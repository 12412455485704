<template>
  <div>
    <div class="Page" v-if="page">
      <ReportInfo :reportVersion="page.reportVersion._id" :trail="[{name: $gettext('Home'), path: '/dashboard'}, {name: $gettext( '%{projectString}s', { projectString: $hugrConfig.projectStringCap } ), path: `/${$hugrConfig.reportRouterReplacement}s`}, {name: 'THIS_REPORT'}, {name: 'pages'}]"/>
      <Body :title="page.name" :subtitle="`${page.host}${page.path}`" :withMenu="true" class="Page_Body">
        <ProgressBar id="pageprogress" label="Progress: " :value="page.progress"/>
        <div class="Page_Body_Screenshot" v-if="page.screenshot">
          <Screenshot :sID="page.screenshot._id" class="Page_Body_Screenshot_Img" alt="Screenshot of page"/>
        </div>

        <Button type="secondary" size="small" :icon="['regular', 'edit']" @click="$refs.editpagemodal.show(page._id)">{{$gettext('Edit page')}}</Button>
        <Button v-if="page.issues.length==0" type="serious" size="small" :icon="['solid', 'trash']" @click="deletePage(page)">{{$gettext('Delete page')}}</Button>

        <TabList :tabs="tabs" />

        <TabPanel ref="issues" class="Page_Body_Issues" id="page-issues" default="open">
          <!-- <Notice type="info">Note: This only shows issues attached to the page, not a component on the page.</Notice> -->
          <InstanceList :key="page._id" :instances="page.issues" />
        </TabPanel>

        <TabPanel ref="components" class="Page_Body_Components" id="page-components">
          <ClearList v-if="page.components.length">
            <li v-for="(component, index) in page.components" :key="`${component.identifier}-${index}`">
              {{component.identifier}}
              <LinkButton type="border" size="small" :to="`/${$hugrConfig.reportRouterReplacement}s/component/${component._id}`">{{$gettext('Go to component')}}</LinkButton>
              </li>
          </ClearList>
          <Empty v-else :text="$gettext('No components here')" />
        </TabPanel>

        <!-- <h3>Issue Instances</h3>
        <Notice type="info">Note: This only shows issues attached to the page, not a component on the page.</Notice>
        <InstanceList :key="page._id" :instances="page.issues" /> -->
      </Body>
      <EditPageModal ref="editpagemodal" @edited="$apollo.queries.page.refetch()"/>
    </div>
    <Loader v-else />
  </div>
</template>

<script>
import gql from 'graphql-tag';

import EditPageModal from '@/modals/Page/Edit';
import InstanceList from '@/components/InstanceList';
import ProgressBar from '@/components/UI/ProgressBar';
import config from '../../../config';

export default {
  name: 'ReportsPageViewView',
  data() {
    const tabs = [
      {
        text: "Issues on page",
        controls: "page-issues",
        action: () => {
          this.$refs.components.close();
          this.$refs.issues.open();
        },
      },
      {
        text: "Components on page",
        controls: "page-components",
        action: () => {
          this.$refs.issues.close();
          this.$refs.components.open();
        },
      },
    ];

    return {
      pageid: this.$route.params.page,

      page: false,

      tabs,
    };
  },
  apollo: {
    page: {
      query: gql`
      query Page($id: ObjectID!) {
        page: Page(id:$id) {
          _id,
          name,
          host
          path
          progress
          screenshot {
            _id
          }
          components {
            _id,
            identifier
          },
          issues {
            _id,
            identifier,
            reason,
            evidence,
            status,
            primary,
            parent {
              _id,
              identifier,
              reason,
              evidence,
              status,
              primary,
              others {
                _id
              }
            }
            others {
              _id
            }
          }
          reportVersion {
            _id
            version
            report {
              _id
              identifier
            }
          }
        }
      }
      `,
      variables() {
        return {
          id: this.pageid,
        };
      },
    },
  },
  watch: {
    $route( to, from ) {
      if( this.$route.path.indexOf( `/${config.reportRouterReplacement}s/page/` ) == 0 ) {
        this.pageid = this.$route.params.page;
        this.$apollo.queries.page.refetch();
      }
    },
  },
  // sockets: {
  //   STATUS_REPORT: function(report) {
  //     this.report = report
  //     let tid = this.$route.params.pathMatch.toUpperCase();
  //     this.jwtemit(['GET_ISSUETEMPLATE', tid]); //to ensure order, doesn't actually need to be here otherwise
  //   },
  //   RETURN_PAGE: function(page) {
  //     this.page = page;
  //   },
  //   RETURN_ISSUEINSTANCES_BY_PAGE: function(instances) {
  //     this.instances = instances;
  //   },
  //   RETURN_COMPONENTS_BY_PAGE: function(components) {
  //     this.components = components;
  //   }
  // },
  methods: {
    componentViewToggle( v ) {
      this.$session.set( 'report.component.view', v );
      this.componentView = v;
    },
    deletePage( page ) {
      if( !page.issues.length && !page.components.length ) {
        this.$confirm.simple( 'This cannot be undone' ).then( result => {
          if( result ) {
            this.doDeletePage( page._id );
          }
        } );
      } else {
        this.$alerts.error( 'Can\'t Delete Page', 'Page cannot be deleted as is has issues or components associated' );
      }
    },
    doDeletePage( page ) {
      this.$apollo.mutate( {
        mutation: gql`mutation ($id: ObjectID!) {
          removePage(id: $id)
        }`,
        variables: {
          id: page,
        },
      } ).then( res => {
        this.$alerts.success( 'Success!', 'Page deleted' );
        const loc = `/${config.reportRouterReplacement}s/view/${this.page.reportVersion.report.identifier}/${this.page.reportVersion.version}`;
        this.$router.push( { path: loc } );
      } );
    },
  },
  components: {
    InstanceList,
    EditPageModal,
    ProgressBar,
  },
};
</script>

<style lang="scss" scoped>

@import '@/assets/styles/variables/_colours.scss';

.Page {
  &_Body {
    position: relative;
    button {
      margin: 10px;
    }

    &_Screenshot {
      height: 240px;
      position: absolute;
      right: 50px;
      top: -90px;

      border: 2px solid $hugr-colours-grey;
      &_Img {
        width: auto;
        height: 100%;
      }
    }

    &_Components {
      li {
        box-sizing: border-box;
        position: relative;
        padding: 20px;
        border: 1px solid $hugr-colours-grey;
        width: 100%;
        margin-top: 10px;
        a {
          position: absolute;
          right: 20px;
          top: 10px;
        }
      }
    }
  }
}
</style>
