<template>
  <div>
    <div v-if="disabled">
      <p v-translate>This functionality is disabled, please speak to an administrator</p>
    </div>
    <div v-else-if="report" class="Publish">
      <!-- <LinkButton type="transparent" size="small" :icon="['solid', 'arrow-left']" :to="'/reports'">Back to reports</LinkButton> -->
      <ReportInfo :reportVersion="report.currentVersion._id" :trail="[{name: $gettext('Home'), path: '/dashboard'}, {name: $gettext( '%{projectString}s', { projectString: $hugrConfig.projectStringCap } ), path: `/${$hugrConfig.reportRouterReplacement}s`}, {name: 'THIS_REPORT'}]"/>

      <Body class="Publish_Body" title="Publish &amp; share" :withMenu="true">
        <Notice type="alert" v-if="!report.currentVersion.published">
          <p>{{ $gettext( 'Publishing this %{projectString} will prevent any further edits. It can be unpublished at any time or you can create a new version for retesting.', { projectString: $hugrConfig.projectString } ) }}</p>
          <Button type="primary" @click="publishReport()">{{$gettext( 'Publish %{projectString}', { projectString: $hugrConfig.projectString } )}} ({{$gettext('version')}} {{report.currentVersion.version}})</Button>
        </Notice>

        <Notice type="alert" v-else-if="report.currentVersion.published">
          <p>{{$gettext( 'This %{projectString} was published on ', { projectString: $hugrConfig.projectString } )}}<DateTime :timestamp="report.currentVersion.publishedDate" />.{{$gettext( 'To make changes to this %{projectString} version it must be unpublished. You can also create a new version.', { projectString: $hugrConfig.projectString } )}}</p>
          <Button type="primary" @click="unpublishReport()">{{$gettext( 'Unpublish %{projectString}', { projectString: $hugrConfig.projectString } )}} ({{$gettext('version')}} {{report.currentVersion.version}})</Button>
        </Notice>

        <div class="Publish_Link">
          <h3 v-translate>Get Link</h3>
          <div v-if="report.currentVersion.published">
            <!-- -restricted - only team members? can view with this link
            -button to change to anyone with link
            -button to copy link -->
            <input ref="shareLink" class="Publish_Link_Input" :value="`${rootUrl}/view/${report.linkString}`" readonly aria-label="Share link" />
            <Button type="border" size="micro" @click="copyLink">{{$gettext('Copy Link')}}</Button>

            <div>
              <AriaListBox idRoot="who_" :label="$gettext('Who can view?')" :labelVisible="true" v-model="whoCanView" @change="changeAccess"
                :options="{
                  'restricted': $gettext('Restricted'),
                  //'anywithpass': 'Anyone with password',
                  'any': $gettext('Anyone'),
                }"
                :extra="{
                  'restricted': $gettext( 'Only logged in users with permission to access the %{projectString} can see', { projectString: $hugrConfig.projectString } ),
                  //'anywithpass': 'Anyone with the link and a password can view',
                  'any': $gettext('Anyone with the link can view'),
                }"
              />
            </div>
            <div v-if="whoCanView=='anywithpass'">
              password input
              <!-- <Input > -->
            </div>
          </div>
          <div v-else>
            <p>{{$gettext( 'Publish %{projectString} to share', { projectString: $hugrConfig.projectString } )}}</p>
          </div>
        </div>

        <!-- <div>
          <h3>Who has access?</h3>
        </div> -->

        <!-- <Notice size="small">Sharing coming soon, reports can be shared with your team by assigning a report to a team in report settings and inviting users from "My Team".</Notice> -->

      </Body>
    </div>
    <Loader v-else />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import config from '../../../config';

import AriaListBox from '@/components/Aria/ListBox';

export default {
  name: 'ReportsPublishView',
  mounted() {
    this.$apollo.query( {
      query: gql`
        query Settings {
          settings: Settings {
            id
            disablePublishAndShare
          }
        }
      `,
    } ).then( res => {
      this.disabled = res.data.settings.disablePublishAndShare;
    } );
  },
  data() {
    return {
      disabled: false,
      identifier: this.$route.params.identifier.replace( '%20', ' ' ),
      version: this.$route.params.version,

      report: false,

      rootUrl: config.rootUrl,

      shareStatus: null,
    };
  },
  watch: {
    $route( to, from ) {
      this.identifier = this.$route.params.identifier.replace( '%20', ' ' );
      this.version = this.$route.params.version;
      this.$apollo.queries.report.refetch();
    },
  },
  apollo: {
    report: {
      query() {
        const reportFragment = gql`
          fragment publishReportFragment on Report {
            _id,
            identifier,
            title,
            archived,
            currentVersion {
              _id,
              published,
              publishedDate,
              version
            }
            trueVersion {
              _id
            }
            publicLink,
            linkString
          }`;
        if( typeof this.version == 'undefined' ) {
          return gql`
            query Report($ident: String!) {
              report: Report(identifier: $ident) {
                ... publishReportFragment
              }
            }
            ${reportFragment}
          `;
        }

          return gql`
            query Report($ident: String!, $version: String!) {
              report: ReportWithVersion(identifier: $ident, version: $version) {
                ... publishReportFragment
              }
            }
            ${reportFragment}
          `;

      },
      variables() {
        return {
          ident: this.identifier,
          version: this.version,
        };
      },
    },
  },
  methods: {
    copyLink() {
      this.$refs.shareLink.focus();
      this.$refs.shareLink.select();
      try {
        const successful = document.execCommand( 'copy' );
        if( successful ) {
          this.$alerts.success( `Link copied to clipboard`, `Link has been successfully copied to your clipboard.` );
        } else {
          this.$alerts.error( `Something went wrong`, `The link could not be copied to your clipboard, please try again or copy the link manually.` );
        }
      } catch ( err ) {
        this.$alerts.error( `Something went wrong`, `The link could not be copied to your clipboard, please try again or copy the link manually.` );
      }
    },
    publishReport() {
      this.$apollo.mutate( {
        mutation: gql`mutation ($id: ObjectID!) {
          version: publishVersion(id: $id) {
            published,
            publishedDate
          }
        }`,
        // Parameters
        variables: {
          id: this.report.currentVersion._id,
        },
      } ).then( () => {
        this.$apollo.queries.report.refetch();
      } );
    },
    unpublishReport() {
      this.$apollo.mutate( {
        mutation: gql`mutation ($id: ObjectID!) {
          version: unPublishVersion(id: $id) {
            published,
            publishedDate
          }
        }`,
        // Parameters
        variables: {
          id: this.report.currentVersion._id,
        },
      } ).then( () => {
        this.$apollo.queries.report.refetch();
      } );
    },
    changeAccess() {
      this.$apollo.mutate( {
        mutation: gql`mutation ($id: ObjectID!, $pub: Boolean!) {
          setReportPublic(id: $id, pub: $pub)
        }`,
        // Parameters
        variables: {
          id: this.report._id,
          pub: this.shareStatus == 'any',
        },
      } ).then( () => {
        this.$apollo.queries.report.refetch();
      } );
    },
  },
  computed: {
    whoCanView: {
      get() {
        if( this.shareStatus ) {
          return this.shareStatus;
        } else if( this.report.publicLink ) {
          return 'any';
        }

          return 'restricted';

      },
      set( s ) {
        this.shareStatus = s;
      },
    },
  },
  components: {
    AriaListBox,
  },
};
</script>

<style lang="scss" scoped>
  .Publish {
    &_Link {
      &_Input {
        padding: 3px;
        margin-right: 5px;
        width: 50%;
      }
    }
  }
</style>
