<template>
  <WidgetBase :title="$gettext( '%{projectString} compliance', { projectString: $hugrConfig.projectStringCap } )">
    <Pie class="Report_Body_Inner_Breakdown_Pie" :data="pieData" :centerKey="pieKey" :cardMode="true"/>
  </WidgetBase>
</template>

<script>
import WidgetBase from './WidgetBase.vue';
import Pie from '../UI/Pie.vue';

export default {
  name: 'UIWidgetFails',
  props: {
    data: {
      type: Object,
    },
  },
  computed: {
    pieKey() {
      return `${this.$hugrConfig.projectStringCap}s`;
    },
    pieData() {
      let pass = 0;
      let fail = 0;

      this.data.forEach( report => {
        if ( report.pass ) {
          pass++;
        } else {
          fail++;
        }
      } );

      return [
      {
        key: 'Pass',
        value: pass,
        colour: '#65D08A',
      },
      {
        key: 'Fail',
        value: fail,
        colour: '#FF6868',
      },
      ];
    },
  },
  components: {
    WidgetBase,
    Pie,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  // .widget {
  //   .container {
  //     padding: 20px;
  //     .inner {
  //       height: 100%;
  //       position: relative;
  //     }
  //   }
  // }
</style>
