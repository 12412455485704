<template>
  <Modal
    ref="modal"
    :title="modalTitle"

    :showActions="true"

    :loading="submitting"
    loadingText="Adding User Journey..."

    :submitText="$gettext('Add user journey')"
    @submit="submit"

    @close="reset()"
    class="UserJourneyModal">
    <div v-if="addToExisting&&!doAddToExisting" class="UserJourneyModal_AddToExisting">
      <Button size="small" @click="triggerAddToExisting()">Show existing journeys</Button>
    </div>
    <template v-if="doAddToExisting && existingJourneys">
      <ul v-if="existingJourneys.length" class="UserJourneyModal_ExistingJourneys">
        <li v-for="journey in existingJourneys" :key="journey._id" class="UserJourneyModal_ExistingJourneys_Item">
          {{ journey.title }}
          <Button size="micro" :aria-label="`Add this page to journey: ${journey.title}`" @click="triggerUpdateJourney( journey._id )">Add</Button>
        </li>
      </ul>
      <Notice type="alert" size="small" v-else>{{ $gettext('No existing journeys, add one below') }}</Notice>
    </template>
    <template v-if="!doAddToExisting || doAddToExisting&&existingJourneys.length==0">
      <FormInput idRoot="userJourney_" :label="$gettext('Title')" v-model="title" ref="title" :validation="['not-empty']"/>
      <FormSelect idRoot="userJourney_" :label="$gettext('Importance')" v-model="importance" :options="{'Essential': 'Essential', 'Secondary': 'Secondary', 'Tertiary': 'Tertiary'}"  />
      <FormTextArea idRoot="userJourney_" :label="$gettext('Description')" v-model="description" />
      <h3>Pages and components</h3>
      <AriaSearchSelectList v-if="pagesAndComponents.length" v-model:items="pagesAndComponents" :ordered="true" :moveable="true" />
      <p v-else v-translate>No pages or components</p>

      <AriaSearchSelect ref="pageselect" :label="$gettext('Add a Page')" :dropdown="true" idRoot="page" :gqlOptions="['PAGES_BY_REPORT', reportVersion]" @selected="addPage"/>

      <AriaSearchSelect ref="componentselect" :label="$gettext('Add a Component')" :dropdown="true" idRoot="component" :gqlOptions="['COMPONENTS_BY_REPORT', reportVersion]" @selected="addComponent"/>
    </template>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';
import FormInput from '@/components/Form/Input';
import FormTextArea from '@/components/Form/TextArea';
import FormSelect from '@/components/Form/Select';
import AriaSearchSelect from '@/components/Aria/SearchSelect2';
import AriaSearchSelectList from '@/components/Aria/SearchSelectList';

export default {
  name: 'UserJourneyModal',
  data() {
    return {
      modalTitle: 'Add a user journey',
      title: '',
      description: '',
      importance: '',

      pagesAndComponents: [],

      addToExisting: false,
      addToExistingType: false,
      pageToAdd: null,
      doAddToExisting: false,
      existingJourneys: false,

      submitting: false,
    };
  },
  methods: {
    submit() {
      this.$hugrvalidate( [ 'title' ], this ).then( ( { success, errors } ) => {
        if( success ) {
          this.submitMutation();
        }
      } );
    },
    submitMutation() {
      this.submitting = true;
      const items = this.pagesAndComponents.map( i => {
        return {
          _id: i._id,
          type: i.type,
        };
      } );
      this.$apollo.mutate( {
        mutation: gql`
          mutation ($reportVersion: ObjectID!, $userJourney: UserJourneyInput!) {
            addUserJourney(reportVersion: $reportVersion, userJourney: $userJourney) {
              _id
            }
          }
        `,
        variables: {
          reportVersion: this.reportVersion,
          userJourney: {
            title: this.title,
            description: this.description,
            importance: this.importance,
            items,
          },
        },
      } ).then( res => {
        if ( res.data.addUserJourney._id ) {
          this.reset();
          this.$alerts.success( 'User journey added!', this.$gettext( 'User journey has been added to your %{projectString}', { projectString: this.$hugrConfig.projectString } ) );
          this.$emit( 'success' );
        }
      } ).catch( err => {
        if( err.message == 'user journey title is not unique' ) {
          this.$refs.title?.addError( 'User Journey must be unique' );
        } else {
          this.$alerts.error( `Oops, there's a problem :/`, `This is definitely a bug` );
        }
      } );
    },
    show() {
      this.reset();
      this.$refs.modal.show();
    },
    reset() {
      this.title = '';
      this.description = '';
      this.importance = '';
      this.pagesAndComponents = [];

      this.addToExisting = false;
      this.addToExistingType = false;
      this.pageToAdd = null;
      this.doAddToExisting = false;
      this.existingJourneys = false;

      this.submitting = false;

      this.$refs.modal.reset();
    },

    addPage( page ) {
      if( this.pagesAndComponents.map( p => p._id ).indexOf( page ) < 0 ) {
        this.$apollo.query( {
          query: gql`
            query Page($id: ObjectID!) {
              page: Page(id: $id) {
                _id,
                name
              }
            }
          `,
          variables: {
            id: page,
          },
        } ).then( res => {
          this.pagesAndComponents.push( {
            ...res.data.page,
            type: 'page',
          } );
        } );
      }
    },
    addComponent( component ) {
      if( this.pagesAndComponents.map( c => c._id ).indexOf( component ) < 0 ) {
        this.$apollo.query( {
          query: gql`
            query Component($id: ObjectID!) {
              component: Component(id: $id) {
                _id,
                identifier
              }
            }
          `,
          variables: {
            id: component,
          },
        } ).then( res => {
          this.pagesAndComponents.push( {
            _id: res.data.component._id,
            name: res.data.component.identifier,
            type: 'component',
          } );
        } );
      }
    },

    setAddToExisting( id, type ) {
      this.addToExisting = true;
      this.pageToAdd = id;
      this.addToExistingType = type;
    },
    triggerAddToExisting() {
      this.modalTitle = 'Add to existing journey';
      this.doAddToExisting = true;

      this.$apollo.query( {
        query: gql`
          query UserJourneysFromVersion($id: ObjectID!) {
            journeys: UserJourneysFromVersion(id: $id) {
              _id,
              title,
            }
          }
        `,
        variables: {
          id: this.reportVersion,
        },
      } ).then( res => {
        this.existingJourneys = res.data.journeys;
      } ).catch( () => {
        this.$alerts.coded( 'TODO', 'TODO' ); //see notifications spreadsheet
      } );
    },
    triggerUpdateJourney( journey ) {
      this.$emit( 'updateJourney', journey, this.pageToAdd, this.addToExistingType );
      this.reset();
    },
  },
  props: {
    reportVersion: {
      type: String,
    },
  },
  components: {
    FormInput,
    FormTextArea,
    FormSelect,
    AriaSearchSelect,
    AriaSearchSelectList,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/assets/styles/variables/_colours.scss';
  .actions button {
    margin-top: 1rem;
  }

  .UserJourneyModal {
    &_AddToExisting {
      text-align: center;
      border-bottom: 1px solid $hugr-colours-grey;
      margin: 0 0 16px;
      padding: 16px;
    }

    &_ExistingJourneys {
      list-style: none;
      margin: 0;
      padding: 0;
      &_Item {
        padding: 8px;
        border-left: 5px solid $hugr-colours-grey;
        background: lighten( $hugr-colours-grey, 13% );
        margin: 16px 0;
        button {
          float: right;
          top: -4px;
        }
      }
    }
  }

  ._darkMode .UserJourneyModal {
    &_ExistingJourneys {
      &_Item {
        background: darken( $hugr-colours-primary, 10% );
      }
    }
  }
</style>
