<template>
  <div>
    <div :class="['ReportInfo', slim?'_slim':'']">
      <BreadCrumbs v-if="trail.length" :trail="trailAugmented" />
      <LinkButton class="ReportInfo_Back" v-if="back" type="icon" :icon="['solid', 'chevron-left']" :to="`/${$hugrConfig.reportRouterReplacement}s/view/${report.identifier}/${report.currentVersion.version}`">
        {{ $gettext( 'Back to %{projectString} home', { projectString: $hugrConfig.projectString } ) }}
      </LinkButton>
      <div class="ReportInfo_Upper">
        <h2 :class="['ReportInfo_Upper_Title', report.title=='Loading'?'_loading':'', slim?'_truncated':'']" :title="report.title">{{report.title}}</h2>
        <span class="ReportInfo_Upper_PubStatus" v-if="report.currentVersion.published"><router-link :to="`/${$hugrConfig.reportRouterReplacement}s/${report.identifier}/${report.currentVersion.version}/publish`"><Icon type="solid" icon="lock" read="locked" /> Published</router-link></span>
        <span class="ReportInfo_Upper_PubStatus" v-else><router-link :to="`/${$hugrConfig.reportRouterReplacement}s/${report.identifier}/${report.currentVersion.version}/publish`">Not published</router-link></span>
      </div>
      <p :class="['ReportInfo_SubTitle', report.title=='Loading'?'_loading':'']">
        #{{report.identifier}}
        <router-link class="ReportInfo_SubTitle_Version" :to="`/${$hugrConfig.reportRouterReplacement}s/${report.identifier}/versions`">Version {{report.currentVersion.version}}<span v-if="report.currentVersion._id!=report.trueVersion._id"> (old version)</span></router-link>
        <span v-if="report.sensitive">sensitive</span>
        <span v-if="report.archived">archived</span>
        <template v-if="productSettings">
          <span >{{ report.productAliases.join(', ') }}</span>
        </template>
      </p>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapActions } from 'vuex';
import config from '../../../config';

export default {
  name: 'UIReportInfo',
  data() {
    return {
      productSettings: false,
      report: {
        title: 'Loading',
        identifier: 'Loading',
        productAliases: [],
        currentVersion: {
          _id: '0',
          version: 'loading',
          published: false,
        },
        trueVersion: {
          _id: '0',
        },
        archived: false,
        sensitive: false,
      },
    };
  },
  watch: {
    reportId() {
      this.getData();
    },
  },
  created() {
    this.$apollo.query( {
      query: gql`
        query Settings {
          settings: Settings {
            id
            productDetails
          }
        }
      `,
    } ).then( res => {
      this.productSettings = res.data.settings.productDetails;
    } ).catch( () => {
      this.$alerts.coded( 'E001', 'F1001' ); //see notifications spreadsheet
    } );

    if( this.reportVersion ) {
      this.setReport( this.reportVersion );
      this.$session.set( 'report', this.reportVersion );
    }
    if( this.reportId ) {
      this.getData();
    }
  },
  methods: {
    ...mapActions( [ 'setReport' ] ),
    getData() {
      this.$apollo.query( {
        query: gql`
          query ReportFromVersion($id: ObjectID!) {
            report: ReportFromVersion(id: $id) {
              _id
              identifier
              productAliases
              title
              archived
              sensitive
              issueCount
              currentVersion {
                _id,
                version
                published
                progress
                isCompliant
              },
              trueVersion {
                _id
              }
              #issues { _id }, #for count only might be better to make this an int in the schema
              # pages {
              #   _id,
              #   name
              # },
              # components {
              #   _id,
              #   identifier
              # }
            }
          }
        `,
        variables: {
          id: this.reportId,
        },
      } ).then( res => {
        this.view = 'report';
        this.report = { ...res.data.report };
      } ).catch( () => {
        this.$alerts.coded( 'E025', 'F1002' ); //see notifications spreadsheet
      } );
    },
  },
  computed: {
    ...mapState( {
      user: 'user',
      reportId: 'report',
    } ),
    trailAugmented() {
      const returnTrail = [];
      for( const item of this.trail ) {
        if( item.name == 'THIS_REPORT' ) {
          returnTrail.push( {
            name: this.report.identifier,
            path: `/${config.reportRouterReplacement}s/view/${this.report.identifier}/${this.report.currentVersion.version}`,
          } );
        } else {
          returnTrail.push( item );
        }
      }

      return returnTrail;
    },
  },
  props: {
    reportVersion: {
      type: String,
      required: false,
    },
    trail: {
      type: Array,
      default: () => { return [] },
    },
    slim: {
      type: Boolean,
      default: false,
    },
    back: {
      type: Boolean,
      default: false,
    },
  },
  components: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

  @import '@/assets/styles/variables/_colours.scss';
  .ReportInfo {
    padding: 20px 50px 0;
    width: 100%;
    box-sizing: border-box;
    margin: auto;
    &_Back {
      position: absolute;
      left: 15px;
      top: 18px;
      color: $hugr-colours-primary !important;
      svg {
        color: $hugr-colours-primary !important;
      }
      &:hover, &:focus {
        color: $hugr-colours-tertiary !important;
      }
    }
    &_Upper {
      &_Title {
        display: inline-block;
        vertical-align: middle;
        font-size: 2em;
        &._loading {
          color: transparent;
          position: relative;
          &:after {
            content: '';
            display: block;
            width: 500px;
            height: 23px;
            position: absolute;
            top: 9px;
            background: $hugr-colours-grey;
          }
        }

        max-width: 40%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &._truncated {
          max-width: 500px;
          margin: 4px 0 26px;
        }
      }
      &_PubStatus {
        vertical-align: middle;
        background: lighten( $hugr-colours-tertiary, 70% );
        color: $hugr-colours-tertiary;
        padding: 8px;
        border-radius: 6px;
        margin-left: 16px;
        position: relative;
        top: 2px;
        font-size: 0.8em;
        svg {
          margin-right: 6px;
        }
        a {
          text-decoration: none;
        }
      }
    }
    &_SubTitle {
      font-size: 0.8em;
      margin-top: 0;
      top: -30px;
      position: relative;
      color: lighten($hugr-colours-primary, 25%);

      &_Version {
        color: #000;
        text-decoration: underline;
        margin-left: 10px;
        cursor: pointer;
      }

      > span {
        margin-left: 10px;
      }

      &._loading {
        color: transparent;
        position: relative;
        a {
          color: transparent !important;
        }
        &:after {
          content: '';
          display: block;
          width: 200px;
          height: 12px;
          position: absolute;
          top: 1px;
          background: lighten($hugr-colours-grey, 10%);
        }
      }
    }

    &._slim {
      padding: 0 50px;
      height: 55px;
      position: relative;
      overflow: hidden;
      .ReportInfo {
        &_Title {
          font-size: 1.5em;
          margin: 10px 0 25px;
        }
        &_subTitle {
          top: -20px;
        }
      }
    }
  }

  ._darkMode .ReportInfo {
    &_Back {
      color: #FFF !important;
      &:hover, &:focus {
        color: $hugr-colours-secondary !important;
      }
    }
    &_SubTitle {
      color: $hugr-colours-grey;
      &_Version {
        color: #FFF;
      }
    }

    &_Upper {
      &_PubStatus {
        a { color: $hugr-colours-tertiary; }
      }
    }
  }

  @media print {
    .ReportInfo {
      display: none !important;
    }
  }

  @media (max-width: 1000px) {
    .ReportInfo {
      padding: 10px 20px 0;
    }
  }
</style>
