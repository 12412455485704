<template>
  <Modal
    ref="modal"
    :title="$gettext(`Edit Team:`) + ` ${team.identifier}`"

    :showActions="true"

    :loading="submitting"
    loadingText="Updating..."

    :submitText="$gettext('Save')"
    @submit="submitTeam"

    @close="reset()"
    :ready="team">
    <FormInput ref="name" idRoot="team_" :label="$gettext('Name')" v-model="team.name" :validation="['not-empty']"/>

    <ul v-if="team.users&&team.users.length" aria-live="polite">
      <li v-for="user in team.users" v-bind:key="user.email">
        {{user.name}} ({{user.email}})
        &nbsp;
        <button @click.prevent="rmUser(user)">{{$gettext('Remove')}}</button>
      </li>
    </ul>
    <p v-else v-translate>No users</p>
    <AriaSearchSelect ref="userselect" :label="$gettext('Add a User')" :dropdown="true" idRoot="team_" :gqlOptions="['USERS']" @selected="addUser"/>

    <ul v-if="team.managers&&team.managers.length" aria-live="polite">
      <li v-for="user in team.managers"  v-bind:key="user.email">
        {{user.name}} ({{user.email}})
        &nbsp;
        <button @click.prevent="rmManager(user)">{{$gettext('Remove')}}</button>
      </li>
    </ul>
    <p v-else v-translate>No managers</p>
    <AriaSearchSelect ref="managerselect" :label="$gettext('Add a Manager')" :dropdown="true" idRoot="team_" :gqlOptions="['USERS']" @selected="addManager"/>

    <AriaSearchSelect v-if="team.customer" ref="customerselect" :label="$gettext('Customer')" :dropdown="true" :gqlOptions="['CUSTOMERS']" @selected="setCustomer" :validation="['not-empty']" :default="team.customer._id"/>
    <AriaSearchSelect v-else ref="customerselect" :label="$gettext('Customer')" :dropdown="true" :gqlOptions="['CUSTOMERS']" @selected="setCustomer" :validation="['not-empty']"/>

    <AriaSearchSelect v-if="team.contact" ref="contactselect" :label="$gettext('Internal Contact')" :dropdown="true" idRoot="team_" :gqlOptions="['DIGTEAM']" @selected="setContact" :default="team.contact._id"/>
    <AriaSearchSelect v-else ref="contactselect" :label="$gettext('Internal Contact')" :dropdown="true" idRoot="team_" :gqlOptions="['DIGTEAM']" @selected="setContact" />
    <FormInput ref="reportlimit" idRoot="team_" :label="$gettext( 'Custom %{projectString} Limit', { projectString: $hugrConfig.projectString } )" v-model="team.reportslimit" />
    <FormInput ref="userslimit" idRoot="team_" :label="$gettext('Custom Users Limit')" v-model="team.userslimit" />

    <template v-if="!team.subscriptionActive">
      <label for="activeIndefinitely" v-translate>Active indefinitely</label>
      <input type="checkbox" v-model="team.activeIndefinitely" />
      <p v-translate>Team valid indefinitely?</p>

      <template v-if="!team.activeIndefinitely">
        <FormDate idRoot="team_" :label="$gettext('Active until')" v-model="team.activeUntil" :hintText="$gettext('Set this date to the future to keep this team active even without a valid subscription')" />
      </template>
    </template>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';

import FormInput from '@/components/Form/Input';
import FormDate from '@/components/Form/Date';

import AriaSearchSelect from '@/components/Aria/SearchSelect2';

export default {
  name: 'EditTeamModal',
  data() {
    return {
      team: false,
      customerChanged: false,
      submitting: false,
    };
  },
  methods: {
    setContact( contact, display ) {
      this.$refs.contactselect.select( contact, display );
      this.team.contact = {
        _id: contact,
        name: display,
      };

    },
    submitTeam() {
      const validations = [ 'name' ];
      if( this.customerChanged ) {
        validations.push( 'customerselect' );
      }
      this.$hugrvalidate( validations, this ).then( ( { success, errors } ) => {
        if( success ) {
          this.submitting = true;
          this.$apollo.mutate( {
            mutation: gql`
              mutation updateTeam($id: ObjectID!, $team: TeamInput!) {
                team: updateTeam(id: $id, team: $team) {
                  _id,
                  name
                }
              }
            `,
            variables: {
              id: this.team._id,
              team: {
                identifier: this.team.identifier,
                name: this.team.name,
                reportslimit: parseInt( this.team.reportslimit ),
                userslimit: parseInt( this.team.userslimit ),
                managers: this.team.managers.map( user => user._id ),
                users: this.team.users.map( user => user._id ),
                customer: this.team.customer?._id,
                contact: this.team.contact?._id,
                activeIndefinitely: this.team.activeIndefinitely,
                activeUntil: this.team.activeIndefinitely ? null : this.team.activeUntil,
              },
            },
          } ).then( res => {
            this.submitting = false;
            this.reset();
            this.$alerts.success( 'Team updated!', `Team ${res.data.team.name} has been updated successfully` );
            this.$emit( 'success', res.data.team._id );
          } ).catch( error => {
            this.submitting = false;
            this.$alerts.coded( 'E067', 'F3801', '', error ); //see notifications spreadsheet
          } );
        }
      } );
    },

    addUser( user ) {
      this.$apollo.query( {
        query: gql`
          query User($id: ObjectID!) {
            user: User(id: $id) {
              _id,
              name,
              email
            }
          }
        `,
        variables: {
          id: user,
        },
      } ).then( res => {
        this.team.users.push( res.data.user );
      } ).catch( error => {
        this.$alerts.coded( 'E018', 'F3802', '', error ); //see notifications spreadsheet
      } );
    },
    rmUser( user ) {
      if( this.team.managers.map( user => user._id ).indexOf( user._id ) >= 0 ) { //if remove user, also remove manager
        this.team.managers.splice( this.team.managers.map( user => user._id ).indexOf( user._id ), 1 );
      }
      this.team.users.remove( user );
    },

    addManager( user ) {
      this.$apollo.query( {
        query: gql`
          query User($id: ObjectID!) {
            user: User(id: $id) {
              _id,
              name,
              email
            }
          }
        `,
        variables: {
          id: user,
        },
      } ).then( res => {
        if( this.team.users.indexOf( res.data.user ) < 0 ) { //make sure manager is in users
          this.team.users.push( res.data.user );
        }
        this.team.managers.push( res.data.user );
      } ).catch( error => {
        this.$alerts.coded( 'E018', 'F3803', '', error ); //see notifications spreadsheet
      } );
    },
    rmManager( user ) {
      this.team.managers.remove( user );
    },

    setCustomer( customer, display ) {
      this.customerChanged = true;
      this.$refs.customerselect.select( customer, display );
      this.team.customer = {
        _id: customer,
        name: display,
      };
    },

    show( id ) {
      this.$apollo.query( {
        query: gql`
          query Team($id: ObjectID!) {
            team: Team(id: $id) {
              _id,
              identifier,
              reportslimit,
              userslimit,
              name,
              activeUntil,
              activeIndefinitely,
              subscriptionActive,
              users {
                _id
                name,
                email
              }
              managers {
                _id
                name,
                email
              }
              customer {
                _id
                name
              }
              contact {
                _id
                name
              }
            }
          }
        `,
        variables: {
          id,
        },
        fetchPolicy: 'no-cache',
      } ).then( res => {
        this.team = { ...res.data.team };

        this.team.users = Object.assign( [], this.team.users );
        this.team.managers = Object.assign( [], this.team.managers );

        setTimeout( () => {
          this.$refs.modal.show();
        }, 100 );
      } ).catch( error => {
        this.$alerts.coded( 'E062', 'F3804', '', error ); //see notifications spreadsheet
      } );
    },
    reset() {
      this.team = false;
      this.$refs.modal.reset();
    },
  },
  props: {
  },
  components: {
    FormInput,
    FormDate,
    AriaSearchSelect,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
