<template>
  <div>
    <div class="Component" v-if="component">
      <ReportInfo :reportVersion="component.reportVersion._id" :trail="[{name: $gettext('Home'), path: '/dashboard'}, {name: $gettext( '%{projectString}s', { projectString: $hugrConfig.projectStringCap } ), path: `/${$hugrConfig.reportRouterReplacement}s`}, {name: 'THIS_REPORT'}, {name: 'components'}]"/>

      <Body :title="component.identifier" :withMenu="true" class="Component_Modal">
        <ProgressBar id="componentprogress" label="Progress: " :value="component.progress"/>
        <div class="Component_Body_Screenshot" v-if="component.screenshot">
          <Screenshot :sID="component.screenshot._id" class="Component_Body_Screenshot_Img" alt="Screenshot of page"/>
        </div>

        <Button type="secondary" size="small" :icon="['regular', 'edit']" @click="$refs.editcomponentmodal.show(component._id)">{{$gettext('Edit component')}}</Button>
        <Button v-if="component.issues.length==0" type="serious" size="small" :icon="['solid', 'trash']" @click="deleteComponent(component)">{{$gettext('Delete component')}}</Button>

        <InstanceList :key="component._id" :instances="component.issues" />
      </Body>
      <EditComponentModal :report="component.reportVersion._id" ref="editcomponentmodal" @edited="$apollo.queries.component.refetch()"/>
    </div>
    <Loader v-else />
  </div>
</template>

<script>
import gql from 'graphql-tag';

import InstanceList from '@/components/InstanceList';
import EditComponentModal from '@/modals/Component/Edit';
import ProgressBar from '@/components/UI/ProgressBar';
import config from '../../../config';
export default {
  name: 'ReportsComponentViewView',
  data() {
    return {
      componentid: this.$route.params.component,
      component: false,
      instances: [],
    };
  },
  apollo: {
    component: {
      query: gql`
      query Component($id: ObjectID!) {
        component: Component(id:$id) {
          _id,
          identifier,
          progress,
          futurePagesAdded,
          screenshot {
            _id
          }
          issues {
            _id,
            identifier,
            reason,
            evidence,
            status,
            primary,
            parent {
              _id,
              identifier,
              reason,
              evidence,
              status,
              primary,
              others {
                _id
              }
            }
            others {
              _id
            }
          }
          reportVersion {
            _id
            version
            report {
              _id
              identifier
            }
          }
        }
      }
      `,
      variables() {
        return {
          id: this.componentid,
        };
      },
    },
  },
  watch: {
    $route( to, from ) {
      if( this.$route.path.indexOf( `/${config.reportRouterReplacement}s/component/` ) == 0 ) {
        this.componentid = this.$route.params.component;
        this.$apollo.queries.component.refetch();
      }
    },
  },
  methods: {
    deleteComponent( component ) {
      if( !component.issues.length ) {
        this.$confirm.simple( 'This cannot be undone' ).then( result => {
          if( result ) {
            this.doDeleteComponent( component._id );
          }
        } );
      } else {
        this.$alerts.error( 'Can\'t Delete Component', 'Component cannot be deleted as it has issues associated' );
      }
    },
    doDeleteComponent( component ) {
      this.$apollo.mutate( {
        mutation: gql`mutation ($id: ObjectID!) {
          removeComponent(id: $id)
        }`,
        // Parameters
        variables: {
          id: component,
        },
      } ).then( res => {
        this.$alerts.success( 'Success!', 'Component deleted' );
        const loc = `/${config.reportRouterReplacement}s/view/${this.component.reportVersion.report.identifier}/${this.component.reportVersion.version}`;
        this.$router.push( { path: loc } );
      } );
    },
  },
  components: {
    InstanceList,
    EditComponentModal,
    ProgressBar,
  },
};
</script>

<style lang="scss" scoped>

@import '@/assets/styles/variables/_colours.scss';

.Component {
  button {
    margin: 10px;
  }
  &_Body {

    position: relative;

    &_Screenshot {
      height: 240px;
      position: absolute;
      right: 50px;
      top: -90px;

      border: 2px solid $hugr-colours-grey;
      &_Img {
        width: auto;
        height: 100%;
      }
    }
  }
}
</style>
