<template>
  <WidgetBase size="long" :title="$gettext('Issue distribution')">
    <div class="Products">
      <div v-for=" ( report, i ) in data " :key="`report-${i}`">
        <div class="Products_Product" v-if="Math.max(report.issues.advisory, report.issues.low, report.issues.medium, report.issues.high, report.issues.critical) > 0">
          <h5 class="a11yhide">{{ report.report.title }}</h5>
          <div class="Products_Product_Graph">
            <div class="Products_Product_Graph_Graphic">
              <SeverityLine
              :type="'line'"
              :direction="'vertical'"
              :advisory="report.issues.advisory"
              :low="report.issues.low"
              :medium="report.issues.medium"
              :high="report.issues.high"
              :critical="report.issues.critical"
            />
            </div>
            <div v-if="i < data.length" class="Products_Product_Graph_Separator">

            </div>
          </div>
          <div class="Products_Product_Info">
            <router-link :to="`/${$hugrConfig.reportRouterReplacement}s/view/${report.report.identifier}`">{{ report.report.title }}</router-link>
          </div>
        </div>
      </div>
      <Empty v-if="data.filter( r => Math.max( r.issues.advisory, r.issues.low, r.issues.medium, r.issues.high, r.issues.critical ) > 0 ).length == 0" :text="$gettext( 'No issues on any %{projectString}s', { projectString: $hugrConfig.projectString } )" />
    </div>
  </WidgetBase>
</template>

<script>
import WidgetBase from './WidgetBase.vue';
import SeverityLine from '../UI/SeverityLine.vue';

export default {
  name: 'UIWidgetMostIssuesProducts',
  props: {
    data: {
      type: Object,
    },
  },
  components: {
    WidgetBase,
    SeverityLine,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .Products {
    display: flex;
    flex-wrap: wrap;
    column-gap: 0px;
    row-gap: 16px;

    height: 100%;
    position: relative;
    &_Product {
      display: flex;
      flex-direction: column;
      width: 129px;
      
      &_Graph {
        display: flex;
        flex-direction: row;

        &_Graphic {
          padding-bottom: 8px;
          padding-left: 8px;
          padding-right: 8px;
          border-bottom: black 1px solid;
        }
        &_Separator {
          height: 24px;
          width: 1px;
          background: black;
          position: relative;
          top: 96px;
        }
      }

      &_Info {
        margin-top: 8px;
        max-width: 130px;
        text-wrap: wrap;
        text-align: center;
        a {
          text-decoration: none;
        }
      }
    }
  }

  // .widget {
  //   .container {
  //     padding: 20px;
  //     .inner {
  //       display: flex;
  //       column-gap: 0px;

  //       height: 100%;
  //       position: relative;
  //     }
  //   }
  // }
</style>
