<template>
  <header :class="['Header', placeholder?'_placeholder':'']">
      <div class="Header_Inner">
        <router-link class="_logolink" :to="'/dashboard'">
          <h1 class="Header_Inner_Logo">HUGR</h1>
          <p class="Header_Inner_From">from <span class="_diglogo"><span>Dig</span> Inclusion</span> </p>
        </router-link>
        <nav :class="['Header_Inner_Nav', menuOpen?'_open':'']" v-if="!placeholder" ref="navigation" tabindex="-1" @keyup.esc="quitNav">
          <ul class="Header_Inner_Nav_List">
            <template v-for="(loc, title) in navigation">
              <template v-if="typeof loc == 'object'">
                <li :key="title" :class="`__menuItem-${title}`">
                  <a href="#" role="button" :ref="`${title}-btn`" @click="toggleSubNav(title)" @keyup.space.prevent="toggleSubNav(title)" @keyup.esc="toggleSubNavOff(title)" aria-expanded="false">
                    {{ $gettext( title, { project: $hugrConfig.projectStringCap } ) }}
                  </a>
                  <ul class="Header_Inner_Nav_SubNav" :ref="title">
                    <template v-for="(sloc, stitle) in loc" :key="sloc">
                      <li @click="preNavigate">
                        <template v-if="typeof sloc == 'object'">
                          <span class="Header_Inner_Nav_SubNav_SectionTitle">{{ stitle }}</span>
                          <ul class="Header_Inner_Nav_SubNav_Section">
                            <li v-for="(ssloc, sstitle) in sloc" :key="ssloc" @click="preNavigate">
                              <router-link v-if="isRoute(sstitle, ssloc)" :to="ssloc" aria-current="page">{{ $gettext(sstitle) }}</router-link>
                              <router-link v-else :to="ssloc">{{ $gettext(sstitle) }}</router-link>
                            </li>
                          </ul>
                        </template>
                        <template v-else-if="stitle != '_type'">
                          <router-link v-if="isRoute(stitle, sloc)" :to="sloc" aria-current="page">{{ $gettext(stitle) }}</router-link>
                          <router-link v-else :to="sloc">{{ $gettext(stitle) }}</router-link>
                        </template>
                      </li>
                    </template>
                  </ul>
                </li>
              </template>
              <li v-else-if="title != '_type'" :key="loc" @click="preNavigate">
                <router-link v-if="isRoute(title, loc)" :to="loc" aria-current="page">{{ $gettext( title, { project: $hugrConfig.projectStringCap }) }}</router-link>
                <router-link v-else :to="loc">{{ $gettext( title, { project: $hugrConfig.projectStringCap } ) }}</router-link>
              </li>
            </template>
            <li class="_logout">
              <a href="#" role="button" @click="logout">{{$gettext('Logout')}}</a></li>
          </ul>
        </nav>

        <div v-if="visitor" class="Header_Inner_Actions">
          <LinkButton size="small" :to="`/dashboard`">{{$gettext('Login')}}</LinkButton>
        </div>
        <div class="Header_Inner_Actions" v-else-if="!placeholder">
          <Button v-if="!disableDarkMode" type="icon" size="small" :toggle="true" :checked="darkMode" class="_dark" :icon="['solid', 'moon']" @click="toggleDarkMode()">{{$gettext('Dark mode')}}</Button>
          <hugr-alerts />
          <Button type="icon" size="small" class="_notifications" ref="notificationBtn" :icon="['solid', 'bell']" :aria-expanded="notificationsOpen" @click="$refs.notifications.toggle()" :title="$gettext('Notifications')">
            {{$gettext('Notifications')}}
            <span class="_unread" v-if="unreadNotifications" :aria-label="`${unreadNotifications} unread`"></span>
          </Button>
          <router-link :to="`/account`" class="_user" :aria-label="`${user.name} - Settings`" :title="`${user.name}` + $gettext('` - Settings`')"><Avatar :uid="user.id"/></router-link>
          <Button size="small" class="_logout" type="icon" :icon="['solid', 'sign-out-alt']" @click="logout" title="logout">{{$gettext('Logout')}}</Button>
          <Button class="_menu" type="icon" :aria-expanded="menuOpen?'true':'false'" ref="mobmenu" :icon="['solid', 'bars']" title="menu" @click="toggleNav">{{$gettext('Menu')}}</Button>
        </div>
      </div>
      <Notifications ref="notifications" @open="onOpenNotifications()" @close="onCloseNotifications()" v-if="!placeholder"/>
  </header>
</template>

<script>
import gql from 'graphql-tag';
// import { mapState, mapActions } from 'vuex';

import Notifications from '@/components/Notifications.vue';
import Avatar from '@/components/Helpers/Avatar.vue';

export default {
  name: 'Header',
  mounted() {
    this.$apollo.query( {
      query: gql`
        query Settings {
          settings: Settings {
            id
            disableDarkMode
          }
        }
      `,
    } ).then( res => {
      this.disableDarkMode = res.data.settings.disableDarkMode;
    } ).catch( () => {
      this.$alerts.coded( 'E001', 'F105' ); //see notifications spreadsheet
    } );
    if( !this.placeholder && this.user ) {
      this.$apollo.query( {
        query: gql`
          query GetUserSetting($id: ObjectID!, $key: String!) {
            res: GetUserSetting(id: $id, key: $key)
          }
        `,
        variables: {
          id: this.user.id,
          key: 'darkMode',
        },
      } ).then( res => {
        this.setDarkMode( res.data.res == 'true' );
      } ).catch( () => {
        this.$alerts.coded( 'E002', 'F101' ); //see notifications spreadsheet
      } );

      const observer = this.$apollo.subscribe( {
        query: gql`
          subscription SubUnreadNotifications($user: ObjectID!) {
            unreadNotifications: SubUnreadNotifications(user: $user)
          }
        `,
        variables: {
          user: this.user.id,
        },
      } );

      observer.subscribe( {
        next: res => {
          this.unreadNotifications = res.data.unreadNotifications;
        },
        error: () => {
          this.$alerts.coded( 'E006', 'F102' ); //see notifications spreadsheet
        },
      } );
    }
  },
  data() {
    return {
      notificationsOpen: 'false',
      unreadNotifications: 0,
      menuOpen: false,
      disableDarkMode: true, //default to true incase

      darkMode: localStorage.getItem( 'darkmode' ) == 'true',
    };
  },
  apollo: {
    unreadNotifications: {
      query: gql`
        query {
          unreadNotifications: UnreadNotifications
        }
      `,
    },
  },
  methods: {
    logout() {
      this.$apollo.mutate( {
          mutation: gql`
            mutation logout($refreshToken: String!) {
              removed: logout(refreshToken: $refreshToken)
            }
          `,
          variables: {
            refreshToken: localStorage.getItem( 'HUGR_REFRESH_TOKEN' ),
          },
      } ).then( () => {

      } ).catch( () => {
        this.$alerts.coded( 'E007', 'F103' ); //see notifications spreadsheet
      } );
      this.$session.destroy();
      localStorage.removeItem( 'HUGR_ACCESS_TOKEN' );
      localStorage.removeItem( 'HUGR_REFRESH_TOKEN' );
      this.$router.push( '/' );
      window.location.reload(); //do true reload to clear everything, this should be the only time this happens
    },
    isRoute( title, loc ) {
      return this.$route.name?.replace( / /g, "" ).trim().toLowerCase() == title.replace( / /g, "" ).trim().toLowerCase()
              || this.$route.path.toLowerCase() == loc.toLowerCase()
              || this.$route.path.toLowerCase().indexOf( loc.toLowerCase() ) >= 0;
    },
    onOpenNotifications() {
      this.notificationsOpen = 'true';
    },
    onCloseNotifications() {
      this.notificationsOpen = 'false';
      setTimeout( () => {
        this.$apollo.queries.unreadNotifications.refetch();
      }, 100 );
      this.$refs.notificationBtn.focus();
    },
    toggleNav() {
      this.menuOpen = !this.menuOpen;
      setTimeout( () => {
        if( this.menuOpen ) {
          //@ts-ignore;
          this.$refs.navigation.focus();
        }
      }, 100 );
    },
    quitNav() {
      this.menuOpen = false;
      //@ts-ignore
      this.$refs.mobmenu.focus();
    },
    preNavigate( close = true ) {
      if( close ) {
        this.menuOpen = false;
      }
      for( const title in this.navigation ) {
        if( typeof this.navigation[title] == 'object' ) {
          this.toggleSubNavOff( title );
        }
      }
    },
    toggleDarkMode() {
      this.setDarkMode( !this.darkMode, true );
    },
    setDarkMode( on, propogate = false ) {
      this.darkMode = on;
      localStorage.setItem( 'darkmode', this.darkMode ? 'true' : 'false' );
      if( propogate ) {
        this.$apollo.mutate( {
          mutation: gql`
            mutation setUserSetting($id: ObjectID!, $key: String!, $value: String!) {
              setUserSetting(id: $id, key: $key, value: $value)
            }
          `,
          variables: {
            id: this.user.id,
            key: 'darkMode',
            value: this.darkMode ? 'true' : 'false',
          },
        } ).catch( () => {
          this.$alerts.coded( 'E005', 'F104' ); //see notifications spreadsheet
        } );
      }
      this.$emit( 'lights' );
      console.log(this.darkMode?'The light has been turned off':'The light has been turned on'); //eslint-disable-line
    },
    toggleSubNav( subnav ) {
      const btn = this.$refs[`${subnav}-btn`][0];
      const el = this.$refs[subnav][0];
      if( el.classList.contains( '_show' ) ) {
        this.toggleSubNavOff( subnav );
      } else {
        this.preNavigate( false );
        el.classList.add( '_show' );
        btn.setAttribute( 'aria-expanded', 'true' );
        const listener = e => {
          if( e.type == "click" || e.key == "Tab" ) {
            const parentEl = document.getElementsByClassName( `__menuItem-${subnav}` )[0];
            const isChild = parentEl.contains( e.srcElement );
            if( !isChild ) {
              this.toggleSubNavOff( subnav );
              document.removeEventListener( 'click', listener, false );
              document.removeEventListener( 'keyup', listener, false );
            }
          }
        };
        document.addEventListener( 'click', listener );
        document.addEventListener( 'keyup', listener );
      }
    },
    toggleSubNavOff( subnav ) {
      const btn = this.$refs[`${subnav}-btn`][0];
      const el = this.$refs[subnav][0];
      el.classList.remove( '_show' );
      btn.setAttribute( 'aria-expanded', 'false' );
    },
  },
  // computed: {
  //   ...mapState(['user']),
  // },
  props: {
    navigation: {
      type: Object,
    },
    user: {
      type: Object,
    },
    placeholder: {
      type: Boolean,
      default: false,
    },
    visitor: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Notifications,
    Avatar,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import '@/assets/styles/variables/_fonts.scss';
@import '@/assets/styles/variables/_colours.scss';
@import '@/assets/styles/variables/_header.scss';

.Header {
  width: 100%;
  position: fixed;
  z-index: 9999;
  background: $hugr-header-regular-bg;
  height: $hugr-header-regular-height;
  border-bottom: $hugr-header-regular-border;
  box-shadow: $hugr-header-regular-shadow;

  &._placeholder {
    position: initial;
  }

  &_Inner {
    > * { display: inline-block; vertical-align: top; }

    background-image: url('../assets/images/logo-footer.png');
    background-repeat: no-repeat;
    background-position: left top;
    background-color: transparent;
    background-size: 35px;
    height: 100%;

    ._logolink {
      text-decoration: none;
      margin-left: 45px;
      padding-top: 12px;
      line-height: 1.3em;
    }
    &_Logo {
      font-family: $hugr-fonts-logo;
      font-weight: 600;
      color: $hugr-header-regular-text;
      font-size: 1.5em;
      margin: 0;
      // border-bottom: 0;
      // padding-left: 50px;
      // height: 41px;
      // padding-top: 5px;
      // margin: 10px 0px;
    }
    &_From {
      font-family: $hugr-fonts-logo;
      font-weight: 1em;
      color: $hugr-header-regular-text;
      margin: 0;
      ._diglogo {
        font-family: 'fs-me-regular', sans-serif;
        span {
          font-family: 'fs-me-bold', sans-serif;
        }
      }
    }

    &_Nav {
      &_List {
        margin: 0 40px;
        padding: 0;
        position: relative;
        margin-top: 22px;
        li {
          display: inline-block;
          position: relative;
          a {
            font-family: $hugr-fonts-logo;
            font-weight: 500;
            color: $hugr-header-regular-text;
            text-decoration: none;
            transition: 0.5s;
            padding: 21px 10px;
            border-bottom: 3px solid transparent;
            &:focus, &:hover, &[aria-current="page"] {
              color: $hugr-header-regular-text-focus;
              border-bottom: 3px solid $hugr-header-regular-text-focus;
            }
          }
          &._logout {
            display: none;
          }
        }
      }

      &_SubNav {
        display: none;
        position: absolute;
        background: $hugr-header-regular-bg;
        width: 190px;
        margin: 0;
        padding: 0;
        margin-top: 23px;
        li {
          display: block;
          a {
            padding: 4px 8px;
            display: block;
            font-size: 0.9em;
          }
        }

        &._show {
          display: block;
        }

        &_SectionTitle {
          display: block;
          color: #FFF;
          padding: 4px 8px;
          font-weight: bold;
        }

        &_Section {
          margin: 0;
          padding: 0;
          li {
            display: block;
            a {
              padding: 4px 12px;
              display: block;
              font-size: 0.9em;
            }
          }
        }
      }
    }

    &_Actions {
      position: absolute;
      right: 10px;
      margin: 15px 0;
      button, a {
        color: #FFF !important;
        margin-left: 10px;
        &:focus, &:hover {
          color: $hugr-colours-secondary !important;
        }

        &._logout:focus, &._logout:hover {
          color: $hugr-colours-red !important;
        }

        &._user {
          color: black !important;
          &:focus, &:hover {
            span {
              transition: background-color .5s ease-in-out 0s,
                          border-radius 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0s;
              border-radius: 100%;
              background: darken($hugr-colours-secondary, 20%) !important;
            }
          }
        }

        &._notifications {
          position: relative;
          ._unread {
            background: $hugr-colours-secondary;
            width: 6px;
            height: 6px;
            position: absolute;
            right: 6px;
            top: 10px;
            border-radius: 10px;
          }
        }

        &._menu {
          display: none;
        }

        &._dark {
          width: 75px;
        }
      }
    }
  }
}

._darkMode {
  .Header {
    background: darken($hugr-header-regular-bg, 10%);
    &_Inner_Nav_SubNav {
      background: darken($hugr-header-regular-bg, 10%);
    }
  }
}

@media screen and (max-width: 1400px) {
  .Header {
    &_Inner {
      &_Nav {
        ul {
          li {
            a {
              font-size: 0.8em;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1150px) {
  .Header {
    &_Inner {
      &_Nav {

        &_SubNav {
          position: unset;
          background: transparent !important;
          border-left: 5px solid $hugr-colours-grey;
          width: unset;
          margin-left: 18px;
          margin-top: 0;
        }

        display: none;

        &._open {
          display: block;
        }

        position: absolute;
        width: 75%;
        height: calc( 100vh - 66px );
        top: 67px;
        left: 0;
        background: $hugr-colours-secondary;
        ul {
          margin-top: 15px;
          li {
            display: block;
            a {
              font-size: 1em;
              color: #000;
              padding: 10px;
              display: block;
              &:hover, &:focus, &[aria-current="page"] {
                color: #000;
                background: #FFF;
                border-radius: 3px;
              }
            }
            &._logout {
              display: block;
            }
          }
        }
      }

      &_Actions {
        button, a {
          &._menu {
            display: inline-block;
          }
          &._logout {
            display: none;
          }
        }
      }
    }
  }
}

@media print {
  header {
    display: none !important;
  }
}
</style>
