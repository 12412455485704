<template>
  <div v-if="instance" class="Instance">
    <Notice class="Instance_Notice" v-if="instance&&instance.others.length&&!instance.reportVersion.published" type="alert" size="micro">{{$gettext('This is an issue group, changes will affect all instances in the group.')}}</Notice>
    <ReportInfo :reportVersion="instance.reportVersion._id" :trail="[{name: $gettext('Home'), path: '/dashboard'}, {name: $gettext( '%{projectString}s', { projectString: $hugrConfig.projectStringCap } ), path: `/${$hugrConfig.reportRouterReplacement}s`}, {name: 'THIS_REPORT'}, {name: $gettext('Issues'), path: `/${$hugrConfig.reportRouterReplacement}s/${instance.reportVersion.report.identifier}/${instance.reportVersion.version}/issues`}]"/>
    <Button class="_asideInfo" type="icon" :icon="['solid', 'info-circle']" @click="toggleAside" :aria-expanded="showAside?'true':'false'">{{$gettext('Info')}}</Button>
    <Body :title="`${instance.template.identifier}: ${instance.template.title}`" :subtitle="`#${instance.identifier}`" :aside="true" :withMenu="true" class="Instance">

      <Button v-if="!instance.reportVersion.published" class="Instance_Change" type="transparent" size="micro" @click="$refs.mutateissuemodal.show()" :icon="['solid', 'exchange-alt', 'after']" aria-label="Change template">{{$gettext('Change')}}</Button>

      <div class="edit-head">
        <h3 v-translate>Overview</h3>
        <Button type="transparent" size="micro" @click="$refs.issuedetailsmodal.show(instance.template._id)" :icon="['solid', 'envelope-open-text', 'after']">{{$gettext('View Details')}}</Button>
      </div>
      <vue-markdown :key="instance.template.identifier+'-description'" v-highlight :html="false">{{ instance.template.description }}</vue-markdown>

      <div class="edit-head">
        <h3 v-translate>Particulars</h3>
        <Button v-if="user.aclevel!=3&&!edit.particulars&&!instance.reportVersion.published" class="edit" type="transparent" size="micro" :icon="['regular', 'edit', 'after']" @click.prevent="editParticulars" aria-label="Edit particulars">{{$gettext('Edit')}}</Button>
      </div>
      <Gallery v-model="instance.evidence" :editable="edit.particulars" :instance="instance._id"/>
      <div v-if="(user.aclevel==3)||!edit.particulars">
        <figure>
          <!-- <img :key="`img-${instance.evidence[0]}`" v-if="instance.evidence[0]" :src="`${$hugrConfig.assetsUrl}/evidence/${instance.evidence[0]}?${new Date().getTime()}`" alt="Screenshot of issue" />
          <span class="no-img" v-else v-translate>No image</span> -->
          <figcaption>
            <vue-markdown :key="instance.identifier+'-caption'" v-highlight :html="false">{{ instance.reason }}</vue-markdown>
          </figcaption>
        </figure>
      </div>
      <div class="Instance_Particulars" v-else>
        <!-- <FormImage ref="evidenceImage" idRoot="particulars_" :label="$gettext('Screenshot')" v-model="instance.evidence" :forceMulti="true" :msg="$gettext('Drag and drop or paste an image here to replace existing image')"/> -->
        <FormTextArea idRoot="issue_" :label="$gettext('Reason')" v-model="instance.reason"/>
        <div class="Instance_Particulars_Actions">
          <Button type="primary" size="small" @click.prevent="cancelParticulars">{{$gettext('Cancel')}}</Button>
          <Button type="secondary" size="small" @click.prevent="saveParticulars">{{$gettext('Save')}}</Button>
        </div>
      </div>

      <div class="edit-head">
        <h3 v-translate>Solutions</h3>
        <Button v-if="user.aclevel!=3&&!edit.solutions&&!instance.reportVersion.published" class="edit" type="transparent" size="micro" :icon="['regular', 'edit', 'after']" @click.prevent="editSolutions" aria-label="Edit solutions">{{$gettext('Edit')}}</Button>
      </div>
      <div v-if="(user.aclevel==3)||!edit.solutions">
        <ul v-if="instance.solutions.length">
          <li v-for="solution in instance.solutions" v-bind:key="solution.identifier">
            <ALink :external="true" :href="`https://solutions.diginclusion.com/#/solutions/${solution.identifier}`">{{ solution.identifier }}: {{ solution.title }}</ALink>
          </li>
        </ul>
        <p v-else v-translate>No solutions</p>
      </div>
      <div class="Instance_Solutions" v-else>
        <ul v-if="instance.solutions.length" aria-live="polite">
          <li v-for="solution in instance.solutions" v-bind:key="solution.identifier">
            {{ solution.identifier }}: {{ solution.title }}
            <button @click.prevent="rmSolution(solution)">{{$gettext('Remove')}}</button>
          </li>
        </ul>
        <p v-else v-translate>No solutions, add one</p>
        <AriaSearchSelect ref="solutionsSelect" :label="$gettext('Add a solution')" idRoot="solution" :dropdown="true" :gqlOptions="['SOLUTIONS']" @selected="addSolution" :priority="instance.template.solutions.map(solution => solution._id)"/>
        <div class="Instance_Solutions_Actions">
          <Button type="primary" size="small" @click.prevent="cancelSolutions">{{$gettext('Cancel')}}</Button>
          <Button type="secondary" size="small" @click.prevent="saveSolutions">{{$gettext('Save')}}</Button>
        </div>
      </div>

      <Aside :class="[showAside?'':'_hide']">
        <dl>
          <div>
            <dt id="statuslabel" v-translate>Status</dt>
            <dd>
              <span v-if="user.aclevel==3||instance.reportVersion.published"> <!-- customer -->
                <AriaListBox v-model="instance.status" idRoot="instance_" label="Status" :labelVisible="false" @change="statusChange"
                  :options="{
                    'reported': $gettext('Reported'),
                    'in-progress': $gettext('In Progress'),
                    'retest': $gettext('To Retest')
                  }"
                  :extra="{
                    'reported': $gettext('Issue is reported, action is required.'),
                    'in-progress': $gettext('Fix is in progress.'),
                    'retest': $gettext('Issue has been fixed and requires retesting')
                  }"
                  />
              </span>
              <span v-else>
                <AriaListBox idRoot="instance_" :label="$gettext('Status')" :labelVisible="false" v-model="instance.status" @change="statusChange"
                  :options="{
                    'reported': $gettext('Reported'),
                    'closed-removed': $gettext('Closed Removed'),
                    'closed-fixed': $gettext('Closed Fixed'),
                    'in-progress': $gettext('In Progress'),
                    'retest': $gettext('To Retest')
                  }"
                  :extra="{
                    'reported': $gettext('Issue is reported, action is required.'),
                    'closed-removed': $gettext('Component with issue has been removed or mitigated.'),
                    'closed-fixed': $gettext('Issue has been fixed.'),
                    'in-progress': $gettext('Fix is in progress.'),
                    'retest': $gettext('Issue has been fixed and requires retesting')
                  }"
                />
              </span>
            </dd>
          </div>
          <div v-if="instance.reportVersion.report.team || instance.reportVersion.report.portfolio">
            <dt v-translate>Assignee</dt>
            <dd>
              <!-- <span v-if="instance.assignee">{{ instance.assignee }}</span>
              <span v-else>Not assigned</span> -->
              <AriaListBox idRoot="instance_" :label="$gettext('Assignee')" :labelVisible="false" v-model="assignee" @change="assigneeChange"
                  :options="assignOpts"
                />
            </dd>
          </div>
          <div>
            <dt v-translate>Reporter</dt>
            <dd>
              <span v-if="instance.reporter">{{instance.reporter.name}} <template v-if="instance.reporter.email!= ''">({{instance.reporter.email}})</template></span>
              <span v-else v-translate>Unavailable</span>
            </dd>
          </div>
          <div>
            <dt id="severitylabel" v-translate>Severity</dt>
            <dd>
              <span> <!-- this is a todo -->
                <span v-if="instance.template.severity==0"><Icon type="regular" icon="lightbulb"/>&nbsp;{{$gettext('Advisory')}}</span>
                <span v-else-if="instance.template.severity==1"><Icon type="solid" icon="arrow-down"/>&nbsp;{{$gettext('Low')}}</span>
                <span v-else-if="instance.template.severity==2"><Icon type="solid" icon="minus"/>&nbsp;{{$gettext('Medium')}}</span>
                <span v-else-if="instance.template.severity==3"><Icon type="solid" icon="arrow-up"/>&nbsp;{{$gettext('High')}}</span>
                <span v-else-if="instance.template.severity==4"><Icon type="solid" icon="exclamation-triangle"/>&nbsp;{{$gettext('Critical')}}</span>
              </span>
            </dd>
          </div>

          <div>
            <dt id="prioritylabel" v-translate>Priority</dt>
            <dd>
              <span v-if="!editPriority"> <!-- this is a todo -->
                <span v-if="instance.priority==0"><Icon type="solid" icon="arrow-down"/>&nbsp;{{$gettext('Lowest')}}</span>
                <span v-else-if="instance.priority==1"><Icon type="solid" icon="arrow-down"/>&nbsp;{{$gettext('Low')}}</span>
                <span v-else-if="instance.priority==2"><Icon type="solid" icon="minus"/>&nbsp;{{$gettext('Medium')}}</span>
                <span v-else-if="instance.priority==3"><Icon type="solid" icon="arrow-up"/>&nbsp;{{$gettext('High')}}</span>
                <span v-else-if="instance.priority==4"><Icon type="solid" icon="arrow-up"/>&nbsp;{{$gettext('Highest')}}</span>

                <Button class="changePriorityBtn" type="transparent" size="micro" :icon="['regular', 'edit', 'after']" @click="()=> { editPriority = true }">{{$gettext('Change')}}</Button>
              </span>
              <span v-else>
                <AriaListBox idRoot="instance_" :label="$gettext('Priority')" :labelVisible="false" v-model="instance.priority" @change="changePriority"
                  :options="{
                    '0': $gettext('Lowest'),
                    '1': $gettext('Low'),
                    '2': $gettext('Medium'),
                    '3': $gettext('High'),
                    '4': $gettext('Highest')
                  }"
                />
              </span>
            </dd>
          </div>

          <div v-if="instance.component">
            <dt v-translate>Component</dt>
            <dd>
              {{instance.component.identifier}}
            </dd>
          </div>

          <div v-if="instance.component"> <!-- Multple pages because component -->
            <dt v-translate>Pages in component</dt>
            <dd>
              <ul>
                <li v-for="page in instance.component.pages" v-bind:key="page.name">
                  {{page.name}}
                </li>
              </ul>
            </dd>
          </div>
          <div v-else> <!-- single page or group -->
            <dt v-translate>Pages</dt>
            <dd>
              <ul aria-live="polite">
                <li v-if="instance.page">

                  <Button v-if="instance.others.length&&!instance.reportVersion.published" type="icon" size="micro" :icon="['solid', 'times']" @click.prevent="removePrimaryPage()">{{$gettext('Remove instance')}}</Button>

                  {{instance.page.name}}

                  <LinkButtonExt :to="`${instance.page.host}${instance.page.path}`" :external="true" type="icon" size="micro" :icon="['solid', 'external-link-alt']"><translate :translate-params="{name: instance.page.name}">Open %{name} in new tab</translate></LinkButtonExt>
                </li>
                <li v-else v-translate>No key page</li>
                <li v-for="other in instance.others" v-bind:key="other.page.name">

                  <Button v-if="!instance.reportVersion.published" type="icon" size="micro" :icon="['solid', 'times']" @click.prevent="removeGroupPage(other._id)">{{$gettext('Remove instance')}}</Button>

                  {{other.page.name}}

                  <LinkButtonExt :to="`${other.page.host}${other.page.path}`" type="icon" size="micro" :icon="['solid', 'external-link-alt']"><translate :translate-params="{name: other.page.name}">Open %{name} in new tab</translate></LinkButtonExt>
                </li>
              </ul>
              <span v-if="user.aclevel<=2&&!instance.reportVersion.published">
                <AriaSearchSelect :label="$gettext('Add a Page')" :icon="['regular', 'file']" :iconOnly="true" :dropdown="true" idRoot="page" :gqlOptions="['PAGES_BY_REPORT', instance.reportVersion._id]"  @selected="addGroupPage"/>
              </span>
            </dd>
          </div>

          <div>
            <dt id="qastatuslabel" v-translate>QA Status</dt>
            <dd>
              <AriaListBox v-if="!instance.reportVersion.published" idRoot="instance_" :label="$gettext('QA Status')" :labelVisible="false" v-model="instance.flag" @change="flagChange"
                :options="{
                  'unchecked': $gettext('Unchecked'),
                  'to-check': $gettext('To Check'),
                  'checked': $gettext('Checked'),
                  'change-required': $gettext('Change Required'),
                  'second-opinion': $gettext('Second Opinion')
                }"
                :extra="{
                  'unchecked': $gettext(`Issue hasn't been checked`),
                  'to-check': $gettext('Issue needs to be checked'),
                  'checked': $gettext('Issue has been checked and confirmed'),
                  'change-required': $gettext('A change is required before the issue can be confirmed'),
                  'second-opinion': $gettext('Issue has been checked but a second opinion is required')
                }"
              />
              <span v-else>{{ instance.flag }}</span>
            </dd>
          </div>

          <div>
            <dt v-translate>Testers</dt>
            <dd>
              <ul>
                <li v-if="instance.reportVersion.report.owner">{{instance.reportVersion.report.owner.name}} ({{instance.reportVersion.report.owner.email}})</li>
                <li v-else v-translate>Unknown</li>
                <li v-for="collaborator in instance.reportVersion.report.collaborators" v-bind:key="collaborator.email">
                  {{collaborator.name}} ({{collaborator.email}})
                </li>
              </ul>
            </dd>
          </div>

          <div v-if="user.aclevel<=2&&!instance.reportVersion.published">
            <dt>{{$gettext('Not')}} {{instance.template.identifier}}?</dt>
            <dd>
              <Button size="micro" type="border" @click="$refs.mutateissuemodal.show()">{{$gettext('Change issue template')}}</Button>
            </dd>
          </div>
          <div v-if="user.aclevel==0 || hasPermission('Issues','ToggleKeyIssue')">
            <dt v-if="instance.isAKeyIssueInstance">{{$gettext('This is marked as a key issue.')}}</dt>
            <dt v-else>{{$gettext('This is not marked as a key issue.')}}</dt>
            <template v-if="!instance.reportVersion.published">
              <dd v-if="instance.isAKeyIssueInstance">
                <Button size="micro" type="border" @click="changeKeyIssue(false)">{{$gettext('Change into non-key issue')}}</Button>
              </dd>
              <dd v-else>
                <Button size="micro" type="border" @click="changeKeyIssue(true)">{{$gettext('Change into key issue')}}</Button>
              </dd>
            </template>
          </div>
        </dl>
        <div class="traversal">
          <LinkButton :icon="['solid', 'chevron-left']" class="t_prev" type="border" size="micro" :to="`/issue/${instance.prevInstance.identifier}`" v-if="instance.prevInstance">{{$gettext('Previous item')}}</LinkButton>
          <LinkButton :icon="['solid', 'chevron-right', 'after']" class="t_next" type="border" size="micro" :to="`/issue/${instance.nextInstance.identifier}`" v-if="instance.nextInstance">{{$gettext('Next item')}}</LinkButton>
        </div>
      </Aside>

      <div class="edit-head">
        <h3 v-translate>Comments</h3>
      </div>
      <Comments :refresh="commentKey" v-bind:reference="instance._id" :team="instance.reportVersion.report.team?instance.reportVersion.report.team._id:( instance.reportVersion.report.portfolio ? instance.reportVersion.report.portfolio.teams[0]._id : false )" :fileUploadEnabled="hasPermission('Files','Upload')" :reportID="instance.reportVersion.report._id"/>

    </Body>
    <IssueDetailsModal ref="issuedetailsmodal"/>
    <MutateIssueModal ref="mutateissuemodal" :id="instance._id" :report="instance.reportVersion._id" />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapGetters } from 'vuex';
import UIBody from '@/components/UI/Body';
import UINotice from '@/components/UI/Notice';
import UIAside from '@/components/UI/Aside';

import UIGallery from '@/components/UI/Gallery';
// import FormImage from '@/components/Form/Image';
import FormTextArea from '@/components/Form/TextArea';

import Link from '@/components/Helpers/Link';

import AriaSearchSelect from '@/components/Aria/SearchSelect2';
import AriaListBox from '@/components/Aria/ListBox';

import Comments from '@/components/Comments/Index';

import IssueDetailsModal from '@/modals/IssueTemplate/Details';
import MutateIssueModal from '@/modals/IssueInstance/Mutate';
import config from '../../../config';
//TODO manage if report doesn't match issue

export default {
  name: 'IssueInstanceView',
  data() {
    return {
      identifier: this.$route.params.identifier,

      originalStatus: false,
      commentKey: 0,

      report: false,
      instance: false,
      edit: {
        particulars: false,
        solutions: false,
        priority: false,
        bkp: {},
      },

      group: false,
      groupInstances: false,

      solutionsChanged: false,

      showAside: window.innerWidth > 1000,

      editPriority: false,
    };
  },
  mounted() {
    this.$apollo.mutate( {
      mutation: gql`
        mutation ($page: String) {
          sendNavigation(page: $page)
        }
      `,
      variables: {
        page: 'Issue Instance',
      },
    } );
  },
  watch: {
    $route() {
      this.identifier = this.$route.params.identifier;
      this.$apollo.queries.instance.refetch();
    },
    instance( { reportVersion } ) {
      if ( !reportVersion ) return;

      this.$router.push( `/${config.reportRouterReplacement}s/${reportVersion.report.identifier}/${reportVersion.version}/issues/${this.identifier}` );

      const currentInstanceVersion = reportVersion.version;

      const latestReportVersion = reportVersion.report.currentVersion.version;

      if ( Number( currentInstanceVersion ) < Number( latestReportVersion ) ) {
        this.$alerts.warn( this.$gettext( 'This issue is on an older version of this %{projectString}. The latest version is %{latestVersion}', { projectString: this.$hugrConfig.projectStringCap, latestVersion: latestReportVersion } ) );
      }
    },
  },
  apollo: {
    instance: {
      query: gql`
        query IssueInstance($ident: String!) {
          instance: IssueInstance(identifier: $ident) {
            _id,
            identifier,
            evidence,
            reason,
            priority,
            status,
            flag,
            isAKeyIssueInstance,

            nextInstance {
              _id,
              identifier
            }
            prevInstance {
              _id,
              identifier
            }

            reportVersion {
              _id
              version
              published
              report {
                currentVersion {
                  _id
                  version
                }
                _id
                identifier
                owner {
                  _id,
                  name,
                  email
                }
                collaborators {
                  _id,
                  name,
                  email
                }
                team {
                  _id
                  users {
                    _id,
                    name,
                    email
                  }
                }
                portfolio {
                  _id
                  teams {
                    _id
                    users {
                      _id,
                      name,
                      email
                    }
                  }
                }
              }
            }

            reporter {
              _id,
              name,
              email
            }

            assignee {
              _id,
              name,
              email
            }

            template {
              _id,
              identifier,
              title,
              description,
              severity
              solutions {
                _id
              }
            },

            solutions {
              _id,
              identifier,
              title
            }

            page {
              _id
              name
              host
              path
            }
            component {
              _id
              identifier,
              pages {
                _id
                name
              }
            }

            others {
              _id
              page {
                _id
                name
                host
                path
              }
            }
          }
        }
      `,
      variables() {
        return {
          ident: this.identifier,
        };
      },
      update: data => {
        const ret = { ...data.instance };

        //Uncaught TypeError: Cannot add property 0, object is not extensible??
        ret.evidence = Object.assign( [], ret.evidence );
        ret.solutions = Object.assign( [], ret.solutions );

        return ret;
      },
      fetchPolicy: 'no-cache',
    },
  },
  methods: {
    editSolutions() {
      this.edit.solutions = true;
      this.edit.bkp.solutions = [];
      for( const solution of this.instance.solutions ) {
        this.edit.bkp.solutions.push( solution );
      }
    },
    addSolution( solution ) {
      if( this.instance.solutions.map( s => s._id ).indexOf( solution ) < 0 ) {
        this.$apollo.query( {
          query: gql`
            query Solution($id: ObjectID!) {
              solution: Solution(id: $id) {
                _id,
                identifier,
                title
              }
            }
          `,
          variables: {
            id: solution,
          },
        } ).then( res => {
          this.instance.solutions.push( res.data.solution );
          this.solutionsChanged = true;
        } );
      } else {
        this.$alerts.warn( this.$gettext( 'Solution already included' ) );
      }
    },
    rmSolution( solution ) {
      this.instance.solutions.remove( solution );
      this.solutionsChanged = true;
    },
    saveSolutions() {
      this.$apollo.mutate( {
        mutation: gql`
          mutation updateIssueInstanceSolutions($id: ObjectID!, $solutions: [ObjectID]!) {
            instance: updateIssueInstanceSolutions(id: $id, solutions: $solutions) {
              _id,
              identifier,
              evidence,
              reason,
              priority,
              status,
              flag,

              assignee {
                _id
                name
                email
              }

              page {
                _id
                name
              }

              others {
                _id
                page {
                  _id
                  name
                }
              }

              solutions {
                _id,
                identifier,
                title
              }
            }
          }
        `,
        variables: {
          id: this.instance._id,
          solutions: this.instance.solutions.map( solution => solution._id ),
        },
      } ).then( res => {
        this.instance.solutions = res.data.instance.solutions;
        this.solutionsChanged = false;
        this.edit.solutions = false;
        this.$alerts.success( 'Solutions updated', `Issue instance solutions have been updated` );
      } ).catch( err => {
        this.$alerts.error( `Sorry, we couldn't update the solution :/`, `` );
        //roll back
        this.cancelSolutions();
      } );
    },
    cancelSolutions() {
      this.instance.solutions = [];
      for( const solution of this.edit.bkp.solutions ) {
        this.instance.solutions.push( solution );
      }
      this.solutionsChanged = false;
      this.edit.solutions = false;
    },

    editParticulars() {
      this.edit.particulars = true;
      this.edit.bkp.particulars = {
        evidence: [ this.instance.evidence[0] ],
        reason: this.instance.reason,
      };
    },
    changeKeyIssue( newValue ) {
      this.$apollo.mutate( {
        mutation: gql`
          mutation setIssueInstanceKeyIssue($id: ObjectID!, $value: Boolean!) {
            result: setIssueInstanceKeyIssue(id: $id, value: $value)
          }
        `,
        variables: {
          id: this.instance._id,
          value: newValue,
        },
      } ).then( res => {
        if( res.data.result == true ) {
          if( newValue ) {
            this.$alerts.success( this.$gettext( 'Success' ), this.$gettext( `Issue is now marked as a key issue.` ) );
          } else {
            this.$alerts.success( this.$gettext( 'Success' ), this.$gettext( `Issue is now no longer marked as a key issue.` ) );
          }
          this.instance.isAKeyIssueInstance = newValue;
        } else {
          this.$alerts.error( this.$gettext( `Oops. Something went wrong` ), this.$gettext( `Please try again later or contact us if the issue persists.` ) );
        }
      } ).catch( err => {
        this.$alerts.error( `Oops. Something went wrong`, `Please try again later or contact us if the issue persists.` );
      } );
    },
    saveParticulars() {
      this.$apollo.mutate( {
        mutation: gql`
          mutation updateIssueInstanceParticulars($id: ObjectID!, $evidence: [String]!, $reason: String!) {
            instance: updateIssueInstanceParticulars(id: $id, evidence: $evidence, reason: $reason) {
              _id,
              identifier,
              evidence,
              reason,
              priority,
              status,
              flag,
            }
          }
        `,
        variables: {
          id: this.instance._id,
          evidence: [],//this.instance.evidence[0]==this.edit.bkp.particulars.evidence[0]?[]:this.instance.evidence,
          reason: this.instance.reason,
        },
      } ).then( res => {
        this.instance.evidence = res.data.instance.evidence;
        this.instance.reason = res.data.instance.reason;
        this.$alerts.success( 'Particulars updated', `Issue instance particulars have been updated` );
        setTimeout( () => {
          this.edit.particulars = false;
        }, 100 );
      } ).catch( () => {
        this.$alerts.error( `Oops. We can't update that at the moment :/`, `There's an issue on the backend.` );
        //roll back
        this.cancelParticulars();
      } );
    },
    cancelParticulars() {
      this.instance.reason = this.edit.bkp.particulars.reason;
      this.edit.particulars = false;
    },

    doComment( comment ) {
      this.$apollo.mutate( {
        mutation: gql`
          mutation addComment($comment: CommentInput!) {
            comment: addComment(comment: $comment) {
              _id
            }
          }
        `,
        variables: {
          comment: {
            reference: this.instance._id,
            from: this.user.id,
            contents: comment,
            edited: false,
          },
        },
      } ).then( res => {
        this.$alerts.success( 'Comment posted!', `Your comment has successfully been posted.` );
        this.commentKey += 1;
        this.$forceUpdate();
      } ).catch( err => {
        this.$alerts.error( `The comment can't be added`, `It's not you it's us. We're still in Alpha so please bear with us and <a target="_blank" href="https://hugr.community/c/Post-any-questions-were-here-to-help-and-hopefully-the-answers-will-help-others-too/bugs/">report a bug</a>` );
      } );
    },
    statusChange( e, to ) {
      const status = to || this.instance.status;
      this.$apollo.mutate( {
        mutation: gql`
          mutation setIssueInstanceStatus($id: ObjectID!, $status: String!) {
            instance: setIssueInstanceStatus(id: $id, status: $status) {
              oldStatus
              issue {
                status
              }
            }
          }
        `,
        variables: {
          id: this.instance._id,
          status,
        },
      } ).then( res => {
        const { status } = res.data.instance.issue;
        this.$alerts.success( 'Status changed', `Issue instance status has been changed to ${this.instance.status}` );

        const positive = [ 'closed-fixed' ];

        const negative = [ 'reported' ];

        const indifferent = [ 'closed-removed' ];

        if ( [ ...positive, ...negative, ...indifferent ].indexOf( status ) >= 0 ) {

          const prefilledMessage = () => {
            if ( positive.includes( status ) ) return 'Fixed: ';
            if ( negative.includes( status ) ) return 'Not Fixed: ';
            if ( indifferent.includes( status ) ) return '';

            return '';
          };

          this.$confirm.input( 'Add a comment about this change?', prefilledMessage() ).then( ( [ yesNo, commentToAdd ] ) => {
            if ( yesNo ) {
              this.doComment( commentToAdd );
            }
          } );
        }
      } ).catch( err => {
        this.$alerts.error( `Issue status not changed`, `There's a bug and you've found it` );
        //TODO this should change it back
      } );
    },
    flagChange( e, to ) {
      const flag = to || this.instance.flag;
      this.$apollo.mutate( {
        mutation: gql`
          mutation setIssueInstanceFlag($id: ObjectID!, $flag: String!) {
            instance: setIssueInstanceFlag(id: $id, flag: $flag) {
              flag
            }
          }
        `,
        variables: {
          id: this.instance._id,
          flag,
        },
      } ).then( res => {
        this.$alerts.success( 'QA Status changed', `Issue instance QA status has been changed to ${this.instance.flag}` );
      } ).catch( err => {
        this.$alerts.error( `QA status not changed`, `There's a bug and you've found it` );
        //TODO this should change it back
      } );
    },
    changePriority( e, to ) {
      const priority = parseInt( to );
      this.$apollo.mutate( {
        mutation: gql`
          mutation setIssueInstancePriority($id: ObjectID!, $priority: Int!) {
            instance: setIssueInstancePriority(id: $id, priority: $priority) {
              priority
            }
          }
        `,
        variables: {
          id: this.instance._id,
          priority,
        },
      } ).then( res => {
        this.instance.priority = res.data.instance.priority;
        this.$alerts.success( 'Priority updated', `Issue instance priority has been updated` );
        this.editPriority = false;
      } ).catch( err => {
        this.$alerts.error( `Issue priority not changed`, `There's a bug and you've found it` );
        //TODO this should change it back
      } );
    },

    assigneeChange( e, to ) {
      const assignee = to || this.assignee;
      if( this.assignee != 0 ) {
        this.$apollo.mutate( {
          mutation: gql`
            mutation setIssueInstanceAssignee($id: ObjectID!, $assignee: ObjectID!) {
              instance: setIssueInstanceAssignee(id: $id, assignee: $assignee) {
                assignee {
                  _id,
                  name,
                  email
                }
              }
            }
          `,
          variables: {
            id: this.instance._id,
            assignee,
          },
        } ).then( res => {
          this.$alerts.success( 'Assignee changed', `Issue instance has been assigned to ${this.instance.assignee.name}` );
        } ).catch( err => {
          this.$alerts.error( `Issue status not assigned`, `There's a bug and you've found it` );
          //TODO this should change it back
        } );
      } else {
        this.$apollo.mutate( {
          mutation: gql`
            mutation setIssueInstanceUnAssigned($id: ObjectID!) {
              instance: setIssueInstanceUnAssigned(id: $id) {
                assignee {
                  _id,
                  name,
                  email
                }
              }
            }
          `,
          variables: {
            id: this.instance._id,
          },
        } ).then( res => {
          this.$alerts.success( 'Assignee changed', `Issue instance has been unassigned` );
        } ).catch( err => {
          this.$alerts.error( `Issue status has not been unassigned`, `There's a bug and you've found it` );
          //TODO this should change it back
        } );
      }
    },

    addGroupPage( page, display ) {
      if( this.instance.page._id == page || this.instance.others.map( instance => instance.page._id ).indexOf( page ) >= 0 ) {
        this.$alerts.warn( 'Issue already on this page', `This issue is already associated with this page, it can't be added again.` );
      } else {
        this.$apollo.mutate( {
          mutation: gql`
            mutation addIssueInstancePage($parent: ObjectID!, $page: ObjectID!) {
              instance: addIssueInstancePage(parent: $parent, page: $page) {
                _id,
                identifier,
                evidence,
                reason,
                priority,
                status,
                flag,

                assignee {
                  _id
                  name
                  email
                }

                page {
                  _id
                  name
                }
                others {
                  _id
                  page {
                    _id
                    name
                  }
                }
              }
            }
          `,
          variables: {
            parent: this.instance._id,
            page,
          },
        } ).then( res => {
          this.instance.page = res.data.instance.page;
          this.instance.others = res.data.instance.others;
          this.assignee = res.data.instance.assignee;
          this.$alerts.success( 'Page added', `Instance has been added to page ${display}` );
        } ).catch( err => {
          this.$alerts.error( `Dang it. We can't add this page right now :/`, `This is definitely a bug.` );
        } );
      }
    },
    removeGroupPage( id ) {
      this.$apollo.mutate( {
        mutation: gql`
          mutation removeIssueInstancePage($parent: ObjectID!, $id: ObjectID!) {
            instance: removeIssueInstancePage(parent: $parent, id: $id) {
              _id,
              identifier,
              evidence,
              reason,
              priority,
              status,
              flag,

              assignee {
                _id
                name
                email
              }

              page {
                _id
                name
              }
              others {
                _id
                page {
                  _id
                  name
                }
              }
            }
          }
        `,
        variables: {
          parent: this.instance._id,
          id,
        },
      } ).then( res => {
        this.instance.page = res.data.instance.page;
        this.assignee = res.data.instance.assignee;
        this.instance.others = res.data.instance.others;
        this.$alerts.success( 'Page removed', `Instance has been removed from page` );
      } ).catch( err => {
        this.$alerts.error( `Oh! We can't remove this issue`,`` );
      } );
    },

    removePrimaryPage() {
      this.$apollo.mutate( {
        mutation: gql`
          mutation changeIssueInstancePage($issue: ObjectID!) {
            instance: changeIssueInstancePage(issue: $issue) {
              _id,
              identifier,
              evidence,
              reason,
              priority,
              status,
              flag,

              assignee {
                _id
                name
                email
              }

              page {
                _id
                name
              }
              others {
                _id
                page {
                  _id
                  name
                }
              }
            }
          }
        `,
        variables: {
          issue: this.instance._id,
        },
      } ).then( res => {
        this.instance.page = res.data.instance.page;
        this.instance.others = res.data.instance.others;
        this.$alerts.success( 'Page removed', `Instance has been removed from page` );
      } ).catch( err => {
        this.$alerts.error( `Oh! We can't remove this issue`, `` );
      } );
    },

    toggleAside() {
      this.showAside = !this.showAside;
    },
  },
  computed: {
    ...mapGetters( [ 'hasPermission' ] ),
    ...mapState( [ 'user' ] ),
    assignee: {
      get() {
        if( this.instance.assignee ) {
          return this.instance.assignee._id;
        }

          return 0;

      },
      set( v ) {
        if( v == 0 ) {
          this.instance.assignee = null;
        } else if( this.instance.reportVersion.report.team ) {
            for( const user of this.instance.reportVersion.report.team.users ) {
              if( user._id == v ) {
                this.instance.assignee = user;
              }
            }
          } else if( this.instance.reportVersion.report.portfolio ) {
            for( const team of this.instance.reportVersion.report.portfolio.teams ) {
              for( const user of team.users ) {
                if( user._id == v ) {
                  this.instance.assignee = user;
                }
              }
            }
          }
      },
    },
    assignOpts() {
      const res = {
        0: 'Unassigned',
      };

      if( this.instance.reportVersion.report.team ) {
        for( const user of this.instance.reportVersion.report.team.users ) {
          res[user._id] = `${user.name} (${user.email})`;
        }
      } else if( this.instance.reportVersion.report.portfolio ) {
        for( const team of this.instance.reportVersion.report.portfolio.teams ) {
          for( const user of team.users ) {
            if( Object.keys( res ).indexOf( user._id ) < 0 ) {
              res[user._id] = `${user.name} (${user.email})`;
            }
          }
        }
      }

      return res;
    },
  },
  components: {
    Body: UIBody,
    Notice: UINotice,
    Aside: UIAside,

    Gallery: UIGallery,
    // FormImage,
    FormTextArea,

    ALink: Link, //fixes invalid end tag issue

    AriaSearchSelect,
    AriaListBox,

    Comments,

    IssueDetailsModal,
    MutateIssueModal,

  },
};
</script>

<style lang="scss">
.box {
  border: 1px solid #b2b3b1;
  width: 100%;
  min-height: 100px;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 1rem;
}
</style>
<style lang="scss" scoped>
@import '@/assets/styles/variables/_colours.scss';

  .Instance {
    &_Notice {
      position: absolute;
      width: 50%;
      max-width: 300px;
      top: 18px;
      right: 18px;
    }
  }

  ._asideInfo {
    display: none !important;
  }

  .Instance {
    &_Change {
      float: right;
      position: relative;
      top: -75px;
    }
  }

  div.edit-head {
    border-bottom: 1px solid #dce4ee;
    margin-bottom: 15px;
    margin-top: 30px;
    padding-bottom: 1px;
    h3 {
      margin: 5px 0;
    }
    button {
      float: right;
      position: relative;
      top: -32px;
    }
  }

  figure {
    border: 1px solid lighten( $hugr-colours-primary, 50% );
    border-top: none;
    background: #f6f8fa;
    padding: 10px;
    margin: 0;

    transition: background-color .5s ease-in-out 0s;
    img {
      max-width: 100%;
    }
  }

  .changePriorityBtn {
    float: right;
    position: relative;
    top: -28px;
  }

  .traversal {
    position: relative;
    .t_prev {
      left: 0;
    }
    .t_next {
      right: 0;
      position: absolute;
    }
  }

  ._darkMode {
    figure {
      background: darken($hugr-colours-primary, 5%);
    }
  }

  .Instance {
    &_Particulars {
      position: relative;
      margin-bottom: 60px;
      &_Actions {
        position: absolute;
        right: 0;
        button {
          margin-left: 5px;
        }
      }
    }

    &_Solutions {
      position: relative;
      margin-bottom: 60px;
      &_Actions {
        position: absolute;
        right: 0;
        button {
          margin-left: 5px;
        }
      }
    }
  }

  @media (max-width: 1000px) {
    ._asideInfo {
      display: inline-block !important;
      position: absolute;
      right: 10px;
      top: 60px;
    }
    .aside {
      &._hide {
        display: none;
      }
    }

    .Instance {
      &_Change {
        top: -55px;
      }
    }
  }
</style>
