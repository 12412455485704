<template>
  <Body :title="$gettext('Dashboard')">
    <div class="first-use" v-if="!dismissDashboardVideo||dismissDashboardVideo!='true'">
      <Notice class="Dashboard_Welcome" size="small" @dismissed="doDismiss">
        <!-- <iframe class="Dashboard_Welcome_Video" width="560" height="315" src="https://www.youtube.com/embed/AT4LJHe_msg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
        <div class="Dashboard_Welcome_Content" v-if="$hugrConfig.appId.indexOf('ho') == 0">
          <p>Here you can log, discuss, resolve and report on accessibility issues with services that are provided or used by Home Office and its staff.</p>
          <p>This will help to give people with access needs equality of experience and opportunity. It will also aid our levels of quality, satisfaction, effectiveness, efficiency, reputation and compliance.</p>
          <p>If you have any questions, ideas or feedback contact the accessibility team.</p>
        </div>
        <div class="Dashboard_Welcome_Content" v-else>
          <h3 v-translate>Let's make accessibility accessible!</h3>
          <p>{{$gettext( 'To get you started we have some instructions to guide you through creating your first %{projectString}', { projectString: this.$hugrConfig.projectString } ) }} <a href="https://docs.hugr.app/#/intro">{{$gettext('HUGR instructions')}}</a></p>
          <p>{{$gettext(`Any questions please do get in touch, we're here to help`)}} <a href="mailto:hello@diginclusion.com">hello@diginclusion.com</a>.</p>
          <!-- <Button type="border" @click="doDismiss">{{$gettext('Dismiss')}}</Button> -->
        </div>
      </Notice>
    </div>
    <div>
      <p v-translate>{{ $gettext( 'This shows your most recent %{projectString}s from the last seven days and the most recent changes to your %{projectString}s.', { projectString: this.$hugrConfig.projectString } ) }}</p>
      <p v-translate>{{$gettext( 'You can open one of your %{projectString}s or go to specific issues in the activity stream to view the changes.', { projectString: this.$hugrConfig.projectString } )}}</p>
      <div class="Dashboard_Item">
        <h3 v-translate>On Deck</h3>
        <!-- <Notice size="small">Shows reports opened in the last 7 days</Notice> -->
        <OnDeck />
        <!-- <Notice size="small">
          TODO: (will eventually show)
          <ul>
            <li>Opened in last 7 days</li>
            <li>Created in the last 7 days and owned by you</li>
            <li>Created in the last 7 days and has you as collaborator</li>
          </ul>
        </Notice> -->
      </div>

      <div class="Dashboard_Item">
        <h3 v-translate>Recent Activity</h3>
        <!-- <Notice size="small">This is in progress</Notice> -->
        <Stream />
      </div>
    </div>
  </Body>
</template>

<script>
import gql from 'graphql-tag';

import { mapState } from 'vuex';

import UIBody from '@/components/UI/Body';

import Stream from '@/components/Stream';
import OnDeck from '@/components/OnDeck';

export default {
  name: 'DashboardView',
  apollo: {
    dismissDashboardVideo: {
      query: gql`
        query GetUserSetting($id: ObjectID!, $key: String!) {
          dismissDashboardVideo: GetUserSetting(id: $id, key: $key)
        }
      `,
      variables() {
        return {
          id: this.user.id,
          key: 'dismissDashboardVideo',
        };
      },
    },
  },
  methods: {
    doDismiss() {
      this.$apollo.mutate( {
        mutation: gql`
          mutation setUserSetting($id: ObjectID!, $key: String!, $value: String!) {
            res: setUserSetting(id: $id, key: $key, value: $value)
          }
        `,
        variables: {
          id: this.user.id,
          key: 'dismissDashboardVideo',
          value: 'true',
        },
      } ).then( res => {
        this.$apollo.queries.dismissDashboardVideo.refetch();
      } );
    },
  },
  computed: {
    ...mapState( [ 'user' ] ),
    // ...mapState(['firstUse'])
  },
  components: {
    Body: UIBody,
    OnDeck,
    Stream,
  },
};
</script>

<style lang="scss" scoped>
  .Dashboard {
    &_Welcome {
      &_Video {
        display: inline-block;
        vertical-align: top;
        // width: 50%;
      }
      &_Content {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        padding: 0 20px;
        padding-left: 50px;
        box-sizing: border-box;
        h3 {
          margin-top: 0;
        }
        a {
          color: #FFF;
        }
      }
    }

    &_Item {
      width: calc(50% - 75px);
      display: inline-block;
      vertical-align: top;
      margin-right: 75px;
    }
  }

  @media screen and (max-width: 800px) {
    .Dashboard {
      &_Welcome {
        &_Video {

        }
        &_Content {
          width: 100%;
          padding: 0 5px;
        }
      }
      &_Item {
        display: block;
        width: 100%;
      }
    }
  }
</style>
