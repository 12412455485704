<template>
  <Body :title="$gettext('Customers')" :fill="true">
    <!-- <div v-if="customers"> -->
      <!-- <SortBy class="Customers_Sort" :options="sortOpts" /> -->

      <DataTable  v-if="customers.length" class="Customers_Table"
                  :headers="[
          $gettext( 'Name' ),
          $gettext( 'Last %{projectString} Accessed', { projectString: $hugrConfig.projectStringCap } ),
          $gettext( 'Last %{projectString} Created', { projectString: $hugrConfig.projectStringCap } ),
          $gettext( 'Associated %{projectString}s', { projectString: $hugrConfig.projectStringCap } ),
          $gettext( 'Associated Teams' ),
          $gettext( 'Associated Portfolios' ),
          $gettext( 'Actions' ),
        ]">
        <tr v-for="customer in customers" v-bind:key="'cus-' + customer._id">
          <td>{{ customer.name }}</td>
          <td v-if="customer.lastReportAccessed">{{ new Date(customer.lastReportAccessed).toLocaleDateString() }}</td>
          <td v-else v-translate>Unknown</td>
          <td v-if="customer.lastReportCreated">{{ new Date(customer.lastReportCreated).toLocaleDateString() }}</td>
          <td v-else v-translate>Unknown</td>
          <td>{{ customer.numberOfAssociatedReports }}</td>
          <td>{{ customer.numberOfAssociatedTeams }}</td>
          <td>{{ customer.numberOfAssociatedPortfolios }}</td>
          <td>
            <Button v-if="hasPermission( 'Customers', 'Update')" class="Button" size="micro" @click="$refs.customermodal.show(customer._id)">{{$gettext('Edit Customer')}}</Button>
            <Button
              class="Button"
              size="micro"
              type="serious"
              @click="deleteCustomer(customer._id)"
              v-if="
                hasPermission( 'Customers', 'Delete' ) &&
                customer.numberOfAssociatedReports==0 &&
                customer.numberOfAssociatedTeams==0 &&
                customer.numberOfAssociatedPortfolios==0
              ">Delete Customer</Button>
          </td>
        </tr>
      </DataTable>
    <!-- </div> -->
    <!-- <Loader v-else /> -->
    <EditCustomerModal ref="customermodal" @success="$apollo.queries.customers.refetch()" />
    <CustomerForm v-if="hasPermission( 'Customers', 'Create' )" @success="$apollo.queries.customers.refetch()" />
  </Body>
</template>

<script>
import gql from 'graphql-tag';
import { mapGetters } from 'vuex';

import CustomerForm from '@/forms/Customer';
import EditCustomerModal from '@/modals/Customer/Edit';

// import UISortBy from '@/components/UI/SortBy.vue';

export default {
  name: 'CustomersView',
  data() {
    return {
      customers: false,
      sortOpts: [
        {
          title: "name",
          click: this.sortByName,
        },
        {
          title: "access date",
          click: this.sortByAccessDate,
        },
        {
          title: "created date",
          click: this.sortByCreateDate,
        },
        {
          title: "report amount",
          click: this.sortByReportAmount,
        },
        {
          title: "teams amount",
          click: this.sortByTeamsAmount,
        },
      ],
    };
  },
  apollo: {
    customers: {
      query: gql`
        {
          customers: Customers {
            _id,
            name,
            lastReportAccessed,
            lastReportCreated,
            numberOfAssociatedReports,
            numberOfAssociatedTeams,
            numberOfAssociatedPortfolios,
          }
        }
      `,
      updated( data ) {
        return { ...data.customers };
      },
    },
  },
  methods: {
    deleteCustomer( id ) {
      this.$confirm.simple( 'Cannot be reversed' ).then( result => {
        if( result ) {
          this.$apollo.mutate( {
            mutation: gql`
              mutation deleteCustomer($id: ObjectID!) {
                removed: deleteCustomer(id: $id)
              }
            `,
            variables: {
              id,
            },
          } ).then( res => {
            if( res.data.removed ) {
              this.$alerts.success( "Customer has been removed" );
              this.$apollo.queries.customers.refetch();
            } else {
              this.$alerts.error( "Customer could not be removed" );
            }
          } ).catch( error => {
            this.$alerts.generic( error );
          } );
        }
      } );
    },
    sortByName() {
      function compare( a, b ) {
        if ( a.name.toLowerCase() < b.name.toLowerCase() ) return -1;
        if ( a.name.toLowerCase() > b.name.toLowerCase() ) return 1;

        return 0;
      }

      return this.customers.sort( compare );
    },
    sortByAccessDate() {
      function compare( a, b ) {
        if ( new Date( a.lastReportAccessed ) > new Date( b.lastReportAccessed ) ) return -1;
        if ( new Date( a.lastReportAccessed ) < new Date( b.lastReportAccessed ) ) return 1;

        return 0;
      }

      return this.customers.sort( compare );
    },
    sortByCreateDate() {
      function compare( a, b ) {
        if ( new Date( a.lastReportCreated ) > new Date( b.lastReportCreated ) ) return -1;
        if ( new Date( a.lastReportCreated ) < new Date( b.lastReportCreated ) ) return 1;

        return 0;
      }

      return this.customers.sort( compare );
    },
    sortByReportAmount() {
      function compare( a, b ) {
        if ( a.numberOfAssociatedReports > b.numberOfAssociatedReports ) return -1;
        if ( a.numberOfAssociatedReports < b.numberOfAssociatedReports ) return 1;

        return 0;
      }

      return this.customers.sort( compare );
    },
    sortByTeamsAmount() {
      function compare( a, b ) {
        if ( a.numberOfAssociatedTeams > b.numberOfAssociatedTeams ) return -1;
        if ( a.numberOfAssociatedTeams < b.numberOfAssociatedTeams ) return 1;

        return 0;
      }

      return this.customers.sort( compare );
    },
  },
  computed: {
    ...mapGetters( [ 'hasPermission' ] ),
  },
  // methods: {
  //   ...mapActions(['jwtemit'])
  // },
  components: {
    CustomerForm,
    EditCustomerModal,
    // SortBy: UISortBy,
  },
};
</script>

<style lang="scss" scoped>
  .Customers {
    &_Sort {
      position: absolute;
      top: 25px;
      right: 50px;
    }
  }

  .Button {
    margin-right: 5px;
  }
</style>
