export default {
  // file deepcode ignore HardcodedNonCryptoSecret: from process, hard coded is for test only
  appSecret: process.env.VUE_APP_APP_SECRET || 'willyWONKAlitany36',
  graphqlServerProtocol: process.env.VUE_APP_GRAPHQL_SERVER_PROTOCOL || 'https',
  graphqlSocketServerProtocol: process.env.VUE_APP_GRAPHQL_SOCKET_SERVER_PROTOCOL || 'wss',
  graphqlServerHost: process.env.VUE_APP_GRAPHQL_SERVER_HOST || 'hugr-backend.docker.diginclusion.com',//'localhost',
  graphqlServerPort: process.env.VUE_APP_GRAPHQL_SERVER_PORT || 443,

  landingPageURL: process.env.VUE_APP_LANDING_URL || 'https://hugr.app',
  jiraClientID: process.env.VUE_APP_JIRA_CLIENT_ID,
  jiraCallbackRoot: process.env.VUE_APP_JIRA_CALLBACK_ROOT || 'https://alpha.hugr.app',

  rootUrl: process.env.VUE_APP_JIRA_CALLBACK_ROOT || 'https://alpha.hugr.app',

  //deprecated below but keep just in case
  socketServerProtocol: process.env.VUE_APP_SOCKET_SERVER_PROTOCOL || 'wss', //https
  socketServerHost: process.env.VUE_APP_SOCKET_SERVER_HOST || 'hugr-backend.docker.diginclusion.com',//'localhost',
  socketServerPort: process.env.VUE_APP_SOCKET_SERVER_PORT || 443,

  assetsUrl: process.env.VUE_APP_ASSETS_URL || 'https://hugr-backend.docker.diginclusion.com',

  locale: process.env.VUE_APP_LOCALE || 'en_GB',

  allowedLocales: process.env.VUE_APP_ALLOWED_LOCALES || 'all',

  authUrl: process.env.VUE_APP_AUTH_URL || 'https://auth.hugr.app',

  appId: process.env.VUE_APP_ID || 'alpha.hugr.app',

  fileContainerURL: process.env.VUE_APP_FILE_CONTAINER_URL || 'https://localhost:5005',
  fileUploadLimitMB: process.env.VUE_APP_FILE_UPLOAD_LIMIT_MB || 50,

  mainShortName: process.env.VUE_APP_MAIN_SHORT_NAME || 'Dig',
  disableGoogle: process.env.VUE_APP_DISABLE_GOOGLE || 'false',
  disableSignup: process.env.VUE_APP_DISABLE_SIGNUP || 'false',

  defaultTemplate: process.env.VUE_APP_DEFAULTTEMPLATE || 'wcag22aa',
  templateChoices: process.env.VUE_APP_TEMPLATECHOICES || '{"wcag22aa":"Default (Accessibility - Web WCAG 2.2 AA)", "wcag22aaa":"Accessibility - Web WCAG 2.2 AAA", "default":"Accessibility - Web WCAG 2.1 AA", "aaa":"Accessibility - Web WCAG 2.1 AAA", "mobios":"Accessibility - Mobile - iOS", "mobandroid":"Accessibility - Mobile - Android", "mobweb":"Accessibility - Mobile - Web", "mobmixed":"Accessibility - Mobile - Mixed","uxdesktop":"UX - Desktop", "uxmob":"UX - Mobile", "other":"Other"}',

  hoMissingProductBannerURL: process.env.VUE_APP_HO_MISSING_PRODUCT_BANNER_URL || '',

  footerPill: process.env.VUE_APP_FOOTER_PILL || '',

  debugMode: process.env.VUE_APP_DEBUG_MODE || 'false',

  footerContact: process.env.VUE_APP_FOOTER_CONTACT || 'hello@diginclusion.com',
  footerForm: process.env.VUE_APP_FOOTER_FORM || '',
  footerFormText: process.env.VUE_APP_FOOTER_FORM_TEXT || 'Report an issue',

  projectString: process.env.VUE_APP_PROJECT_STRING || 'project',
  projectStringCap: ( process.env.VUE_APP_PROJECT_STRING ? `${process.env.VUE_APP_PROJECT_STRING[0].toUpperCase()}${process.env.VUE_APP_PROJECT_STRING.slice( 1 )}` : 'Project' ),
  reportRouterReplacement: process.env.VUE_APP_REPORT_ROUTER_REPLACEMENT || 'project',
  customerString: process.env.VUE_APP_CUSTOMER_STRING || 'customer',
};
