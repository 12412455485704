import Alerts from './components/Alerts';
import { ref } from 'vue';
import config from '../../../config';

const tryAgain = code => { return `Please refresh the page and try again. If this continues to happen please <a href="mailto:hello@diginclusion.com?subject=Hugr bug ref: ${code}">report an issue</a>` };
const noCanDo = `Sorry, it doesn't look like you can do that`;
const genericUser = 'Looks like you missed something!';
const generic = [ 'Something went wrong!', code => `Oops! ${tryAgain( code )}` ];
const errors = {
  E001: generic,
  E002: generic,
  E003: generic,
  E004: generic,
  E005: generic,
  E006: [ generic[0], code => `Could not retrieve unread notifications, ${tryAgain( code )}` ],
  E007: generic,
  E008: [ generic[0], code => `Could not dismiss this notification, ${tryAgain( code )}` ],
  E009: [ generic[0], code => `Could not dismiss notifications, ${tryAgain( code )}` ],
  E010: generic,
  E037: [ genericUser, () => `Please give the group a name and try again.` ],
  E043: [ 'Code is not valid!', () => `Please check the code and try again.` ],
  E079: [ noCanDo, () => this.$gettext( "Looks like you've hit the active %{project} limit for a free user, archive some or set up a team to get more!", { project: this.$hugrConfig.projectString } ) ],
  E085: [ generic[0], code => `Couldn't save desired page size for this table. ${tryAgain( code )}` ],
};

const alertsPlugin = {
  install( app ) {
    app.component( 'hugr-alerts', Alerts );
    const alerts = ref( [] );
    app.provide( '$alertsList', alerts );

    const plugin = {
      coded( code, location, ref = '', error ) {
        let type = 'info';
        if( code[0] == 'E' ) {
          type = 'error';
        }

        let refCode = `${code}-${location}`;
        if( ref != '' ) {
          refCode = `${code}-${location}-${ref}`;
        }

        let title = generic[0];
        let message = generic[1]( refCode );

        if( error && error.message == 'ACL error' ) {
          title = 'Insufficient permissions';
          message = 'You do not have the necessary permission to perform this action.';
          refCode = ref != '' ? `${location}-${ref}` : location;
        } else if( errors.hasOwnProperty( code ) ) {
          // eslint-disable-next-line prefer-destructuring
          title = errors[code][0];
          message = errors[code][1]( refCode );
        }

        alerts.value.push( {
          timestamp: new Date(),
          type,
          title,
          message,
          // icon
          code: refCode,
        } );

        if( config.debugMode == 'true' && error ) {
          console.error( `Error on ${refCode}`, error );
        }
      },
      generic( error ) {
        const type = 'error';

        let title = generic[0];
        let message = generic[1]( '' );
        if( error && error.message == 'ACL error' ) {
          title = 'Insufficient permissions';
          message = 'You do not have the necessary permission to perform this action.';
        }

        alerts.value.push( {
          timestamp: new Date(),
          type,
          title,
          message,
        } );

        if( config.debugMode == 'true' && error ) {
          console.error( `Error on generic`, error );
        }
      },
      error( title, message, icon, error ) {
        if( error && error.message == 'ACL error' ) {
          title = 'Insufficient permissions';
          message = 'You do not have the necessary permission to perform this action.';
        }

        alerts.value.push( {
          timestamp: new Date(),
          type: 'error',
          title,
          message,
          icon,
        } );

        if( config.debugMode == 'true' && error ) {
          console.error( `Error`, error );
        }
      },
      success( title, message, icon ) {
        alerts.value.push( {
          timestamp: new Date(),
          type: 'success',
          title,
          message,
          icon,
        } );
      },
      info( title, message, icon, error ) {
        alerts.value.push( {
          timestamp: new Date(),
          type: 'info',
          title,
          message,
          icon,
        } );

        if( config.debugMode == 'true' && error ) {
          console.error( `Error on ${refCode}`, error );
        }
      },
      warn( title, message, icon, error ) {
        alerts.value.push( {
          timestamp: new Date(),
          type: 'warn',
          title,
          message,
          icon,
        } );

        if( config.debugMode == 'true' && error ) {
          console.error( `Error on ${refCode}`, error );
        }
      },
    };

    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$alerts = plugin;

    app.provide( 'alerts', plugin );
  },
};

export default alertsPlugin;
