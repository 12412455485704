<template>
  <Modal
    ref="modal"
    :title="$gettext( 'New %{projectString}', { projectString: $hugrConfig.projectStringCap } )"
    :showActions="true"
    :submitText="$gettext( 'Add %{projectString}', { projectString: $hugrConfig.projectStringCap } )"
    @submit="submit"
    @close="reset()">
    <FormInput ref="title" idRoot="report_" :label="$gettext('Title')" v-model="title" :validation="['not-empty']" :hintText="$gettext('This will appear at the top of your %{projectString}, you can edit it from settings later', { projectString: $hugrConfig.projectString })"/>

    <template v-if="title!=''">
      <Notice size="micro" v-if="!manualid">
        <div>#<span>{{ identifierDisplay?identifierDisplay:gendentifier }}</span></div>
        <div>
          {{ $gettext( "This is your %{projectString} identifier, it'll be used to find and identify your %{projectString} and issues within it.", { projectString: $hugrConfig.projectString } ) }}
        </div>
        <Button size="micro" @click="manualIdentifier()">{{$gettext('I want to set this manually')}}</Button>
      </Notice>
      <FormInput v-if="manualid" ref="identifier" idRoot="report_" :label="$gettext('Identifier')" v-model="identifier" :hintText="$gettext('This must be unique and may only contain letters, numbers, underscore and hyphen characters')"

        :validation="[{
          regex: /^[a-zA-Z0-9\-\_]*$/,
          message: 'May only contain letters, numbers, underscore and hyphen characters'
        },
        'not-empty']" />
    </template>

    <!-- Team OR portfolio -->

    <TabList v-if="portfoliosOn" class="TeamPortfolioTabList" :lastIndex="lastKnownTab" @tabChanged="lastKnownTab = $event;" :tabs="tabs"/>

    <div :id="portfoliosOn?'tabpane':''">
      <div v-show="tabbedToPortfolio">
        <template v-if="Object.keys(portfolios).length>3">

          <AriaListBox idRoot="report_" :label="$gettext('Portfolio')" v-model="portfolio"
            :options="{
              ...portfolios
            }"
          />

        </template>
        <template v-else>
          <FormFieldSet :legend="$gettext('Portfolio')">
            <div v-for="(portfolioname, portfoliovalue) of portfolios" :key="`portfolio-${portfoliovalue}-container`">
              <input type="radio" ref="portfolioselect" v-model="portfolio" :value="portfoliovalue" :id="`portfolio-${portfoliovalue}`" :validation="['not-empty']"
                :key="`portfolio-${portfoliovalue}`" />
              <label :for="`portfolio-${portfoliovalue}`" :key="`portfolio-${portfoliovalue}-label`">{{portfolioname}}</label>
            </div>
          </FormFieldSet>
        </template>
      </div>

      <div>
        <template v-if="Object.keys(teams).length>3">
          <AriaListBox idRoot="report_" :label="$gettext('Team')" v-model="team"
            :options="{
              '0': $gettext('Just me'),
              ...teams
            }"
          />

        </template>
        <template v-else>
          <FormFieldSet :legend="$gettext('Who will be working on this?')">
            <div>
              <input type="radio" v-model="team" value="0" id="justme" />
              <label for="justme">{{$gettext('Just Me')}}</label>
            </div>
            <div v-for="(teamname, teamvalue) of teams" :key="`team-${teamvalue}-container`">
              <input type="radio" v-model="team" :value="teamvalue" :id="`team-${teamvalue}`"
                :key="`team-${teamvalue}`" />
              <label :for="`team-${teamvalue}`" :key="`team-${teamvalue}-label`">{{teamname}} (Team)</label>
            </div>
          </FormFieldSet>
        </template>
      </div>

      <div  v-show="tabbedToPortfolio">
        <br />
        <div><input id="report-sensitive" type="checkbox" v-model="sensitive" aria-decsribedby="report-sensitive-hint"><label for="report-sensitive">Sensitive</label></div>
        <span class="__small" id="report-sensitive-hint">
          {{ $gettext( 'Ticking this will mean that only the owner and team assigned will be able to access this %{projectString}, not the portfolio principals.',  { projectString: $hugrConfig.projectString } ) }}
        </span>
      </div>
    </div>

    <!-- END Team OR portfolio -->

    <FormFieldSet :legend="productSettings?$gettext('Product Details'):$gettext('%{projectString} Details', { projectString: $hugrConfig.projectStringCap })" v-if="hasPermission('ProductDetails', 'All') || user.aclevel == 0" decoration="divide">
      <FormInput v-if="productSettings" idRoot="_details" :label="$gettext('Aliases')" :hintText="$gettext('Comma separated')" v-model="productAliases" />
      <TextArea idRoot="_details" :label="$gettext('Description')" v-model="productDescription" />
      <FormInput idRoot="_details" :label="$gettext('URL')" v-model="productUrl" />
    </FormFieldSet>

    <template v-if="productSettings">
      <FormFieldSet :legend="$gettext('Product Risk')" v-if="hasPermission('ProductRisk', 'All') || user.aclevel == 0" decoration="divide">
        <Select idRoot="_risk" :label="$gettext('Risk')" v-model="productRisk" :options="[$gettext('Low'), $gettext('Medium'), $gettext('High')]" />
        <TextArea idRoot="_risk" :label="$gettext('Description')" v-model="productRiskDescription" />
      </FormFieldSet>

      <FormFieldSet :legend="$gettext('Disproportionate Burden')"
        v-if="hasPermission('DisproportionateBurden','All') || user.aclevel == 0" decoration="divide">
        <Select idRoot="_burden" :label="$gettext('Disproportionate Burden')" v-model="disproportionateBurden"
          :options="[$gettext('None'), $gettext('Being considered'), $gettext('Made'), $gettext('Expired')]" />
        <TextArea idRoot="_burden" :label="$gettext('Description')" v-model="disproportionateBurdenDescription" />
      </FormFieldSet>

      <FormFieldSet :legend="$gettext('Product Accessibility Statement (Compliance)')"
        v-if="hasPermission('AccessibilityStatement','All') || user.aclevel == 0" decoration="divide">
        <Select idRoot="_a11y" :label="$gettext('Product Accessibility Compliance Statement')" v-model="accessibilityStatement"
          :options="[$gettext('Unknown'), $gettext('Partially - mostly unmet'), $gettext('Partially - mostly met'), $gettext('Met'), $gettext('Exceeded')]" />
        <FormInput idRoot="_details" :label="$gettext('Product Accessibility Compliance Statement URL')" v-model="productStatementUrl" />
        <TextArea idRoot="_a11y" :label="$gettext('Notes')" v-model="accessibilityStatementNotes" />
      </FormFieldSet>
    </template>

    <FormSelect idRoot="report_" :label="$gettext('Report Type')" v-model="template" :options="reportTemplateChoices"/>

    <AriaSearchSelect v-if="parseInt(user.aclevel)<2" v-show="portfolio===''" ref="customerselect" :label="$gettext('Customer')" :dropdown="true" :gqlOptions="['CUSTOMERS']" @selected="setCustomer" :validation="['in-options']"/>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapActions, mapGetters } from 'vuex';

import FormFieldSet from '@/components/Form/FieldSet';
import FormInput from '@/components/Form/Input';
import FormSelect from '@/components/Form/Select';

import AriaSearchSelect from '@/components/Aria/SearchSelect3';
import AriaListBox from '@/components/Aria/ListBox';
import Select from '@/components/Form/Select.vue';
import TextArea from '@/components/Form/TextArea.vue';
import config from '../../../config';

export default {
  name: 'ReportModal',
  data() {
    const tabs = [
      {
        text: "Portfolio",
        controls: "tabpane",
        action: () => {
          if( !this.tabbedToPortfolio ) {
            this.tabbedToPortfolio = true;
          }
        },
      },
      {
        text: "Team",
        controls: "tabpane",
        action: () => {
          if( this.tabbedToPortfolio ) {
            this.tabbedToPortfolio = false;
          }
        },
      },
    ];

    return {
      open: false,

      identifier: '',
      title: '',
      customer: null,
      owner: null,
      template: config.defaultTemplate,
      currentVersion: null,
      archived: false,
      productSettings: false,
      publishedDate: null,
      collaborators: [],
      team: '0',
      portfolio: this.portfolioId ? this.portfolioId : '',
      sensitive: false,
      reportTemplateChoices: JSON.parse( config.templateChoices ),

      teams: false,

      portfoliosOn: false,
      portfolios: false,

      manualid: false,
      fullTeam: false,

      identifierDisplay: false, //updates to value once gendentifier checked with backend
      idTimeout: false,

      tabbedToPortfolio: false,
      tabs,
      lastKnownTab: 0,

      productAliases: '',
      productDescription: '',
      productUrl: '',
      productStatementUrl: '',
      productRisk: '',
      productRiskDescription: '',
      disproportionateBurden: '',
      disproportionateBurdenDescription: '',
      accessibilityStatement: '',
      accessibilityStatementNotes: '',
    };
  },
  apollo: {
    teams: {
      query: gql`
        {
          teams: Teams {
            _id
            name
            customer {
              _id
              name
            }
          },
        }
      `,
      update: data => {
        const opts = {};

        for( const team of data.teams ) {
          if( team.customer ) {
            opts[team._id] = { label: team.name, group: team.customer.name };
          } else {
            opts[team._id] = team.name;
          }
        }

        return opts;
      },
    },
    portfolios: {
      query: gql`
        {
          portfolios: Portfolios {
            _id
            name
            customer {
              _id
              name
            }
          },
        }
      `,
      update: data => {
        const opts = {};

        for( const portfolio of data.portfolios ) {
          if( portfolio.customer ) {
            opts[portfolio._id] = { label: portfolio.name, group: portfolio.customer.name };
          } else {
            opts[portfolio._id] = portfolio.name;
          }
        }

        return opts;
      },
    },
  },
  created() {
    this.$apollo.query( {
      query: gql`
        query Settings {
          settings: Settings {
            id
            productDetails
            portfolios
          }
        }
      `,
    } ).then( res => {
      this.productSettings = res.data.settings.productDetails;
      this.portfoliosOn = res.data.settings.portfolios;
      if( this.portfoliosOn ) {
        this.tabbedToPortfolio = true;
      }
    } ).catch( error => {
      this.$alerts.coded( 'E001', 'F4701', '', error ); //see notifications spreadsheet
    } );
  },
  watch: {
    team() {
      if( parseInt( this.user.aclevel ) >= 2 && this.team != 0 ) {
        this.$apollo.query( {
          query: gql`
            query Team($id: ObjectID!) {
              team: Team(id: $id) {
                _id,
                identifier,
                customer {
                  _id
                }
              }
            }
          `,
          variables: {
            id: this.team,
          },
        } ).then( res => {
          this.fullTeam = res.data.team;
        } ).catch( error => {
          this.$alerts.coded( 'E062', 'F4702', '', error ); //see notifications spreadsheet
        } );
      }
    },
    gendentifier() {
      if( !this.open ) return;
      this.identifierDisplay = false;
      clearTimeout( this.idTimeout );
      this.idTimeout = setTimeout( () => {
        this.$apollo.query( {
          query: gql`
            query CheckReportIdentifier($identifier: String!) {
              identifier: CheckReportIdentifier(identifier: $identifier)
            }
          `,
          variables: {
            identifier: this.gendentifier,
          },
          fetchPolicy: 'no-cache',
        } ).then( res => {
          this.identifierDisplay = res.data.identifier;
        } ).catch( error => {
          this.$alerts.coded( 'E078', 'F4703', '', error ); //see notifications spreadsheet
        } );
      }, 100 );
    },
    portfolioId() {
      if( this.portfolioId ) {
        this.portfolio = this.portfolioId;
        this.setCustomerFromPortfolio();
      } else {
        this.portfolio = '';
      }
    },
    portfolio() {
      this.setCustomerFromPortfolio();
    },
  },
  methods: {
    manualIdentifier() {
      this.identifier = this.generateIdentifier;
      this.manualid = true;
    },
    setCustomer( customer, display ) {
      if( this.$refs.customerselect ) {
        this.$refs.customerselect.select( customer, display );
      }
      this.customer = customer;
    },
    setCustomerFromPortfolio() {
      if( this.portfolio != '' ) {
        this.$apollo.query( {
          query: gql`
            query Portfolio($id: ObjectID) {
              portfolio: Portfolio(id: $id) {
                _id
                customer {
                  _id
                  name
                }
              },
            }
          `,
          variables:  {
            id: this.portfolio,
          },
        } ).then( res => {
          if( this.$refs.customerselect ) {
            this.$refs.customerselect.selectWithoutFocus( res.data.portfolio.customer._id, res.data.portfolio.customer.name );
          }
          this.customer = res.data.portfolio.customer._id;
        } ).catch( error => {
          this.$alerts.coded( 'E050', 'F4704', '', error ); //see notifications spreadsheet
        } );
      }
    },
    submit() {
      const toValidate = [ 'title' ];
      if( this.manualid ) {
        toValidate.push( 'identifier' );
      }
      if( parseInt( this.user.aclevel < 2 ) && this.portfolio != '' ) {
        toValidate.push( 'customerselect' );
      }
      if( this.tabbedToPortfolio ) {
        toValidate.push( 'portfolioselect' );
      }

      this.$hugrvalidate( toValidate, this ).then( ( { success, errors } ) => {
        if( success ) {
          this.doSubmit();
        }
      } );
    },

    doSubmit() {
      let { customer } = this;
      if( parseInt( this.user.aclevel ) >= 2 && this.team != '0' && this.fullTeam.customer ) {
        customer = this.fullTeam.customer._id;
      }

      let identifier;
      if( this.manualid ) {
        identifier = this.idprefix + this.identifier;
      } else if( this.identifierDisplay ) {
        identifier = this.identifierDisplay;
      } else {
        identifier = this.gendentifier;
      }

      let team = null;
      let portfolio = null;

      if( this.tabbedToPortfolio ) {
        portfolio = this.portfolio;
      }

      team = this.team == '0' ? null : this.team;

      this.$apollo.mutate( {
        mutation: gql`
          mutation addReport($report: ReportInput!) {
            report: addReport(report: $report) {
              _id
            }
          }
        `,
        variables: {
          report: {
            identifier,
            title: this.title,
            template: this.template,
            customer,
            owner: this.user.id,
            currentVersion: this.currentVersion,
            archived: this.archived,
            collaborators: this.collaborators,
            portfolio,
            team,
            productAliases: this.productAliases.split( ',' ).map( t => t.trim() ),
            productDescription: this.productDescription,
            productUrl: this.productUrl,
            productStatementUrl: this.productStatementUrl,
            productRisk: this.productRisk,
            productRiskDescription: this.productRiskDescription,
            disproportionateBurden: this.disproportionateBurden,
            disproportionateBurdenDescription: this.disproportionateBurdenDescription,
            accessibilityStatement: this.accessibilityStatement,
            accessibilityStatementNotes: this.accessibilityStatementNotes,
            sensitive: this.sensitive,
          },
        },
      } ).then( res => {
        this.$emit( 'success', res.data.report._id );
        this.$emit( 'reportidentifier', identifier );
        this.$alerts.success( this.$gettext( '%{projectString} created', { projectString: this.$hugrConfig.projectStringCap } ), this.$gettext( 'The %{projectString} has been created successfully', { projectString: this.$hugrConfig.projectString } ) );
        this.reset();
      } ).catch( error => {
        if ( error.message.indexOf( 'too-many-reports' ) >= 0 ) {
          //report limit hit
          this.$alerts.coded( 'E079', 'F4705', '', error ); //see notifications spreadsheet
        } else {
          this.$alerts.coded( 'E080', 'F4706', '', error ); //see notifications spreadsheet
        }
      } );
    },

    show() {
      this.open = true;
      this.$refs.modal.show();
      if( this.portfolio != '' ) {
        this.setCustomerFromPortfolio();
      }
    },
    reset() {
      this.open = false;
      this.identifier = '';
      this.title = '';
      this.template = 'default';
      this.customer = null;
      this.owner = null;
      this.currentVersion = null;
      this.archived = false;
      this.publishedDate = null;
      this.collaborators = [];
      this.team = '0';
      this.portfolio = '';

      this.$refs.modal.reset();
    },
  },
  computed: {
    ...mapState( [ 'user' ] ),
    ...mapGetters( [ 'hasPermission', 'hasPermissions' ] ),
    idprefix() {
      let identifier = '';
      if( parseInt( this.user.aclevel ) >= 2 ) {
        if( this.team != '0' ) {
          identifier = `${this.fullTeam.identifier}_${identifier}`; //this should be the identifier for the team
        } else {
          identifier = `${this.user.name.replace( / /g, '' )}_${identifier}`;
        }
      }

      return identifier;
    },
    generateIdentifier() {
      // Replace all weird characters before we begin.
      const titleParts = this.title
        .replace( /[?#/&$:;@()[\].`{}|^]/ig, '' )
        .split( ' ' );
      let titleInitials;
      if( titleParts.length > 1 ) {
        titleInitials = titleParts.slice( 0, 3 ).map( p => {

          if ( p.length <= 4 ) {
            return p.toUpperCase();
          }
            //strip vowels and adjacent double letters
            const smlr = Array.from( p ).filter( ( c, k ) => {
              return !/[aeiou]/i.test( c ) || k == 0 || k == p.length - 1;
            } ).join( '' ).replace( /[^\w\s]|(.)(?=\1)/gi, "" ).toUpperCase();
            if( smlr.length <= 4 ) {
              return smlr;
            }

              return p[0].toUpperCase();

        } ).join( '' );
      } else {
        titleInitials = titleParts[0].toUpperCase();
      }

      return titleInitials.replace( /&/g, 'AND' );
    },
    gendentifier() {
      return this.idprefix + this.generateIdentifier;
    },
  },
  props: {
    portfolioId: {
      type: String,
      required: false,
    },
  },
  components: {
    FormInput,
    FormFieldSet,
    FormSelect,
    AriaSearchSelect,
    AriaListBox,
    Select,
    TextArea,
},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/assets/styles/variables/_colours.scss';

 form {
   button.button {
     width: 100%;
     margin-top: 20px;
   }
 }

 .TeamPortfolioTabList {
  padding-bottom: 0 !important;
}

#tabpane {
  // background: $hugr-colours-grey;
  border: 1px solid $hugr-colours-grey;
  border-top: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 10px;

  margin-bottom: 50px;
  margin-top: -40px;

  fieldset {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
</style>
