<template>
  <Modal
    ref="modal"
    :title="$gettext(`Edit Portfolio`) + `: ${portfolio.identifier}`"

    :showActions="true"

    :loading="submitting"
    loadingText="Updating..."

    :submitText="$gettext('Save')"
    @submit="submitPortfolio"

    :ready="portfolio"
    @close="reset()">
    <FormInput ref="name" idRoot="portfolio_" :label="$gettext('Name')" v-model="portfolio.name" :validation="['not-empty']"/>

    <AriaSearchSelect v-if="portfolio.customer" ref="customerselect" :label="$gettext('Customer')" :dropdown="true" :gqlOptions="['CUSTOMERS']" @selected="setCustomer" :validation="['not-empty']" :default="portfolio.customer._id"/>

    <AriaSearchSelect ref="userselect" :label="$gettext('Principal')" :dropdown="true" :gqlOptions="['USERS']" @selected="setPrincipal" :validation="['not-empty']" :default="portfolio.principal?._id" :hintText="$gettext( 'This user will have access to all %{projectString}s associated with this portfolio unless they are marked as sensitive.', { projectString: $hugrConfig.projectString } )"/>

    <ul v-if="portfolio.teams&&portfolio.teams.length" aria-live="polite">
      <li v-for="team in portfolio.teams"  v-bind:key="team.identifier">
        {{ team.name }}
        &nbsp;
        <button @click.prevent="rmTeam(team)">{{$gettext('Remove')}}</button>
      </li>
    </ul>
    <p v-else v-translate>No teams</p>
    <AriaSearchSelect ref="teamselect" :label="$gettext('Add a Team')" :dropdown="true" idRoot="portfolio_" :gqlOptions="['TEAMS']" @selected="addTeam" :hintText="$gettext( 'These teams will have access to all %{projectString}s associated with this portfolio unless they are marked as sensitive.', { projectString: $hugrConfig.projectString } )"/>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';

import FormInput from '@/components/Form/Input';

import AriaSearchSelect from '@/components/Aria/SearchSelect2';

export default {
  name: 'EditPortfolioModal',
  data() {
    return {
      portfolio: false,
      customerChanged: false,
      principalChanged: true,
      submitting: false,
    };
  },
  methods: {
    submitPortfolio() {
      const validations = [ 'name' ];
      if( this.customerChanged ) {
        validations.push( 'customerselect' );
      }
      this.$hugrvalidate( validations, this ).then( ( { success, errors } ) => {
        if( success ) {
          this.submitting = true;
          this.$apollo.mutate( {
            mutation: gql`
              mutation updatePorfolio($id: ObjectID!, $portfolio: PortfolioInput!) {
                portfolio: updatePortfolio(id: $id, portfolio: $portfolio) {
                  _id,
                  name
                }
              }
            `,
            variables: {
              id: this.portfolio._id,
              portfolio: {
                identifier: this.portfolio.identifier,
                name: this.portfolio.name,
                customer: this.portfolio.customer?._id,
                teams: this.portfolio.teams.map( team => team._id ),
                principal: this.portfolio.principal?._id,
              },
            },
          } ).then( res => {
            this.submitting = false;
            this.reset();
            this.$alerts.success( 'Portfolio updated!', `Portfolio ${res.data.portfolio.name} has been updated successfully` );
            this.$emit( 'success', res.data.portfolio._id );
          } ).catch( error => {
            this.submitting = false;
            this.$alerts.coded( 'E061', 'F3401', '', error ); //see notifications spreadsheet
          } );
        }
      } );
    },

    setCustomer( customer, display ) {
      this.customerChanged = true;
      this.$refs.customerselect.select( customer, display );
      this.portfolio.customer = {
        _id: customer,
        name: display,
      };
    },

    setPrincipal( principal, display ) {
      this.principalChanged = true;
      this.$refs.userselect.select( principal, display );
      this.portfolio.principal = {
        _id: principal,
        name: display,
      };
    },

    addTeam( team ) {
      if( this.portfolio.teams.map( team => team._id ).indexOf( team ) < 0 ) {
        this.$apollo.query( {
          query: gql`
            query Team($id: ObjectID!) {
              user: Team(id: $id) {
                _id,
                identifier,
                name
              }
            }
          `,
          variables: {
            id: team,
          },
        } ).then( res => {
          this.portfolio.teams.push( res.data.user );
        } ).catch( error => {
          this.$alerts.coded( 'E062', 'F3402', '', error ); //see notifications spreadsheet
        } );
      }
    },
    rmTeam( team ) {
      this.portfolio.teams.remove( team );
    },

    show( id ) {
      this.$apollo.query( {
        query: gql`
          query Portfolio($id: ObjectID!) {
            portfolio: Portfolio(id: $id) {
              _id,
              identifier,
              name,
              customer {
                _id
                name
              }
              teams {
                _id
                identifier
                name
              }
              principal {
                _id
                name
              }
            }
          }
        `,
        variables: {
          id,
        },
        fetchPolicy: 'no-cache',
      } ).then( res => {
        this.portfolio = { ...res.data.portfolio };
        this.portfolio.teams = Object.assign( [], this.portfolio.teams );
        setTimeout( () => {
          this.$refs.modal.show();
        }, 100 );
      } ).catch( error => {
        this.$alerts.coded( 'E050', 'F3403', '', error ); //see notifications spreadsheet
      } );
    },
    reset() {
      this.portfolio = false;
      this.$refs.modal.reset();
    },
  },
  props: {
  },
  components: {
    FormInput,
    AriaSearchSelect,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
