<template>
  <ReportInfo :trail="[
      {name: $gettext('Home'), path: '/dashboard'},
      {name: $gettext( '%{projectString}s', { projectString: $hugrConfig.projectStringCap } ), path: `/${$hugrConfig.reportRouterReplacement}s`},
      {name: 'THIS_REPORT'},
      {name: $gettext('User journeys'), path: `/${$hugrConfig.reportRouterReplacement}s/${identifier}/${version}/journeys`}
    ]" />

  <Body :withMenu="true" class="UserJourneys" title="User Journeys" ref="body" :titleIcon="['solid', 'route']" :titleHint="['',$gettext('User Journeys are groups of pages and components that make up a specific journey through the website.')]">
    <template v-if="!reportLoading">
      <Button v-if="!report.report.currentVersion.published" class="UserJourneys_AddBtn"  :icon="['solid', 'route']" size="small" type="secondary" @click="userjourneymodal.show()">{{ $gettext('Add a user journey') }}</Button>
    </template>

    <template v-if="!reportLoading">
      <DataTable
          v-if="report.report.userJourneys.length>0"
          class="UserJourneys_Table"
          :noScroll="true"
          :headers="[
            $gettext('Journey Name'),
            $gettext('Importance'),
            // $gettext('Description'),
            // $gettext('Pages'),
            // $gettext('Components'),
            $gettext('Test Status'),
            $gettext('Compliance'),
            $gettext('Instances of issues by severity'),
          ]"
        >
        <tr v-for="journey in report.report.userJourneys" :key="journey._id" class="UserJourneys_Table_Row">
          <td class="UserJourneys_Table_Row_Name"><router-link to="#" @click="userjourneyfragment.show( journey._id )" @keyup.space.prevent="userjourneyfragment.show( journey._id )" :title="journey.title">{{ journey.title }}</router-link></td>
          <td>
            <template v-if="journey.importance">{{ journey.importance }}</template>
            <template v-else>Unknown</template>
          </td>
          <!-- <td class="UserJourneys_Table_Row_Description">
            <span v-if="journey.description" :title="journey.description">{{ journey.description }}</span>
            <span v-else>None</span>
          </td> -->
          <!-- <td>{{ journey.items.filter(i => i.type=='page').length }}</td> -->
          <!-- <td>{{ journey.items.filter(i => i.type=='component').length }}</td> -->
          <td><ProgressBar :id="`journeyprogress-${journey.title}`" label="Journey Progress" :hideLabel="true" :value="journey.progress" type="simple"/></td>
          <td>
            <CompliancePill :progress="journey.progress" :isCompliant="journey.isCompliant" :issues="journey.issues.length" size="small"/>
          </td>
          <td>
            <div class="UserJourneys_Table_Row_Severity">
              <SeverityLine
                :type="hasPermission( 'UIAlts', 'IssueBySeverityBar' )?'line':'pills'"
                :advisory="journey.issues.filter( i => i.issueTemplate.severity == 0 ).length"
                :low="journey.issues.filter( i => i.issueTemplate.severity == 1 ).length"
                :medium="journey.issues.filter( i => i.issueTemplate.severity == 2 ).length"
                :high="journey.issues.filter( i => i.issueTemplate.severity == 3 ).length"
                :critical="journey.issues.filter( i => i.issueTemplate.severity == 4 ).length"
                />
            </div>
          </td>
        </tr>
      </DataTable>
      <template v-else>
        <Empty v-if="!report.report.currentVersion.published" :text="$gettext('No user journeys')"
          :button="{size: 'regular', type: 'secondary', icon: ['solid', 'route'], text: $gettext('Add an user journey')}"
          @buttonClick="$refs.userjourneymodal.show()" />
        <Empty v-else :text="$gettext('No user journeys')" />
      </template>
    </template>
    <Loader v-else />
  </Body>
  <UserJourneyModal v-if="!reportLoading" :reportVersion="report.report.currentVersion._id" ref="userjourneymodal" @success="preRefetchReport" />

  <PageFragment ref="pagefragment" @closed="closeFragment" @goToComponent="goToComponent" @goToUserJourney="goToUserJourney" @goToIssue="goToIssue"/>
  <ComponentFragment ref="componentfragment" @closed="closeFragment" @goToPage="goToPage" @goToUserJourney="goToUserJourney" @goToIssue="goToIssue"/>
  <UserJourneyFragment ref="userjourneyfragment" @closed="closeFragment" @goToComponent="goToComponent" @goToPage="goToPage" @goToIssue="goToIssue"/>
  <IssueFragment ref="issuefragment" @closed="closeFragment" @goToPage="goToPage" @goToComponent="goToComponent" @goToUserJourney="goToUserJourney"/>
</template>

<script setup>
  import UserJourneyModal from '@/modals/UserJourney/Create';

  import PageFragment from '@/fragments/Page';
  import ComponentFragment from '@/fragments/Component';
  import UserJourneyFragment from '@/fragments/UserJourney';
  import IssueFragment from '@/fragments/Issue';

  import ProgressBar from '@/components/UI/ProgressBar';
  import CompliancePill from '@/components/CompliancePill';
  import Pill from '@/components/UI/Pill.vue';
  import SeverityLine from '@/components/UI/SeverityLine';

  import { ref, onMounted, computed } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { useQuery, useMutation } from '@vue/apollo-composable';
  import gql from 'graphql-tag';

  import config from '../../../config';

  const router = useRouter();
  const store = useStore();

  const hasPermission = computed( () => store.getters.hasPermission );

  const userjourneymodal = ref( null );

  const pagefragment = ref( null );
  const componentfragment = ref( null );
  const userjourneyfragment = ref( null );
  const issuefragment = ref( null );

  const identifier = ref( router.currentRoute.value.params.identifier );
  const version = ref( router.currentRoute.value.params.version );
  const urljourney = ref( router.currentRoute.value.params.journey );

  const body = ref( null );

  onMounted( () => {
    if( urljourney.value ) {
      userjourneyfragment.value.show( urljourney.value );
    }

    const { mutate: sendNavigation } = useMutation(
      gql`
        mutation sendNavigation ($page: String) {
          sendNavigation(page: $page)
        }
      `, {
        variables: {
          page: 'Assessment Journeys',
        },
    } );
    
    sendNavigation();
  } );

  const reportFragment = gql`
    fragment reportFragment on Report {
      _id
      identifier
      title

      userJourneys {
        _id
        title
        description
        importance
        items {
          type
        }
        progress
        isCompliant

        issues {
          _id
          issueTemplate {
            severity
          }
        }
      }

      currentVersion {
        _id
        published
      }
    }`;

  let reportQuery = gql`
      query Report($identifier: String!) {
        report: Report(identifier: $identifier) {
          ... reportFragment
        }
      }
      ${reportFragment}
    `;

  if( version.value ) {
    reportQuery = gql`
      query Report($identifier: String!, $version: String!) {
        report: ReportWithVersion(identifier: $identifier, version: $version) {
          ... reportFragment
        }
      }
      ${reportFragment}
    `;
  }

  const { result: report, refetch: refetchReport, loading: reportLoading, onResult: onReport } = useQuery(
    reportQuery,
    {
      identifier,
      version,
    },
    {
      fetchPolicy: 'no-cache',
    },
  );

  const preRefetchReport = () => {
    refetchReport();
    body.value.reloadMenu();
  };

  onReport( ( { data } ) => {
    if( data?.report?.currentVersion?._id ) {
      // eslint-disable-next-line no-underscore-dangle
      store._actions.setReport[0]( data.report.currentVersion._id );
    }
  } );

  const closeFragment = () => {
    history.pushState(
      {},
      null,
      `/${config.reportRouterReplacement}s/${identifier.value}/${version.value}/journeys`,
    );
    refetchReport();
  };

  const goToComponent = component => {
    componentfragment.value.show( component );
  };

  const goToPage = page => {
    pagefragment.value.show( page );
  };

  const goToUserJourney = journey => {
    userjourneyfragment.value.show( journey );
  };

  const goToIssue = issue => {
    issuefragment.value.show( issue );
  };

</script>

<style lang="scss" scoped>
  .UserJourneys {
    position: relative;

    &_Blurb {
      font-size: 0.9em;
    }

    &_AddBtn {
      position: absolute;
      right: 50px;
      top: 36px;
    }
    
    &_Table {
      margin-top: 32px;
      &_Row {
        &_Name {
          max-width: 10vw;
          a {
            display: inline-block;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        &_Description {
          max-width: 20vw;
          span {
            display: inline-block;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        &_Severity {
          max-width: 240px;
          display: flex;
          justify-content: flex-start;
          gap: 12px;
        }
      }
    }
  }
</style>
