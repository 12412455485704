<template>
  <ul class="AutoComplete" v-if="visible">
    <li v-for="( user, index ) in users" :key="user._id" :class="[ 'AutoComplete_Item', selected==index?'_active':'' ]" tabindex="-1" @mouseover="setSelected(user._id)"
      @click="complete(user._id)">
      @{{ user.name }}</li>
    <li v-if="team" :class="[ 'AutoComplete_Item', selected==users.length?'_active':'']" tabindex="-1" @mouseover="setSelected('new')"
      @click="complete('new')">{{ $gettext('Add a contact') }}</li>
  </ul>
  <ContactModal ref="contactmodal" :team="{_id: team}" @success="completeOnNew" />
</template>

<script>
import gql from 'graphql-tag';
import { mapState } from 'vuex';
import ContactModal from '@/modals/User/Contact';

export default {
  name: 'UserAutocomplete',
  emits: [ 'complete', 'focusItem' ],
  data() {
    return {
      selected: null,
    };
  },
  apollo: {
    teamInfo: {
      query: gql`
        query Team($id: ObjectID) {
          teamInfo: Team(id: $id) {
            _id,
            managers {
              _id,
              email,
            }
            users {
              _id,
              email
            }
          }
        }
      `,
      variables() {
        return {
          id: this.team,
        };
      },
      skip() {
        return !this.team;
      },
    },
  },
  computed: {
    ...mapState( [ 'user' ] ),
    ids() {
      if( this.team ) {
        return [ ...this.users.map( u => u._id ), 'new' ];
      }

      return this.users.map( u => u._id );

    },
  },
  methods: {
    clear() {
      this.selected = null;
      this.$forceUpdate();
    },
    setSelected( id ) {
      this.selected = this.ids.indexOf( id );
    },
    completeOnCurrent() {
      if( !this.selected || this.selected == null ) this.selected = 0;
      this.complete( this.users[ this.selected ]._id );
    },
    complete( id ) {
      if( id == 'new' || id == this.users.length ) {
        this.$refs.contactmodal.show( this.term );
      } else {
        this.setSelected( id );
        this.$emit( 'complete', id );
        if( this.team && [ ...this.teamInfo.managers, ...this.teamInfo.users ].map( u => u._id ).indexOf( id ) < 0 ) {
          if( this.teamInfo.managers.map( u => u._id ).indexOf( this.user.id ) >= 0 ) {
            this.$confirm.make( `This person isn't associated with the team`, 'Would you like to add them?' ).then( result => {
              if( result ) {
                this.addToTeam( id );
              }
            } );
          } else {
            this.$alerts.warn( this.$gettext( 'This user may not have access to this %{project}', { project: this.$hugrConfig.projectString } ), `Ask a team manager to add them to the team if they require access.` );
          }
        } else if( !this.team ) {
          this.$alerts.warn( this.$gettext( 'This user may not have access to this %{project}', { project: this.$hugrConfig.projectString } ) );
        }
      }
    },
    addToTeam( id ) {
      this.$apollo.mutate( {
        mutation: gql`
          mutation ($id: ObjectID!, $team: ObjectID!, $sender: String!) {
            inviteTeamUserById(id: $id, team: $team, sender: $sender)
          }
        `,
        variables: {
          id,
          team: this.team,
          sender: this.user.name,
        },
      } ).then( res => {
        if ( res.data.inviteTeamUserById ) {
          this.$alerts.success( 'Team member added!', `User has been added to team, they will receive an email.` );
          this.$emit( 'success' );
        } else {
          this.$alerts.error( "Couldn't add user", "Have you hit your users limit?" );
        }
      } ).catch( () => {
        this.$alerts.error( `Oops, there's a problem :/`, `This is definitely a bug.` );
      } );
    },
    completeOnNew( id ) {
      setTimeout( () => {
        this.$emit( 'complete', id );
      }, 50 );
    },
    cycleActive( e ) {
      const maxIndex = this.team ? this.users.length : this.users.length - 1;
      if( e.key === 'ArrowDown' ) {
        if( this.selected == null ) {
          this.selected = 0;
        } else if( this.selected < maxIndex ) {
          this.selected += 1;
        }
      } else if( e.key === 'ArrowUp' ) {
        if( this.selected == null ) {
          this.selected = 0;
        } else if( this.selected > 0 ) {
          this.selected -= 1;
        }
      }

      if( this.selected < this.users.length ) this.$emit( 'focusItem', this.users[ this.selected ] );
    },
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
    team: {
      default: false,
    },
    term: {
      type: String,
    },
    visible: Boolean,
  },
  components: {
    ContactModal,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/assets/styles/variables/_colours.scss';

  .AutoComplete {
    list-style: none;
    float: left;
    position: relative;
    z-index: 999;
    background: $hugr-colours-grey;
    padding: 0;
    margin: 0;
    margin-top: -35px;
    margin-left: -1px;
    width: calc( 100% + 2px );
    box-sizing: border-box;
    border: 1px solid lighten( $hugr-colours-tertiary, 20% );
    border-top: none;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    &_Item {
      padding: 3px 6px;
      &._active {
        background: lighten( $hugr-colours-tertiary, 20% );
      }
    }
  }
  // .autocomplete {
  //   list-style: none;
  //   padding: 5px;
  //   margin: 0;
  //   background-color: #FFF;
  //   border: 1px solid $hugr-colours-primary;
  //   border-radius: 5px;
  //   width: 100%;

  //   li {
  //     border-radius: 5px;
  //     cursor: pointer;
  //   }
  // }

  // .active {
  //   font-weight: bold;
  //   background-color: $hugr-colours-grey;
  //   color: $hugr-colours-primary;
  // }

  ._darkMode {

    // .autocomplete {
    //   background-color: $hugr-colours-primary;
    //   border: 1px solid #b2b3b1;
    // }

    // .active {
    //   background-color: $hugr-colours-white;
    // }
  }
</style>
