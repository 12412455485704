<template>
  <div>
    <Body title="Teams & Users" :noPadBottom="true">
      <TabList
        v-if="tabs.length"
        :tabs="tabs"
        :key="`teamtabs-${tabs.length}`"
        :lastIndex="tabs.map( t => t.controls ).indexOf( getMethod )" />
      <!-- :lastIndex="tabs.map(t => t.text).indexOf(team.name)" /> -->
    </Body>
    <Body v-show="showTeams" :title="$gettext('Teams')" :fill="true" headingLevel="3" class="Teams" id="teams" :noPadTop="true">
      <!-- Need to move sort logic to server for pagination -->
      <!-- <SortBy class="Teams_Sort" :options="sortOpts" /> -->
      <div class="Reports_Actions __grid">
        <Button
          class="__grid_column_2"
          type="secondary"
          :icon="['solid', 'users']"
          v-if="hasPermission('Teams','Create')"
          @click="$refs.teammodal.show()"
          >{{$gettext('Add a team')}}</Button
        >
        <Search
          :class="[hasPermission('Teams','Create')?'__grid_column_10':'__grid_column_12']"
          :label="$gettext('Search teams')"
          v-model="teamSearch"
          :inUse="teamSearch!=''"
        />
      </div>
      <!-- <CardSet :title="$gettext('All Teams')" hlevel="3" :button="{icon:['solid', 'users', 'after'], text: $gettext('Add a team')}" class="Teams_Teams" @buttonClick="$refs.teammodal.show()"> -->
      <CardSet :title="teamSearch==''?$gettext('All Teams'):$gettext('Search results')" hlevel="4" class="Teams_Teams">
        <DataTable  class="Teams_Teams_Table" tablekey="adminteams" :customisable="true" @changed="$forceUpdate()"
                    v-show="teams.pages>0"
                    :headers="[
            $gettext('Identifier'),
            $gettext('Name'),
            { name: $gettext('Managers'), default: false },
            $gettext('Users'),
            $gettext( '%{projectString}s', { projectString: $hugrConfig.projectStringCap } ),
            $gettext('Customer'),
            { name: $gettext('Internal contact'), default: false },
            $gettext('Active'),
            { name: $gettext('MFA Enabled'), default: false },
            { name: '', freeze: true }
          ]">
          <tr v-for="team in teams.teams" v-bind:key="'team-' + team.identifier">
            <td v-if="$tableColumnVisible('adminteams', 'identifier')">{{ team.identifier }}</td>
            <td v-if="$tableColumnVisible('adminteams', 'name')">{{ team.name }}</td>
            <td v-if="$tableColumnVisible('adminteams', 'managers')">
              <ul>
                <li v-for="user in team.managers" :key="`manager-${user.email}`">
                  {{ user.name }} ({{user.email}})
                </li>
              </ul>
            </td>

            <td v-if="$tableColumnVisible('adminteams', 'users')">
              <span v-if="team.users.length < team.userslimit">{{ team.users.length }} / {{ team.userslimit }}</span>
              <span class="red" v-else>{{ team.users.length }} / {{ team.userslimit }}</span>
            </td>

            <td v-if="$tableColumnVisible('adminteams', `${$hugrConfig.projectStringCap}s`)">
              <router-link :to="`/${$hugrConfig.reportRouterReplacement}s/find/all/alphabetical/team/${team._id}`">
                <span v-if="team.reportsamount <= team.reportslimit">{{ team.reportsamount }} / {{ team.reportslimit }}</span>
                <span class="red" v-else>{{ team.reportsamount }} / {{ team.reportslimit }}</span>
              </router-link>
            </td>

            <td v-if="$tableColumnVisible('adminteams', $gettext('Customer')=='Organisation'?'organisation':'customer' )">
              <span v-if="team.customer">{{ team.customer.name }}</span>
              <span v-else v-translate>No Customer</span> <!-- This should never happen, other than in tests? -->
            </td>
            <td v-if="$tableColumnVisible('adminteams', 'internalContact')">
              <span v-if="team.contact">{{ team.contact.name }}</span>
              <span v-else v-translate>No Contact</span>
            </td>
            <td v-if="$tableColumnVisible('adminteams', 'active')">
              {{ activeString(team) }}
            </td>
            <td v-if="$tableColumnVisible('adminteams', 'mFAEnabled')">
              <span v-if="team.mfaEnabled">Yes</span>
              <span v-else>No</span>
            </td>
            <td>
              <Button v-if="hasPermission('Teams','Update')" size="micro" @click="$refs.editteammodal.show(team._id)">{{$gettext('Edit Team')}}</Button>
            </td>
          </tr>
        </DataTable>
        <DataTablePaginator v-if="teams.pages>1" :pages="teams.pages" @select="(page) => { teamsPage = page } " />
        <DataTablePageSize v-if="teams.pages>0" :tableName="'Teams'" @selected="(p) => { teamsPageSize = p; teamsPage = 0; }" />
        <Empty v-if="teams.pages==0" :text="$gettext('Nothing here!')" :showButton="hasPermission('Teams','Create')" :button="{icon:['solid', 'users'], text: $gettext('Add a team')}" @buttonClick="$refs.teammodal.show()" />
      </CardSet>
    </Body>
    <Body v-show="showUsers" :title="$gettext('Users')" :fill="true" headingLevel="3" class="Users" id="users" :noPadTop="true">

      <div class="Users_Actions __grid">
        <Button
          class="__grid_column_2"
          type="secondary"
          :icon="['solid', 'user-plus']"
          @click="$refs.usermodal.show()"
          v-if="hasPermission('Users','Create')"
          >{{$gettext('Add a user')}}</Button>
        <Button
          class="__grid_column_2"
          type="secondary"
          :icon="['solid', 'user-plus']"
          @click="$refs.usermodal.show(true)"
          v-if="hasPermission('Users','CanInvite')"
          >{{$gettext('Invite a user')}}</Button>
        <Search
          :class="[hasPermission('Users','Create')&&hasPermission('Users','CanInvite')?'__grid_column_8':hasPermission('Users','Create')||hasPermission('Users','CanInvite')?'__grid_column_10':'__grid_column_12']"
          :label="$gettext('Search Users')"
          v-model="userSearch"
          :inUse="userSearch!=''"
        />
      </div>
      <!-- <CardSet :title="$gettext('All Users')" hlevel="3" :button="{icon:['solid', 'user-plus', 'after'], text: $gettext('Add an user')}" :button2="{icon:['solid', 'user-plus', 'after'], text: this.$gettext('Invite a user')}" class="Users" @buttonClick="$refs.usermodal.show();" @button2Click="$refs.usermodal.show(true);"> -->
      <CardSet :title="userSearch==''?$gettext('All Users'):$gettext('Search results')" hlevel="4" class="Users_Users">
        <template v-if="users.pages>0">
          <DataTable  class="Users_Users_Table" ref="adminusers" tablekey="adminusers" :customisable="true" @changed="() => { userFilters = $refs.adminusers.getFilters(); $apollo.queries.users.refetch(); $forceUpdate() }"
                      :filter="{
                        status: ['Active', 'Disabled'],
                        permissionGroup: [ ...permissionGroups.map( g => g.name ), 'None' ],
                      }"
                      :headers="[
              $gettext('Name'),
              $gettext('Email'),
              $gettext('Status'),
              $gettext('Permission Group'),
              $gettext('Last Login'),
              { name: $gettext('AC Level'), default: false },
              { name: $gettext('Contactable'), default: false },
              { name: $gettext('MFA Enabled'), default: false },
              { name: $gettext('Security Keys'), default: false },
              { name: $gettext( '%{projectString}s', { projectString: $hugrConfig.projectStringCap } ), default: false },
              { name: '', freeze: true }
            ]">
            <tr v-for="user in users.users" v-bind:key="'user-' + user.email" :class="[user.disabled?'_dimmed':'']">
              <td v-if="$tableColumnVisible('adminusers', 'name')">
                <router-link :to="`/userprofile/${user._id}`">{{ user.name }}</router-link>
              </td>
              <td v-if="$tableColumnVisible('adminusers', 'email')">{{ user.email }}</td>
              <td v-if="$tableColumnVisible('adminusers', 'status')">{{ user.disabled?$gettext('Disabled'):$gettext('Active') }}</td>
              <td v-if="$tableColumnVisible('adminusers', 'permissionGroup')">{{ user.permissionGroup?$gettext(user.permissionGroup.name):$gettext('None') }}</td>
              <td v-if="$tableColumnVisible('adminusers', 'lastLogin')">
                <span v-if="user.lastLogin" :title="moment( user.lastLogin ).format( `DD/MM/yyyy @ HH:mm` )">{{ moment( user.lastLogin ).fromNow() }}</span>
                <span v-else>Unknown</span>
              </td>
              <td v-if="$tableColumnVisible('adminusers', 'aCLevel')">
                <span v-if="user.aclevel==0" >Admin</span>
                <span v-if="user.aclevel==1" >Tester</span>
                <span v-if="user.aclevel==2" >Customer</span>
              </td>
              <td v-if="$tableColumnVisible('adminusers', 'contactable')">{{isInterestedInNews(user)}}</td>
              <td v-if="$tableColumnVisible('adminusers', 'mFAEnabled')">{{ user.mfaEnabled?$gettext('Yes'):$gettext('No') }}</td>
              <td v-if="$tableColumnVisible('adminusers', 'securityKeys')">{{ user.securityKeys?user.securityKeys:$gettext('None') }}</td>
              <td v-if="$tableColumnVisible('adminusers', 'reports')">{{ user.reports.length }}</td>
              <td>
                <Button v-if="!user.disabled && (hasPermission('Users','Update') )" type="serious" size="micro" @click="disableUser(user._id)">{{$gettext('Disable user')}}</Button>
                <Button v-if="user.disabled && (hasPermission('Users','Update') )" type="secondary" size="micro" @click="enableUser(user._id)">{{$gettext('Enable user')}}</Button>
                &nbsp;
                <Button v-if="hasPermission('Users','Update') " size="micro" @click="$refs.editusermodal.show(user._id)">{{$gettext('Edit user')}}</Button>
              </td>
            </tr>

          </DataTable>
          <DataTablePaginator :pages="users.pages" @select="(page) => { usersPage = page } " />
          <DataTablePageSize :tableName="'Users'" @selected="(p) => { usersPageSize = p; usersPage = 0; }" />
        </template>
        <Empty v-else :text="$gettext('Nothing here!')" :showButton="hasPermission('Users','Create')" :button="{icon:['solid', 'user-plus'], text: $gettext('Add an user')}" @buttonClick="$refs.usermodal.show()" />
      </CardSet>
    </Body>
    <Body v-show="showContacts" :title="$gettext('Contacts')" :fill="true" headingLevel="3" class="Contacts" id="contacts" :noPadTop="true">

      <div class="Users_Actions __grid">
        <!-- <Button
          class="__grid_column_2"
          type="secondary"
          :icon="['solid', 'user-plus']"
          @click="$refs.contactmodal.show()"
          >{{$gettext('Add a contact')}}</Button
        > -->
        <!-- TODO: add team option to contact modal or remove requirement for team, and reenable above -->
        <Search
          class="__grid_column_12"
          :label="$gettext('Search Contacts')"
          v-model="contactSearch"
          :inUse="contactSearch!=''"
        />
      </div>
      <CardSet :title="contactSearch==''?$gettext('All Contacts'):$gettext('Search results')" hlevel="4" class="Users_Users">
        <template v-if="contacts.pages>0">
          <DataTable  class="Users_Users_Table"
                      :headers="[
              $gettext('Name'),
              $gettext('Email'),
              $gettext('Status'),
              $gettext('Permission Group'),
              $gettext('AC Level'),
              ''
            ]">
            <tr v-for="user in contacts.users" v-bind:key="'user-' + user.email" :class="[user.disabled?'_dimmed':'']">
              <td><router-link :to="`/userprofile/${user._id}`">{{ user.name }}</router-link></td>
              <td>{{ user.email }}</td>
              <td>{{ user.disabled?$gettext('Disabled'):$gettext('Active Contact') }}</td>
              <td>{{ user.permissionGroup?$gettext(user.permissionGroup.name):$gettext('None') }}</td>
              <td>
                <span v-if="user.aclevel==0">Admin</span>
                <span v-else-if="user.aclevel==1">Tester</span>
                <span v-else-if="user.aclevel==2">Customer</span>
                <span v-else v-translate>None</span>
              </td>
              <td>
                <!-- <Button v-if="!user.disabled && hasPermission('Users','Delete')" type="serious" size="micro" @click="disableUser(user._id)">{{$gettext('Disable contact')}}</Button>
                <Button v-if="user.disabled && hasPermission('Users','Update')" type="secondary" size="micro" @click="enableUser(user._id)">{{$gettext('Enable contact')}}</Button>
                &nbsp; -->
                <Button v-if="hasPermission('Contacts','Update')" size="micro" @click="$refs.editcontactmodal.show(user._id)">{{$gettext('Edit contact')}}</Button>
              </td>
            </tr>

          </DataTable>
          <DataTablePaginator :pages="users.pages" @select="(page) => { usersPage = page } " />
          <DataTablePageSize :tableName="'Users'" @selected="(p) => { usersPageSize = p; usersPage = 0; }" />
        </template>
        <Empty v-else :text="$gettext('Nothing here!')" :button="{icon:['solid', 'user-plus'], text: $gettext('Add an user')}" @buttonClick="$refs.usermodal.show()" />
      </CardSet>
    </Body>
    <Body v-show="showTeamContact" :title="$gettext('Team Contact')" :fill="true" headingLevel="3" class="TeamContact" id="team-contact" :noPadTop="true">
      <p>This will be the internal contact for all teams unless changed. The person (or email) that will receive all tickets from teams.</p>
      <AriaSearchSelect ref="contactselect" :label="$gettext('Internal Contact')" :dropdown="true" idRoot="team_" :gqlOptions="['DIGTEAM']" @selected="setContact" />
      <Button type="secondary" htype="submit" @click="setSettingValue( 'defaultTeamContact', defaultTeamContact )">{{$gettext('Save')}}</Button>
    </Body>

    <TeamModal ref="teammodal" @success="$apollo.queries.teams.refetch()" />
    <EditTeamModal ref="editteammodal" @success="$apollo.queries.teams.refetch()" />

    <UserModal ref="usermodal" @success="$apollo.queries.users.refetch()" />
    <EditUserModal ref="editusermodal" @success="$apollo.queries.users.refetch()" />

    <ContactModal ref="contactmodal" @success="$apollo.queries.contacts.refetch()" />
    <EditContactModal ref="editcontactmodal" @success="$apollo.queries.contacts.refetch()" />

    <!-- <UserForm />
      <CustomerUserForm />
      <AdminPasswordResetForm /> -->
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapGetters } from 'vuex';

import TeamModal from '@/modals/Team/Create';
import EditTeamModal from '@/modals/Team/Edit';

import UserModal from '@/modals/User/Create';
import EditUserModal from '@/modals/User/Edit';

import ContactModal from '@/modals/User/Contact';
import EditContactModal from '@/modals/User/EditContact';

import UIDataTablePaginator from '@/components/UI/DataTablePaginator';
import UIDataTablePageSize from '@/components/UI/DataTablePageSize';

import AriaSearchSelect from '@/components/Aria/SearchSelect2';

// import UISortBy from '@/components/UI/SortBy.vue';

import moment from 'moment';

export default {
  name: 'TeamsView',
  data() {
    return {
      getMethod: this.$route.name,

      teams: false,
      teamsPage: 0,
      teamsPageSize: 10,

      users: false,
      usersPage: 0,
      usersPageSize: 25,
      userFilters: null,

      contacts: false,
      contactsPage: 0,
      contactsPageSize: 25,

      teamSearch: '',
      userSearch: '',
      contactSearch: '',

      defaultTeamContact: '',

      sortOpts: [
        {
          title: "name",
          click: this.sortByName,
        },
        {
          title: "identifier",
          click: this.sortByIdentifier,
        },
        {
          title: this.$gettext( '%{projectString}s amount', { projectString: this.$hugrConfig.projectString } ),
          click: this.sortByReports,
        },
        {
          title: "users amount",
          click: this.sortByUsers,
        },
      ],

      showTeams: true,
      showUsers: false,
      showContacts: false,

      tabs: [
        {
          text: "Teams",
          controls: "teams",
          action: () => {
            this.showTeams = true;
            this.showUsers = false;
            this.showContacts = false;
            this.showTeamContact = false;
            this.getMethod = 'teams';
            this.$router.push( '/teams' );
          },
        },
        {
          text: "Users",
          controls: "users",
          action: () => {
            this.showTeams = false;
            this.showUsers = true;
            this.showContacts = false;
            this.showTeamContact = false;
            this.getMethod = 'users';
            this.$router.push( '/users' );
          },
        },
        {
          text: "Contacts",
          controls: "contacts",
          action: () => {
            this.showTeams = false;
            this.showUsers = false;
            this.showContacts = true;
            this.showTeamContact = false;
            this.getMethod = 'contacts';
            this.$router.push( '/contacts' );
          },
        },
        {
          text: "Team Contact",
          controls: "team-contact",
          action: () => {
            this.showTeams = false;
            this.showUsers = false;
            this.showContacts = false;
            this.showTeamContact = true;
            this.getMethod = 'team-contact';
            this.$router.push( '/team-contact' );
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters( [ 'hasPermission' ] ),
    ...mapState( { stateUser: state => state.user } ),
  },
  mounted() {
    this.$apollo.queries.teams.refetch();
    this.$apollo.mutate( {
      mutation: gql`
        mutation ($page: String) {
          sendNavigation(page: $page)
        }
      `,
      variables: {
        page: 'Teams',
      },
    } );
  },
  watch: {
    teamSearch() {
      this.teamsPage = 0;
    },
    usersSearch() {
      this.usersPage = 0;
    },
  },
  apollo: {
    teams: {
      query() {
        const teamPageFragment = gql`
          fragment teamPageFragment on TeamPage {
            teams {
              _id,
              identifier,
              name,
              reportsamount,
              reportslimit,
              userslimit,
              mfaEnabled,
              users {
                _id
              }
              managers {
                _id
                name,
                email
              }
              customer {
                _id
                name
              }
              contact {
                _id
                name
              },
              subscriptionActive,
              active,
              activeUntil,
              activeIndefinitely
            }
            hasMore
            pages
          }
        `;

        if( this.teamSearch != '' ) {
          return gql`
            query SearchTeamsPage($term: String!, $page: Int!, $size: Int!) {
              teams: SearchTeamsPage(term: $term, page: $page, size: $size) {
                ... teamPageFragment
              }
            }
            ${teamPageFragment}
          `;
        }

          return gql`
            query TeamsPage($page: Int!, $size: Int!) {
              teams: TeamsPage(page: $page, size: $size) {
                ... teamPageFragment
              }
            }
            ${teamPageFragment}
          `;

      },
      variables() {
        return {
          page: this.teamsPage,
          size: this.teamsPageSize,
          term: this.teamSearch == '' ? null : this.teamSearch,
        };
      },
      error( error ) {
        this.$alerts.coded( 'E092', 'F9001', '', error );
      },
    },
    users: {
      query() {
        const userPageFragment = gql`
          fragment userPageFragment on UserPage {
            users {
              _id,
              name,
              email,
              aclevel,
              interestedInNews,
              madeNewsChoice,
              mfaEnabled,
              securityKeys,
              disabled,
              permissionGroup {
                _id,
                name
              }
              lastLogin
              reports {
                _id
              }
            }
            hasMore
            pages
          }`;

        if( this.userSearch != '' ) {
          return gql`
            query SearchUsersPage($term: String!, $page: Int!, $size: Int!) {
              users: SearchUsersPage(term: $term, page: $page, size: $size) {
                ... userPageFragment
              }
            }
            ${userPageFragment}
          `;
        }

          return gql`
            query UsersPage($page: Int!, $size: Int!, $filters: Object) {
              users: UsersPage(page: $page, size: $size, filters: $filters) {
                ... userPageFragment
              }
            }
            ${userPageFragment}
          `;

      },
      variables() {
        return {
          page: this.usersPage,
          size: this.usersPageSize,
          term: this.userSearch == '' ? null : this.userSearch,
          filters: this.userFilters,
        };
      },
      fetchPolicy: 'no-cache',
      error( error ) {
        this.$alerts.coded( 'E093', 'F9002', '', error );
      },
    },
    contacts: {
      query() {
        const userPageFragment = gql`
          fragment userPageFragment on UserPage {
            users {
              _id,
              name,
              email,
              aclevel,
              interestedInNews,
              madeNewsChoice,
              mfaEnabled,
              securityKeys,
              disabled,
              permissionGroup {
                _id,
                name
              }
            }
            hasMore
            pages
          }`;

        if( this.contactSearch != '' ) {
          return gql`
            query SearchContactsPage($term: String!, $page: Int!, $size: Int!) {
              contacts: SearchContactsPage(term: $term, page: $page, size: $size) {
                ... userPageFragment
              }
            }
            ${userPageFragment}
          `;
        }

          return gql`
            query ContactsPage($page: Int!, $size: Int!) {
              contacts: ContactsPage(page: $page, size: $size) {
                ... userPageFragment
              }
            }
            ${userPageFragment}
          `;

      },
      variables() {
        return {
          page: this.contactsPage,
          size: this.contactsPageSize,
          term: this.contactSearch == '' ? null : this.contactSearch,
        };
      },
      error( error ) {
        this.$alerts.coded( 'E094', 'F9003', '', error );
      },
    },
    settings: {
      query: gql`
        {
          settings: Settings {
            id
            defaultTeamContact {
              _id,
              name,
              email
            }
          }
        }
      `,
      result( res ) {
        const contact = res.data.settings.defaultTeamContact;
        this.setContact( contact._id, `${contact.name} (${contact.email})` );
        this.defaultTeamContact = contact._id;
      },
      fetchPolicy: 'no-cache',
    },
    permissionGroups: {
      query: gql`
        query permissionGroups {
          permissionGroups: PermissionGroups {
            _id,
            name,
          }
        }
      `,
    },
  },
  methods: {
    moment,
    setSettingValue( setting, value ) {
      this.$apollo.mutate( {
        mutation: gql`
          mutation setSettingValue($setting: String!, $value: String!) {
            settings: setSettingValue(setting: $setting, value: $value) {
              defaultTeamContact {
                _id
              }
            }
          }
        `,
        variables: {
          setting,
          value,
        },
      } ).then( () => {
        this.$alerts.success( 'Team contact changed' );
      } ).catch( error => {
        this.$alerts.generic( error );
      } );
    },
    setContact( contact, display ) {
      this.$refs.contactselect.select( contact, display );
      this.defaultTeamContact = contact;
    },
    disableUser( id ) {
      this.$apollo.mutate( {
        mutation: gql`
          mutation disableUser($id: ObjectID!) {
            disableUser(id: $id) {
              _id
            }
          }
        `,
        variables: {
          id,
        },
      } ).then( () => {
        this.$apollo.queries.users.refetch();
      } ).catch( error => {
        this.$alerts.generic( error );
      } );
    },
    enableUser( id ) {
      this.$apollo.mutate( {
        mutation: gql`
          mutation enableUser($id: ObjectID!) {
            enableUser(id: $id) {
              _id
            }
          }
        `,
        variables: {
          id,
        },
      } ).then( () => {
        this.$apollo.queries.users.refetch();
      } ).catch( error => {
        this.$alerts.generic( error );
      } );
    },
    sortByName() {
      function compare( a, b ) {
        if ( a.name.toLowerCase() < b.name.toLowerCase() ) return -1;
        if ( a.name.toLowerCase() > b.name.toLowerCase() ) return 1;

        return 0;
      }

      return this.teams.sort( compare );
    },
    sortByReports() {
      function compare( a, b ) {
        if ( a.reportsamount > b.reportsamount ) return -1;
        if ( a.reportsamount < b.reportsamount ) return 1;

        return 0;
      }

      return this.teams.sort( compare );
    },
    sortByUsers() {
      function compare( a, b ) {
        if ( a.users.length > b.users.length ) return -1;
        if ( a.users.length < b.users.length ) return 1;

        return 0;
      }

      return this.teams.sort( compare );
    },
    sortByIdentifier() {
      function compare( a, b ) {
        if ( a.identifier.toLowerCase() < b.identifier.toLowerCase() ) return -1;
        if ( a.identifier.toLowerCase() > b.identifier.toLowerCase() ) return 1;

        return 0;
      }

      return this.teams.sort( compare );
    },
    isInterestedInNews( user ) {
      if( user.madeNewsChoice ) {
        if( user.interestedInNews ) {
          return this.$gettext( 'Yes' );
        }

          return this.$gettext( 'No' );

      }

        return this.$gettext( 'Not Asked' );

    },
    activeString( team ) {
      if( team.active ) {
        let str = 'Yes';
        if( team.subscriptionActive ) {
          str += ' (subscription)';
        } else if( team.activeIndefinitely ) {
          str += ' (until forever)';
        } else {
          str += ` (until ${moment( team.activeUntil ).format( "DD/MM/yyyy" )})`;
        }

        return this.$gettext( str );
      }

        return this.$gettext( 'No' );

    },
  },
  components: {
    TeamModal,
    EditTeamModal,

    UserModal,
    EditUserModal,

    ContactModal,
    EditContactModal,

    DataTablePaginator: UIDataTablePaginator,
    DataTablePageSize: UIDataTablePageSize,

    AriaSearchSelect,
    // SortBy: UISortBy,
  },
};
</script>

<style lang="scss" scoped>

  @import '@/assets/styles/variables/_colours.scss';

  .red {
    color: $hugr-colours-red;
  }

  .Teams {
    &_Sort {
      position: absolute;
      top: 25px;
      right: 50px;
    }
    &_Teams {
      &_Table {
        width: 100%;
      }
    }
    &_Users {
      &_Table {
        width: 100%;
      }
    }
  }

  ._darkMode {
    .red {
      color: lighten($hugr-colours-red, 30%);
    }
  }
</style>
