<template>
  <div v-if="report" class="Report">
    <ReportInfo :reportVersion="report.currentVersion._id"
      :trail="[{name: 'Home', path: '/dashboard'}, {name: $gettext( '%{projectString}s', { projectString: $hugrConfig.projectStringCap } ), path: `/${$hugrConfig.reportRouterReplacement}s`}]" />

    <template v-if="teamLoaded">
      <div class="Report_Team" v-if="!showPeopleOnReports && people.length>1" @mouseenter="hoverPeople()" @mouseleave="blurPeople()">
        <h3 class="Report_Team_Title">Team</h3>
        <Avatar v-for="(person, index) in people" :class="['Report_Team_Avatar', `_person-${index}`, index<4&&!showAllPeople?`_startCollapsed`:'', index>4&&!showAllPeople?`_startHidden`:'']" :key="person[1]" :uid="person[1]" :details="true" size="small" type="round" :report="report._id" @focus="hoverPeople()" @blur="blurPeople(e)"/>
        <Pill :class="['Report_Team_Pill', showAllPeople?`_hide`:``]" v-if="people.length>5" :text="`${people.length-5} more`" />
      </div>
    </template>

    <Body ref="body" class="Report_Body" :withMenu="true">
      <div class="Report_Body_Inner __grid">
        <div class="__grid_column_8">
          <div v-if="unstarted" class="Report_Body_Inner_Notice">
            <Notice size="micro" :title="$gettext( 'Welcome to your new %{projectString}', { projectString: $hugrConfig.projectString } )" :icon="['solid', 'rocket']" @dismissed="unstarted = false">
              To begin, add the pages, components and user journeys you would like to assess below.
            </Notice>
          </div>

          <div class="Report_Body_Inner_Constituents">
            <div class="Report_Body_Inner_Constituents_Item">
              <div class="Report_Body_Inner_Constituents_Item_Inner" @click="router.push(`/${$hugrConfig.reportRouterReplacement}s/${report.identifier}/${report.currentVersion.version}/pages`)">
                <div class="Report_Body_Inner_Constituents_Item_Inner_Left">
                  <h3 class="Report_Body_Inner_Constituents_Item_Inner_Left_Title"><router-link :to="`/${$hugrConfig.reportRouterReplacement}s/${report.identifier}/${report.currentVersion.version}/pages`">Pages</router-link></h3>
                  <p class="Report_Body_Inner_Constituents_Item_Inner_Left_Number">{{  report.pageCount }}</p>
                </div>
                <div class="Report_Body_Inner_Constituents_Item_Inner_Right">
                  <Icon type="regular" icon="file" class="Report_Body_Inner_Constituents_Item_Inner_Right_Icon"/>
                </div>
              </div>
              <Button v-if="!report.currentVersion.published" type="link" size="micro" :icon="['solid', 'plus']" class="Report_Body_Inner_Constituents_Item_Button" @click="pagemodal.show()">Add a page</Button>
            </div>
            <div class="Report_Body_Inner_Constituents_Item">
              <div class="Report_Body_Inner_Constituents_Item_Inner" @click="router.push(`/${$hugrConfig.reportRouterReplacement}s/${report.identifier}/${report.currentVersion.version}/components`)">
                <div class="Report_Body_Inner_Constituents_Item_Inner_Left">
                  <h3 class="Report_Body_Inner_Constituents_Item_Inner_Left_Title"><router-link :to="`/${$hugrConfig.reportRouterReplacement}s/${report.identifier}/${report.currentVersion.version}/components`">Components</router-link></h3>
                  <p class="Report_Body_Inner_Constituents_Item_Inner_Left_Number">{{  report.components.length }}</p>
                </div>
                <div class="Report_Body_Inner_Constituents_Item_Inner_Right">
                  <Icon type="solid" icon="puzzle-piece" class="Report_Body_Inner_Constituents_Item_Inner_Right_Icon"/>
                </div>
              </div>
              <Button v-if="!report.currentVersion.published" type="link" size="micro" :icon="['solid', 'plus']" class="Report_Body_Inner_Constituents_Item_Button" @click="componentmodal.show()">Add a component</Button>
            </div>
            <div class="Report_Body_Inner_Constituents_Item" >
              <div class="Report_Body_Inner_Constituents_Item_Inner" @click="router.push(`/${$hugrConfig.reportRouterReplacement}s/${report.identifier}/${report.currentVersion.version}/journeys`)">
                <div class="Report_Body_Inner_Constituents_Item_Inner_Left">
                  <h3 class="Report_Body_Inner_Constituents_Item_Inner_Left_Title"><router-link :to="`/${$hugrConfig.reportRouterReplacement}s/${report.identifier}/${report.currentVersion.version}/journeys`">User Journeys</router-link></h3>
                  <p class="Report_Body_Inner_Constituents_Item_Inner_Left_Number">{{  report.userJourneyCount }}</p>
                </div>
                <div class="Report_Body_Inner_Constituents_Item_Inner_Right">
                  <Icon type="solid" icon="route" class="Report_Body_Inner_Constituents_Item_Inner_Right_Icon"/>
                </div>
              </div>
              <Button v-if="!report.currentVersion.published" type="link" size="micro" :icon="['solid', 'plus']" class="Report_Body_Inner_Constituents_Item_Button" @click="userjourneymodal.show()">Add a journey</Button>
            </div>
          </div>
          <!-- <TabList :tabs="tabs"/> -->
          <template v-if="issuesLoaded">
            <template v-if="report.issues.length">
              <div class="Report_Body_Inner_Breakdown">
                <div class="Report_Body_Inner_Breakdown_Top">
                  <h3>{{ breakdownTitle }} by severity</h3>
                  <div class="Report_Body_Inner_Breakdown_Top_PieSet">Show: <button @click="setPieInstances()" :aria-current="breakdownTitle!='Issues'">Issue Instances</button> <button @click="setPieIssues()" :aria-current="breakdownTitle=='Issues'">Issues</button></div>
                </div>
                <Pie v-if="hasPermission( 'UIAlts', 'ReportPie' )" class="Report_Body_Inner_Breakdown_Pie" :data="pieData" :centerKey="pieKey" @goToIssues="goToIssues"/>
                <div v-else class="Report_Body_Inner_Breakdown_Bottom">
                  <div class="Report_Body_Inner_Breakdown_Bottom_Left">
                    <div v-for="(data, i) in pieData" :key="'issue-'+i+breakdownTitle" class="Report_Body_Inner_Breakdown_Bottom_Left_Inner">
                      <Pill :text="data.value" :type="data.key.toLowerCase()" :circle="true" @click.prevent="goToIssues( data.key )"/>
                      <span class="Report_Body_Inner_Breakdown_Bottom_Left_Inner_Text">
                        <router-link :to="`/${$hugrConfig.reportRouterReplacement}s/${report.identifier}/${report.currentVersion.version}/issues`" @click.prevent="goToIssues( data.key )">{{ data.key }}</router-link>
                      </span>
                    </div>
                  </div>
                  <div class="Report_Body_Inner_Breakdown_Bottom_Right">
                    <Pill v-if="breakdownTitle=='Issues'" :text="report.issues.filter( i => i.primary ).length" :circle="true" :size="'huge'" :subtext="'issues total'"/>
                    <Pill v-else :text="report.issues.length" :circle="true" :size="'huge'" :subtext="'instances total'"/>
                  </div>
                </div>
              </div>
              <div class="Report_Body_Inner_ExecSummary">
                <h3>Executive Summary</h3>
                <ButtonSet class="Report_Body_Inner_ExecSummary_Opts" label="Executive summary options" :dropdown="true" size="micro" type="icon" :icon="['solid', 'ellipsis-vertical']">
                  <Button type="transparent" size="micro" :icon="['regular', 'edit']" @click="editexecsummary.show(report.currentVersion._id)">{{ $gettext('Edit') }}</Button>
                  <Button type="transparent" size="micro" :icon="['regular', 'edit']"
                    @click="editrenderdetails.show(report.currentVersion._id, report.currentVersion.createdOverride, report.currentVersion.publishedOverride, report.currentVersion.keyIssues, issuesMarkedAsKeyIssues);">{{
                    $gettext('Dates and key issues') }}</Button>
                </ButtonSet>
                <vue-markdown v-highlight :html="false" :key="report.currentVersion.execSummary">{{ report.currentVersion.execSummary }}</vue-markdown>
              </div>
            </template>
            <Notice v-else size="micro" title="Add some issues" :icon="['solid', 'rocket']">
              go to a page or component to start adding issues.
            </Notice>
          </template>
          <Loader v-else />
          <!-- <div class="Report_Body_Inner_Details" v-if="productSettings || ( report.productDescription && report.productDescription != '' )">
            <h3>{{ $gettext('Report details') }}</h3>
            <p>{{ report.productDescription }}</p>
            <Button size="micro" type="border"
              v-if="!allDetails && ( report.productUrl!='' || report.productStatementUrl!='' || productSettings )"
              @click="openAllDetails()" ref="allDetailsBtn">{{
              $gettext('View more')
              }}</Button>&nbsp;
          </div> -->
        </div>
        <div class="__grid_column_4">
          <!-- <div class="Report_Body_Inner_Progress">
            <h3 v-translate>Assessment progress:</h3>
            <ProgressBar id="reportprogress" :label="$gettext('Assessment Progress:') " :value="report.currentVersion.progress" :hideLabel="true" :hideNumber="true"/>
            <p>TODO checks completed</p>
          </div> -->

          <template v-if="teamLoaded">
            <div class="Report_Body_Inner_PeopleFull" v-if="showPeopleOnReports && people.length>1">
              <h3 v-translate>People</h3>
              <ul>
                <li v-for="person in people" :key="`person${person[0]}`" class="Report_Body_Inner_PeopleFull_Person">
                  <router-link :to="`/userprofile/${person[1]}`" class="Report_Body_All_Details_Person_Name">{{ person[0] }}</router-link>
                  <span class="Report_Body_Inner_PeopleFull_Person_Details">
                    <span v-if="person[2]&&person[2]!=''" class="Report_Body_Inner_PeopleFull_Person_Details_Role">{{ person[2] }}</span>
                    <template v-if="person[2]&&person[2]!=''&&lastEngagement[person[1]]">&nbsp;-&nbsp;</template>
                    <span v-if="lastEngagement[person[1]]" class="Report_Body_Inner_PeopleFull_Person_Details_LastEngagement">{{ moment(lastEngagement[person[1]]).fromNow() }}</span>
                  </span>
                </li>
              </ul>
            </div>
          </template>

          <div class="Report_Body_ActivityLog">
            <ActivityLog ref="activitylog" :report="report._id" :team="report.team?report.team._id:(report.portfolio&&report.portfolio.teams[0]?report.portfolio.teams[0]._id:null)" @newItem="doRefetchReport"/>
          </div>
        </div>
      </div>
    </Body>
    <PageModal :report="report.currentVersion._id" :components="report.components" ref="pagemodal" @added="() => { doRefetchReport(); activitylog.reload() }" />
    <ComponentModal :report="report.currentVersion._id" ref="componentmodal" @added="() => { doRefetchReport(); activitylog.reload() }" />
    <UserJourneyModal :reportVersion="report.currentVersion._id" ref="userjourneymodal" @added="() => { doRefetchReport(); activitylog.reload() }" />
    <EditRenderDetailsModal @success="() => { doRefetchReport(); activitylog.reload() }" ref="editrenderdetails" :data="[]" />
    <EditExecSummaryModal @success="() => { doRefetchReport(); activitylog.reload() }" ref="editexecsummary" />
  </div>
  <Loader v-else-if="reportLoading" />
  <Empty v-else :text="$gettext('There doesn\'t seem to be anything here for you, did you take a wrong turn?')" />
</template>

<script setup>

  import { ref, computed, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import { useQuery, useLazyQuery, useMutation } from '@vue/apollo-composable';
  import { useStore } from 'vuex';
  import gql from 'graphql-tag';
  import moment from 'moment';

  import PageModal from '@/modals/Page/Create';
  import ComponentModal from '@/modals/Component/Create';
  import UserJourneyModal from '@/modals/UserJourney/Create';
  import EditRenderDetailsModal from '@/modals/RenderDetails/Edit';
  import EditExecSummaryModal from '@/modals/Report/EditExecSummary';

  import ActivityLog from '@/components/ActivityLog.vue';

  import ProgressBar from '@/components/UI/ProgressBar.vue';
  import Pie from '@/components/UI/Pie.vue';
  import Avatar from '@/components/Helpers/Avatar.vue';
  import Pill from '@/components/UI/Pill.vue';
  import config from '../../../config';

  const store = useStore();

  const hasPermission = computed( () => store.getters.hasPermission );

  const body = ref( null );

  const pagemodal = ref( null );
  const componentmodal = ref( null );
  const userjourneymodal = ref( null );
  const editrenderdetails = ref( null );
  const editexecsummary = ref( null );

  const activitylog = ref( null );

  onMounted( () => {
    const { mutate: sendNavigation } = useMutation(
      gql`
        mutation sendNavigation ($page: String) {
          sendNavigation(page: $page)
        }
      `, {
        variables: {
          page: 'Assessment',
        },
    } );
    
    sendNavigation();
  } );

  const { onResult: onSettings } = useQuery(
    gql`
      query Settings {
        settings: Settings {
          id
          productDetails
          # displayAllProductDetails
          # disableSpreadsheetPrompt
          # showReportWithoutPage
          showPeopleOnReports
        }
      }
    `,
  );

  const showPeopleOnReports = ref( false );
  const productSettings = ref( false );

  onSettings( ( { data } ) => {
    showPeopleOnReports.value = data?.settings.showPeopleOnReports;
    productSettings.value = data?.settings.productDetails;
  } );

  const router = useRouter();

  const identifier = ref( router.currentRoute.value.params.identifier );
  const version = ref( router.currentRoute.value.params.version );

  const reportFragment = gql`
    fragment reportFragment on Report {
      _id
      identifier
      title

      productDescription

      pageCount
      userJourneyCount

      components {
        _id,
        identifier,
        description,
      }

      currentVersion {
        _id
        progress
        published
        execSummary
        execSummaryTmp
        createdOverride,
        publishedOverride,
        keyIssues,
        version
      }
    }`;

  let reportQuery = gql`
      query Report($identifier: String!) {
        report: Report(identifier: $identifier) {
          ... reportFragment
        }
      }
      ${reportFragment}
    `;

  if( version.value ) {
    reportQuery = gql`
      query Report($identifier: String!, $version: String!) {
        report: ReportWithVersion(identifier: $identifier, version: $version) {
          ... reportFragment
        }
      }
      ${reportFragment}
    `;
  }

  const { refetch: refetchReport, loading: reportLoading, onResult: onReport } = useQuery(
    reportQuery,
    {
      identifier,
      version,
    },
    {
      fetchPolicy: 'no-cache',
    },
  );

  const report = ref( null );

  const breakdownTitle = ref( 'Issue instances' );
  const pieData = ref( [] );
  const pieKey = ref( null );
  const setPieIssues = () => {
    breakdownTitle.value = 'Issues';
    pieKey.value = 'issues identified';
    pieData.value = [
      {
        key: 'Advisory',
        value: report.value.issues.filter( i => i.primary ).filter( i => i.template.severity == 0 ).length,
        colour: '#879441',
      },
      {
        key: 'Low',
        value: report.value.issues.filter( i => i.primary ).filter( i => i.template.severity == 1 ).length,
        colour: '#009620',
      },
      {
        key: 'Medium',
        value: report.value.issues.filter( i => i.primary ).filter( i => i.template.severity == 2 ).length,
        colour: '#FBB55E',
      },
      {
        key: 'High',
        value: report.value.issues.filter( i => i.primary ).filter( i => i.template.severity == 3 ).length,
        colour: '#FAC0BF',
      },
      {
        key: 'Critical',
        value: report.value.issues.filter( i => i.primary ).filter( i => i.template.severity == 4 ).length,
        colour: '#B22C2A',
      },
    ];
  };

  const setPieInstances = () => {
    breakdownTitle.value = 'Issue instances';
    pieKey.value = 'instances total';
    pieData.value = [
      {
        key: 'Advisory',
        value: report.value.issues.filter( i => i.template.severity == 0 ).length,
        colour: '#879441',
      },
      {
        key: 'Low',
        value: report.value.issues.filter( i => i.template.severity == 1 ).length,
        colour: '#009620',
      },
      {
        key: 'Medium',
        value: report.value.issues.filter( i => i.template.severity == 2 ).length,
        colour: '#FBB55E',
      },
      {
        key: 'High',
        value: report.value.issues.filter( i => i.template.severity == 3 ).length,
        colour: '#FAC0BF',
      },
      {
        key: 'Critical',
        value: report.value.issues.filter( i => i.template.severity == 4 ).length,
        colour: '#B22C2A',
      },
    ];
  };

  const unstarted = ref( true );

  const lastEngagement = ref( {} );

  const teamLoaded = ref( false );

  const doRefetchReport = () => {
    teamLoaded.value = false;
    refetchReport();
    body.value.reloadMenu();
  };

  const people = computed( () => {
    const ret = [];

    if( !teamLoaded.value ) return ret;

    ret.push( [ report.value.owner.name, report.value.owner._id , report.value.owner.permissionGroup?.name ] );

    for( const person of report.value.collaborators ) {
      ret.push( [ person.name, person._id, person.permissionGroup?.name ] );
    }

    if( report.value.team ) {
      for( const person of report.value.team.managers ) {
        if( ret.map( i => i[1] ).indexOf( person._id ) == -1 ) {
          ret.push( [ person.name, person._id, person.permissionGroup?.name ] );
        }
      }

      for( const person of report.value.team.users ) {
        if( ret.map( i => i[1] ).indexOf( person._id ) == -1 ) {
          ret.push( [ person.name, person._id, person.permissionGroup?.name ] );
        }
      }

      for( const person of report.value.team.contacts ) {
        if( ret.map( i => i[1] ).indexOf( person._id ) == -1 ) {
          ret.push( [ `${person.name} (Contact)`, person._id, person.permissionGroup?.name ] );
        }
      }

      return ret;
    }

    if( !report.value.portfolio ) return ret;

    //else portfolio
    for( const team of report.value.portfolio.teams ) {
      for( const person of team.managers ) {
        if( ret.map( i => i[1] ).indexOf( person._id ) == -1 ) {
          ret.push( [ person.name, person._id, person.permissionGroup?.name ] );
        }
      }

      for( const person of team.users ) {
        if( ret.map( i => i[1] ).indexOf( person._id ) == -1 ) {
          ret.push( [ person.name, person._id, person.permissionGroup?.name ] );
        }
      }

      for( const person of team.contacts ) {
        if( ret.map( i => i[1] ).indexOf( person._id ) == -1 ) {
          ret.push( [ `${person.name} (Contact)`, person._id, person.permissionGroup?.name ] );
        }
      }
    }

    return ret;
  } );

  const usersArray = ref( [] );
  const reportId = ref( null );

  const { load: loadEngagements, refetch: refetchEngagements, onResult: onEngagements } = useLazyQuery( gql`
   query LastEngagements($users: [ObjectID]!, $report: ObjectID!) {
      engagements: LastEngagements(users: $users, report: $report) {
        user {
          _id
        }
        timestamp
      }
    }
  `,
  {
    users: usersArray,
    report: reportId,
  },
  {
    fetchPolicy: 'no-cache',
  } );

  const currentVersion = ref( null );

  const { load: loadIssues, refetch: updateIssues, onResult: onIssuesResult } = useLazyQuery(
    gql`
      query IssuesFromReportVersion($id: ObjectID!) {
        reportIssues: IssuesFromReportVersion(id: $id) {
          _id
          identifier,
          status,
          flag,
          reason,
          primary,
          priority,
          isAKeyIssueInstance,

          reporter {
            _id
            name
          }
          assignee {
            _id
          }
          template {
            _id
            severity
            section
            identifier
            title
          }
          others {
            _id
          }
        }
      }
    `,
    {
      id: currentVersion,
    },
    {
      fetchPolicy: 'no-cache',
    },
  );

  const issuesLoaded = ref( false );
  onIssuesResult( ( { data } ) => {
    issuesLoaded.value = true;
    report.value.issues = data.reportIssues;

    setPieInstances();
  } );

  const issuesMarkedAsKeyIssues = computed( () => {
    const ret = [];
    for ( const issue of report.value.issues ) {
      if( issue.isAKeyIssueInstance ) {
        ret.push( issue );
      }
    }

    return ret;
  } );

  const { load: loadTeamData, refetch: updateTeamData, onResult: onTeamResult } = useLazyQuery(
    gql`
      query ReportFromVersion($id: ObjectID!) {
        reportTeam: ReportFromVersion(id: $id) {
          _id
          owner {
            _id
            name,
            email,
            permissionGroup {
              _id
              name
            }
          }
          collaborators {
            _id
            name,
            email,
            permissionGroup {
              _id
              name
            }
          }

          team {
            _id,
            managers {
              _id,
              name,
              permissionGroup {
                _id
                name
              }
            }
            users {
              _id,
              name,
              permissionGroup {
                _id
                name
              }
            }
            contacts {
              _id,
              name,
              permissionGroup {
                _id
                name
              }
            }
          }
          portfolio {
            _id
            identifier
            name
            teams {
              _id
              managers {
                _id,
                name,
                permissionGroup {
                  _id
                  name
                }
              }
              users {
                _id,
                name,
                permissionGroup {
                  _id
                  name
                }
              }
              contacts {
                _id,
                name,
                permissionGroup {
                  _id
                  name
                }
              }
            }
          }
        }
      }
    `,
    {
      id: currentVersion,
    },
    {
      fetchPolicy: 'no-cache',
    },
  );

  onTeamResult( ( { data } ) => {
    teamLoaded.value = true;
    report.value.owner = data.reportTeam.owner;
    report.value.collaborators = data.reportTeam.collaborators;
    report.value.team = data.reportTeam.team;
    report.value.portfolio = data.reportTeam.portfolio;

    usersArray.value = people.value.map( p => p[1] );
  } );

  onReport( ( { data } ) => {
    report.value = data.report;
    unstarted.value = report.value.pageCount == 0 && report.value.components.length == 0;

    reportId.value = report.value._id;

    currentVersion.value = data.report.currentVersion._id;

    // eslint-disable-next-line no-unused-expressions
    loadEngagements() || refetchEngagements();

    issuesLoaded.value = false;
    // eslint-disable-next-line no-unused-expressions
    loadIssues() || updateIssues();

    // eslint-disable-next-line no-unused-expressions
    loadTeamData() || updateTeamData();
  } );

  onEngagements( ( { data } ) => {
    for( const engagement of data.engagements ) {
      lastEngagement.value[ engagement.user._id ] = engagement.timestamp;
    }
  } );

  const showAllPeople = ref( false );
  let peopleTimeout;

  const hoverPeople = () => {
    clearTimeout( peopleTimeout );
    showAllPeople.value = true;
  };

  const blurPeople = () => {
    if( document.activeElement.classList.contains( 'Avatar_Actual' ) ) return;

    peopleTimeout = setTimeout( () => {
      showAllPeople.value = false;
    }, 500 );
  };

  const goToIssues = key => {
    localStorage.setItem( 'prePopIssueSeverity', key.toLowerCase() );
    router.push( `/${config.reportRouterReplacement}s/${report.value.identifier}/${report.value.currentVersion.version}/issues` );
  };

</script>

<style lang="scss">
  @import '@/assets/styles/variables/_colours.scss';

  .Report {
    position: relative;
    &_Team {
      position: absolute;
      right: 64px;
      top: 64px;
      &_Title {
        font-weight: bold;
        display: inline-block;
        vertical-align: middle;
        margin: 0;
        margin-right: 8px;
      }
      &_Avatar {
        margin-right: 4px;
        display: inline-block;
        vertical-align: middle;
        width: 32px;

        transition: width .2s;

        &._startCollapsed {
          width: 20px;
        }

        &._startHidden {
          position: absolute !important;
          width: 1px;
          overflow: hidden;
        }
      }
      &_Pill {
        display: inline-block;
        vertical-align: middle;
        width: 68px;
        white-space: nowrap;
        overflow: hidden;

        transition: all .2s;

        &._hide {
          position: absolute !important;
          height: 1px; width: 1px;
          overflow: hidden;
          clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
          clip: rect(1px, 1px, 1px, 1px);
        }
      }
    }
    &_Body {
      &_Inner {
        &_Notice {
          margin-bottom: 24px;
        }
        &_Constituents {
          border-bottom: 1px solid lighten( $hugr-colours-grey, 10% );
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          column-gap: 20px;

          &_Item {
            margin: 0 0 24px 0;
            flex: 30%;
            cursor: pointer;

            &:last-child {
              border-right: 0;
            }

            &_Button {

            }

            &_Inner {
              border: 1px solid lighten($dig-blue, 65%);
              background: lighten($dig-blue, 72%);
              display: flex;
              flex-direction: row;
              padding: 8px;
              height: 80px;
              max-height: 80px;
              justify-content: space-between;
              border-radius: 8px;
              margin-bottom: 8px;

              &_Left {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                &_Title {
                  margin: 0;
                  font-weight: 600;
                  a {
                    color: unset;
                    text-decoration: unset;
                  }
                }

                &_Number {
                  margin: 0;
                  font-weight: 600;
                  font-size: 2em;
                }
              }

              &_Right {
                display: flex;
                justify-content: flex-end;

                &_Icon {
                  background: lighten( $dig-blue, 65% );
                  padding: 12px;
                  border-radius: 55px;
                  width: 20px;
                  height: 20px;

                  display: inline-block;
                  vertical-align: middle;
                }
              }
            }
          }
        }
        &_Breakdown {
          background: $hugr-colours-input-surface;
          padding: 32px 0;
          margin-top: 16px;
          &_Top {
            padding: 0 32px;
            h3 {
              margin: 0;
              font-weight: bold;
            }
            &_PieSet {
              // float: right;
              // margin-top: -22px;
              margin-top: 8px;
              button {
                background: transparent;
                border: none;
                // color: $hugr-colours-tertiary;
                cursor: pointer;
                padding: 4px;
                border-radius: 4px;
                font-weight: bold;
                &:hover, &:focus, &._active, &[aria-current]:not([aria-current="false"]) {
                  background: $hugr-colours-grey;
                }
              }
            }
          }
          &_Bottom {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 100%;
            margin: 32px;
            margin-bottom: 0px;
            column-gap: 64px;

            &_Left {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              row-gap: 8px;
              &_Inner {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                cursor: pointer;

                &_Text {
                  margin-left: 8px;
                  font-size: 0.9em;
                  font-weight: 600;
                  a {
                    color: unset;
                    text-decoration: unset;
                  }
                }
              }
            }
            &_Right {

            }
          }
          &_Pie {
            
          }
        }
        &_ExecSummary {
          position: relative;
          h3 {
            font-weight: bold;
          }
          &_Opts {
            position: absolute !important;
            right: 0;
            top: 0;
            button {
              width: 100%;
            }
          }
        }

        &_Progress {
          background: lighten( $hugr-colours-grey, 10% );
          padding: 8px;
          h3 {
            font-family: "Quicksand", serif;
            margin: 8px 0;
            font-weight: bold;
            font-size: 12pt;
            color: $hugr-colours-primary;
          }
          .ProgressBar {
            width: 100%;
            progress {
              width: 100%;
            }
          }
          p {
            margin: 4px 0;
            color: $hugr-colours-primary;
          }
        }

        &_PeopleFull {
          border-left: 1px solid lighten( $hugr-colours-grey, 10% );
          padding: 0 16px;

          h3 {
            margin: 0;
            font-size: 1.1em;
            font-weight: bold;
          }
          ul {
            padding: 0;
            list-style: none;
            li {
              margin-bottom: 8px;
              a {
                display: inline-block;
                max-width: 250px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: 0.9em;
                text-decoration: none;
              }
            }
          }

          &_Person {
            &_Details {
              font-size: 0.8em;
              display: block;
              margin-top: -6px;
              &_Role {
              }
              &_LastEngagement {

              }
            }
          }
        }
      }
    }
  }

  ._darkMode .Report {
    &_Body {
      &_Inner {
        &_Constituents {
          &_Item {
            &_Inner {
              background: $dig-blue;

              &_Left {
                &_Title {
                  a {
                    color: #FFF;
                  }
                }
              }

              &_Right {
                &_Icon {
                  background: darken( $dig-blue, 10% );
                }
              }
            }
          }
        }
        &_Breakdown {
          background: darken( $hugr-colours-primary, 10% );
          &_Top {
            &_PieSet {
              button {
                color: $hugr-colours-secondary;
                &:hover, &:focus, &._active, &[aria-current]:not([aria-current="false"]) {
                  background: $hugr-colours-primary;
                }
              }
            }
          }
        }

        &_Progress {
          background: lighten( $hugr-colours-primary, 20% );
          h3 {
            color: #FFF;
          }
        }
      }
    }
  }
</style>
