<template>
  <Modal
    ref="modal"
    :stepsInHeader="true"
    :currentStep="currentStep"
    :totalSteps="totalSteps"
    :title="$gettext(getTitle(currentStep))"
    :subtitle="$gettext(getSubtitle(currentStep))"

    :showActions="true"

    emptyText="No data to export"
    :canContinue="stepReady()"
    continueText="Next"
    @continue="nextStep"
    @back="previousStep"
    :completed="completed"

    :loading="exporting"
    loadingText="Exporting..."

    :submitText="$gettext('Export')"
    @submit="nextStep"

    @close="reset()">
    <div class="CSVSummary">
      <div class="CSVSummary_Step1" v-if="currentStep==1">
        <Select idRoot="publishedrange" :label="$gettext('Published within the last')" v-model="publishedRange" :options="[$gettext('Last week'), $gettext('Last month'), $gettext('Last 3 months'), $gettext('Last 6 months'), $gettext('Last year'), $gettext('Any')]" @change="checkSelected()" />
      
        <ul>
          <template v-for="report in reports" :key="report._id">
            <li v-if="doShow( report )">
              <input type="checkbox" :id="`version-${report.currentVersion._id}`" v-model="selectedVersions" :value="report.currentVersion._id"/>
              <label :for="`version-${report.currentVersion._id}`">
                <span>{{ report.identifier }}: {{ report.title }} (Version {{ report.currentVersion.version }})</span>
                &nbsp;
                <span v-if="report.currentVersion.published">Published {{ moment( report.currentVersion.publishedDate ).format( 'MMM Do YYYY' ) }}</span>
                <span v-else>Not Published</span>
              </label>
            </li>
          </template>
        </ul>
      </div>
      <div class="CSVSummary_Step2" v-if="currentStep==2">
        <div v-for="option in enabledOptions" :key="option.option">
          <input type="checkbox" :id="`option-${option.option}`" v-model="option.enabled" />
          <label :for="`option-${option.option}`">{{ option.option }}</label>
        </div>
      </div>
      <div class="CSVSummary_Step3" v-if="currentStep==3">
        <p><b>{{ selectedVersions.length }}</b> {{ $gettext( '%{projectString}s selected', { projectString: $hugrConfig.projectString } ) }}.</p>
        <p><b>{{ enabledOptions.filter( o => o.enabled ).length }}</b> attributes selected.</p>
        <p v-if="completed">Export Complete</p>
      </div>
    </div>
  </Modal>
</template>

<script setup>
  import { ref, inject } from 'vue';
  import { useLazyQuery } from '@vue/apollo-composable';
  import gql from "graphql-tag";
  import moment from 'moment';

  import Select from '@/components/Form/Select';

  import config from '../../../config';
  
  const modal = ref( null );
  
  const currentStep = ref( 1 );
  const totalSteps = ref( 3 );
  const publishedRange = ref( 1 );
  const team = ref( null );
  const enabled = ref( false );
  const exporting = ref( false );
  const completed = ref( false );
  
  const reports = ref( null );

  const selectedVersions = ref( [] );
  const enabledOptions = ref( [
    { option: "Identifier", enabled: true },
    { option: "Title", enabled: true },
    { option: "Version", enabled: true },
    { option: "published Date", enabled: true },
    { option: "WCAG Section", enabled: true },
    { option: "WCAG Ref", enabled: true },
    { option: "WCAG Title", enabled: true },
    { option: "Ref", enabled: true },
    { option: "Header", enabled: true },
    { option: "Pages Failed", enabled: true },
    { option: "Pages Passed", enabled: true },
    { option: "Pages Not Applicable", enabled: true },
    { option: "Pages Advisory", enabled: true },
    { option: "Pages Not Tested", enabled: true },
    { option: "Total Issues", enabled: true },
  ] );

  const getTitle = step => {
    return [
      `Select which ${config.projectString}s to export`,
      'Select attributes to include',
      'Confirm CSV Export',
    ][ step - 1 ];
  };

  const getSubtitle = step => {
    return [
      `Choose which ${config.projectString}s you would like to include in your summary`,
      'Choose what information you would like to include',
      '',
    ][ step - 1 ];
  };

  const doShow = report => {
    if( publishedRange.value == 5 ) return true;

    let compareDate = moment();

    switch( publishedRange.value ) {
      case '0': {
        compareDate = moment().subtract( '7', 'd' );

        break;
      }
      case '1': {
        compareDate = moment().subtract( '1', 'M' );

        break;
      }
      case '2': {
        compareDate = moment().subtract( '3', 'M' );

        break;
      }
      case '3': {
        compareDate = moment().subtract( '6', 'M' );

        break;
      }
      case '4': {
        compareDate = moment().subtract( '12', 'M' );

        break;
      }
      default: {
        compareDate = moment().subtract( '128', 'M' );

        break;
      }
    }

    return moment( report.currentVersion.publishedDate ).isAfter( compareDate );
  };

  const checkSelected = () => {
    const replaceVersions = [];
    for( const version of selectedVersions.value ) {
      const report = reports.value.filter( r => r.currentVersion._id == version )[ 0 ];
      if( doShow( report ) ) replaceVersions.push( version );
    }
    selectedVersions.value = replaceVersions;
  };

  const stepReady = () => {
    switch( currentStep.value ) {
      case 1: {
        return selectedVersions.value.length > 0;
      }
      case 2: {
        return enabledOptions.value.filter( o => o.enabled ).length > 0;
      }
      case 3: {
        return true;
      }
      default: {
        return false;
      }
    }
  };

  const { load: loadReports, refetch: updateReports, onResult: onReports } = useLazyQuery( gql`
    query ReportsFromTeam($team: ObjectID!) {
      reports: ReportsFromTeam(team: $team) {
        _id
        identifier
        title
        currentVersion {
          _id
          version
          published
          publishedDate
        }
      }
    }
  `,
  {
    team,
  },
  {
    fetchPolicy: 'no-cache',
    enabled,
  } );

  onReports( ( { data } ) => {
    reports.value = data.reports;

    modal.value.show();
  } );

  const { load: loadReportStats, refetch: updateReportStats, onResult: onReportStats } = useLazyQuery( gql`
    query ReportsFromVersionArray($versions: [ObjectID]!) {
      reports: ReportsFromVersionArray(versions: $versions) {
        _id
        identifier
        title
        currentVersion {
          _id
          version
          published
          publishedDate
          stats {
            wcagSection
            wcagRef
            wcagTitle
            ref
            header
            pagesFailed
            pagesPassed
            pagesNotApplicable
            pagesAdvisory
            pagesNotTested
            totalIssues
          }
        }
      }
    }
  `,
  {
    versions: selectedVersions,
  },
  {
    fetchPolicy: 'no-cache',
    enabled: exporting,
  } );

  const exportCsv = () => {
    if( !reports.value || reports.value.length == 0 ) return;

    const rows = [];

    const headRow = [];
    for( const option of enabledOptions.value.filter( o => o.enabled ) ) {
      headRow.push( option.option );
    }
    rows.push( headRow );

    for( const version of selectedVersions.value ) {
      const report = reports.value.filter( r => r.currentVersion._id == version )[ 0 ];

      for( const statRow of report.currentVersion.stats ) {
        const row = [];
        for( const option of enabledOptions.value.filter( o => o.enabled ) ) {
          switch( option.option ) {
            case "Identifier": {
              row.push( report.identifier );

              break;
            }
            case "Title": {
              row.push( report.title );

              break;
            }
            case "Version": {
              row.push( report.currentVersion.version );

              break;
            }
            case "published Date": {
              row.push( report.currentVersion.published ? moment( report.currentVersion.publishedDate ).format( 'DD/MM/YYYY' ) : 'Not Published' );

              break;
            }
            case "WCAG Section": {
              row.push( statRow.wcagSection );

              break;
            }
            case "WCAG Ref": {
              row.push( statRow.wcagRef );

              break;
            }
            case "WCAG Title": {
              row.push( statRow.wcagTitle );

              break;
            }
            case "Ref": {
              row.push( statRow.ref );

              break;
            }
            case "Header": {
              row.push( statRow.header );

              break;
            }
            case "Pages Failed": {
              row.push( statRow.pagesFailed );

              break;
            }
            case "Pages Passed": {
              row.push( statRow.pagesPassed );

              break;
            }
            case "Pages Not Applicable": {
              row.push( statRow.pagesNotApplicable );

              break;
            }
            case "Pages Advisory": {
              row.push( statRow.pagesAdvisory );

              break;
            }
            case "Pages Not Tested": {
              row.push( statRow.pagesNotTested );

              break;
            }
            case "Total Issues": {
              row.push( statRow.totalIssues );

              break;
            }
          }
        }
        rows.push( row );
      }
    }

    const csvContent = `data:text/csv;charset=utf-8,${rows.map( r => r.join( "," ) ).join( "\n" )}`;
    const encodedUri = encodeURI( csvContent );
    window.open( encodedUri );

    completed.value = true;
  };

  onReportStats( ( { data } ) => {
    reports.value = data.reports;

    exportCsv();
  } );

  const nextStep = () => {
    if( currentStep.value == 3 ) {
      exporting.value = true;
      // eslint-disable-next-line no-unused-expressions
      loadReportStats() || updateReportStats();
    }

    if( currentStep.value <= 2 ) currentStep.value += 1;
  };

  const previousStep = () => { currentStep.value -= 1 };

  const show = teamid => {
    team.value = teamid;
    enabled.value = true;
    
    // eslint-disable-next-line no-unused-expressions
    loadReports() || updateReports();
  };

  const reset = () => {
    reports.value = null;
    enabled.value = false;
    completed.value = false;
    exporting.value = false;
    selectedVersions.value = [];
    currentStep.value = 1;
    modal.value.reset();
  };

  defineExpose( {
    show,
    reset,
  } );
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';
  .CSVSummary {
    padding: 16px;
  }

  .actions {
    padding: 16px;
    display: flex !important;
    justify-content: space-between !important;
    background: #EBEDF0;
    padding-top: 8px;
    padding-bottom: 0;
  }

  ._darkMode .actions {
    background: darken( $hugr-colours-primary, 10% );
  }
</style>
